<template>
    <v-container >
		<v-slide-y-transition mode="out-in">
			<v-layout column md12 sm12 lg12>
				<v-data-table
					:headers="headers"
					:items="desserts"
					sort-by="email"
					class="elevation-1"
				>
					<template v-slot:top>
					<v-toolbar
						flat
					>
						<v-toolbar-title>ADMINISTRATORS</v-toolbar-title>
						<v-divider
						class="mx-4"
						inset
						vertical
						></v-divider>
						<v-spacer></v-spacer>
						<v-dialog
						v-model="dialog"
						max-width="500px"
						>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
							color="primary"
							dark
							class="mb-2"
							v-bind="attrs"
							v-on="on"
							>
							ADD ADMIN
							</v-btn>
						</template>
						<v-card>
							<v-card-title>
							<span class="headline">{{ formTitle }}</span>
							</v-card-title>

							<v-card-text>
							<v-container>
								<validation-observer
									ref="observer"
									v-slot="{ invalid }"
								>
									<form @submit.prevent="editedItem.id != 0 ? updateRecord(editedItem) : submit()">
									<validation-provider
										v-slot="{ errors }"
										name="Name"
										rules="required|max:100"
									>
										<v-text-field
										v-model="editedItem.name"
										:counter="100"
										:error-messages="errors"
										label="Name"
										required
										></v-text-field>
									</validation-provider>
									
									<validation-provider
										v-slot="{ errors }"
										name="email"
										rules="required|email"
									>
										<v-text-field
										v-model="editedItem.email"
										:error-messages="errors"
										label="E-mail"
										required
										></v-text-field>
									</validation-provider>

									<v-text-field
										v-model="password"
										:append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
										:rules="[rules.required, rules.min]"
										:type="show1 ? 'text' : 'password'"
										name="password"
										label="Type your password"
										hint="At least 8 characters"
										counter
										@click:append="show1 = !show1"
									></v-text-field>


									<validation-provider
										v-slot="{ errors }"
										name="select"
										rules="required"
									>
										<v-select
										v-model="editedItem.select"
										:items="items"
										:error-messages="errors"
										label="Select Role"
										data-vv-name="select"
										required
										></v-select>
									</validation-provider>
									
									<v-btn
										class="mr-4"
										type="submit"
										:disabled="invalid"
									>
										submit
									</v-btn>
									<v-btn @click="clear">
										clear
									</v-btn>
									</form>
								</validation-observer>
							</v-container>
							</v-card-text>

							<!-- <v-card-actions>
							<v-spacer></v-spacer>
							<v-btn
								color="blue darken-1"
								text
								@click="close"
							>
								Cancel
							</v-btn>
							<v-btn
								color="blue darken-1"
								text
								@click="save"
							>
								Save
							</v-btn>
							</v-card-actions> -->
						</v-card>

						</v-dialog>
						<v-dialog v-model="dialogDelete" max-width="500px">
							<v-card>
								<v-card-title class="headline">Are you sure you want to delete this item?</v-card-title>
								<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
								<v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
								<v-spacer></v-spacer>
								</v-card-actions>
							</v-card>
						</v-dialog>
					</v-toolbar>
					</template>
					<template v-slot:item.actions="{ item }">
					<v-icon
						small
						class="mr-2"
						@click="editItem(item)"
					>
						mdi-pencil
					</v-icon>
					<v-icon
						small
						@click="deleteItem(item)"
					>
						mdi-delete
					</v-icon>
					</template>
					<template v-slot:no-data>
					<v-btn
						color="primary"
						@click="initialize"
					>
						Reset
					</v-btn>
					</template>
				</v-data-table>

				<!-- <v-card>
					<v-toolbar flat>
					<v-toolbar-title>ADD ADMIN</v-toolbar-title>
					<v-spacer></v-spacer>
					<div>
						<v-switch
						v-model="sticky"
						label="Sticky Banner"
						hide-details
						></v-switch>
					</div>
					</v-toolbar>
    				<v-banner
						single-line
						:sticky="sticky"
					>
      				CREATE ADMINISTRATIVE USERS

					
					</v-banner>
					<v-card-text class=" lighten-4">
					<v-sheet
						max-width="100%"
						height="300"
						class="mx-auto"
					>
					
						
					
					</v-sheet>
					</v-card-text>
				</v-card> -->


                
			</v-layout>
		</v-slide-y-transition>
	</v-container>
</template>

<script>
  import { required, digits, email, max, regex } from 'vee-validate/dist/rules'
  import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
  import Axios from 'axios'

  setInteractionMode('eager')

  extend('digits', {
    ...digits,
    message: '{_field_} needs to be {length} digits. ({_value_})',
  })

  extend('required', {
    ...required,
    message: '{_field_} can not be empty',
  })

  extend('max', {
    ...max,
    message: '{_field_} may not be greater than {length} characters',
  })

  extend('regex', {
    ...regex,
    message: '{_field_} {_value_} does not match {regex}',
  })

  extend('email', {
    ...email,
    message: 'Email must be valid',
  })

  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    data: () => ({
	  sticky: false,
      name: '',
      email: '',
      select: null,
      items: [
        'Superadmin',
        'Admin',
	  ],
	  show1: false,
	  password: 'Password',
    	rules: {
          required: value => !!value || 'Required.',
          min: v => v.length >= 8 || 'Min 8 characters',
          emailMatch: () => (`The email and password you entered don't match`),
        },
	  checkbox: null,
	  
	  // Table start
      dialog: false,
      dialogDelete: false,
      headers: [
        { text: 'Name', value: 'name' },
		{ text: 'Email', value: 'email' },
		 { text: 'Roles', value: 'select' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      desserts: [],
      editedIndex: -1,
      editedItem: {
		id: 0,
        name: '',
        email: '',
      	select: null
      },
      defaultItem: {
        name: '',
        email: '',
        select: null
      },
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Add New Admin' : 'Edit Admin'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
	this.getAllRoles()
	  this.initialize()
	  this.getAllUsers()
    },


    methods: {
		getAllRoles(){
		  	Axios.get('/getRoles', {
				headers: {
					Authorization: "Bearer " + localStorage.getItem("id_token")
				}
		  	}).then(response => {
				// console.log("rxfghjikbggvhkgk"+ response.data.data.map(user => ({user})))
				this.items = response.data.data.map(role => role.name)
				console.log(this.desserts)
				// this.desserts.push()

			}).catch(error => {
			})
	  },
	  	getAllUsers(){
		  	Axios.get('/getAdmin', {
				headers: {
					Authorization: "Bearer " + localStorage.getItem("id_token")
				}
		  	}).then(response => {
				  console.log("Welcoms")
				// console.log("rxfghjikbggvhkgk"+ response.data.data.map(user => ({user})))
				this.desserts = response.data.data.map(user => ({"id": user.id, "name": user.name, "email": user.email, "select":user.role != null ? user.role.role_name.name : ''}))
				console.log(this.desserts)
				// this.desserts.push()

			}).catch(error => {
			})
	  },
      submit () {
		this.$refs.observer.validate()
		let formData = {
			name: this.editedItem.name,
			email: this.editedItem.email,
			role: this.editedItem.select,
			password: this.password
		}
		Axios.post('/admin_user', formData, {
			headers: {
				Authorization: "Bearer " + localStorage.getItem("id_token")
			}
		}).then(response => {
			if(response.data.status){
				this.$swal(response.data.message);
			}else{
				this.$swal(response.data.message);
			}
			this.clear()
		}).catch(error => {
		})
		
		this.getAllUsers()
      },
      clear () {
        this.editedItem.name = ''
        this.editedItem.password = ''
        this.editedItem.email = ''
        this.editedItem.select = null
        this.$refs.observer.reset()
	  },
	  initialize () {
        this.desserts = [
          {
            name: 'loading...',
			email: 'loading...',
			select: "loading..."
          }
         
        ]
      },

      editItem (item) {
		  
        this.editedIndex = this.desserts.indexOf(item)
		this.editedItem = Object.assign({}, item)
		console.log(this.editedItem)
        this.dialog = true
	  },
	  
	  updateRecord(item)
	  {
		  console.log(item)
		  let formData = {
			name: this.editedItem.name,
			email: this.editedItem.email,
			role: this.editedItem.select,
			password: this.password
		}
		Axios.post('/editAdmin/'+item.id, formData, {
			headers: {
				Authorization: "Bearer " + localStorage.getItem("id_token")
			}
		}).then(response => {
			this.$swal('User successfully Updated');
			this.getAllUsers()
		}).catch(error => {
		})
	  },

      deleteItem (item) {
		
        this.editedIndex = this.desserts.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

	  deleteReal(item){
		Axios.post('/deleteAdmin/'+item.id, {}, {
			headers: {
				Authorization: "Bearer " + localStorage.getItem("id_token")
			}
		}).then(response => {
			this.$swal('Successfully Deleted');
			this.clear()
		}).catch(error => {
		})
	  },
	  
      deleteItemConfirm () {
		this.deleteReal(this.editedItem)
        this.desserts.splice(this.editedIndex, 1)
        this.closeDelete()
	  },
	  


      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.desserts[this.editedIndex], this.editedItem)
        } else {
          this.desserts.push(this.editedItem)
        }
        this.close()
      },
    },
  }
</script>