<template>
  <v-card class="card">
    <v-card-text class="pa-1">
      <area-chart :data="data" :dataset="{borderWidth: 3}" :colors="color"  :ytitle="title" ></area-chart>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: false
        },
        data: {
            required: true
        },
        color: {
            type: Array,
            required: true
        }
    },
  data() {
    return {
    }
  }
}
</script>

<style>
  .card {
    border-radius: 3px;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.21);
    background-color: transparent;
  }
</style>
