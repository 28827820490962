<template>
  <div class="text-center ma-2">
    <v-snackbar
      v-model="status_res"
    >
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="status_res = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
    props: ['text', 'status'],
    data () {
        return {
            status_res: false
        }
    },
    watch: {
        status (newVal, oldVal) {
            this.status_res = newVal
        }
    }
}
</script>