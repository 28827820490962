<template>
	<v-app id="inspire">
	<v-content>
		<v-container fluid fill-height>
		<v-layout align-center justify-center>
			<v-flex xs12 sm8 md4>
			<v-card class="elevation-12">
				<v-toolbar dark color="grey darken-4">
				<!-- <v-toolbar-title>ESAR admin</v-toolbar-title> -->
				<img src="@/assets/esar_logo.png" alt="Vuetify.js" class="esar-logo">
				<v-spacer></v-spacer>
				<!-- <v-tooltip bottom>
					<v-btn
					slot="activator"
					:href="source"
					icon
					large
					target="_blank"
					>
					<v-icon large>code</v-icon>
					</v-btn>
					<span>Source</span>
				</v-tooltip> -->
				<!-- <v-tooltip right>
					<v-btn slot="activator" icon large href="https://codepen.io/johnjleider/pen/wyYVVj" target="_blank">
					<v-icon large>mdi-codepen</v-icon>
					</v-btn>
					<span>Codepen</span>
				</v-tooltip> -->
				</v-toolbar>
				<v-card-text>
                    <v-form>
                        <v-text-field  v-model="name" prepend-icon="person" name="login" label="Login" type="text"></v-text-field>
                        <v-text-field v-model="password" id="password" prepend-icon="lock" name="password" label="Password" type="password"></v-text-field>
                    </v-form>
				</v-card-text>
				<v-card-actions>
					<v-card-text v-if="loginTrue" >
						<span style="color:red">
							Username or password is incorrect
						</span>
					</v-card-text>
					<v-card-text v-if="error" >
						<span style="color:red">
							{{error}}
						</span>
					</v-card-text>
                    <v-spacer></v-spacer>
                    <v-btn @click="login" color="primary">Login</v-btn>
				</v-card-actions>
			</v-card>
			</v-flex>
		</v-layout>
		</v-container>
	</v-content>
	</v-app>
</template>

<script>
	export default {

        props: {
            source: String
        },

		data: () => ({
			name: '',
			password: '',
			drawer: null,
			loginTrue: false
		}),
        methods: {
            login () {
				this.$store.dispatch('currentUser/loginUser', {name: this.name, password: this.password})
			},
			checkOut () {
				/* eslint-disable */
				if (!this.password || this.password === "") {
					this.loginTrue = true
				}
				else if (!this.name || this.name === "") {
					this.loginTrue = true
				}
				else {
					this.loginTrue = false
				}
			}
		},

		computed: {

			token () {
				return this.$store.state.currentUser.token
			},
			error: {
				get () {
					return this.$store.state.currentUser.error
				}
			}
		},

		watch: {
			name () {
				this.checkOut()
			},
			password () {
				this.checkOut()
			}
		}
	}
</script>

<style scoped>
	.esar-logo {
		width: 170px;
		margin-left: 10px;
		margin-right: 10px;
	}
</style>

