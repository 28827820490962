import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'

Vue.use(VueRouter)
import Cars from '@/components/Cars.vue'
import Trips from '@/components/Trips.vue'
import Users from '@/components/Users.vue'
import Earnings from '@/components/Earnings.vue'
import Admin from '@/components/Admin.vue'
import Role from '@/components/Role.vue'
import Calculator from '@/components/Calculator.vue'
import Login from '@/components/Login.vue'
import Home from '@/components/Home.vue'
import PopUser from '@/components/popups/ActiveUsers.vue'
const routerOptions = [
  {
    path: '/',
    redirect: 'dashboard/basic-dashboard',
    component: () => import('@/layouts/Layout'),
    children: [
      { path: '/cars', component: Cars, meta: { requiresAuth: true } },
      { path: '/trips', component: Trips, meta: { requiresAuth: true } },
      { path: '/users', component: Users, meta: { requiresAuth: true } },
      { path: '/earnings', component: Earnings, meta: { requiresAuth: true } },
      { path: '/admin', component: Admin, meta: { requiresAuth: true } },
      { path: '/roles', component: Role, meta: { requiresAuth: true } },
      { path: '/calculator', component: Calculator, meta: { requiresAuth: true } },
      // { path: '/login', component: Login },
      { path: '/', component: Home, meta: { requiresAuth: true } },
      { path: '/dashboard', component: Home, meta: { requiresAuth: true } },
      { path: '/popup', component: PopUser, meta: { requiresAuth: true } }
    ]
  },{ path: '/login', component: Login },
]
const routes = routerOptions
// routes.push({ path: '*', meta: { requiresAuth: true } })
// const routes = routerOptions.map(route => {
// 	return {
//     ...route,
    
//         component: () => import(`@/components/${route.component}.vue`)
// 	}
// })
 
// routes.push([
//   {
//     path: '/ss',
//     name: 'Home',
//     component: Home
//   },
//   {
//     path: '/about',
//     name: 'About',
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
//   },
//   {
//     path: '/contact',
//     name: 'Contact',
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: () => import(/* webpackChunkName: "about" */ '../views/Contact.vue')
//   },
//   {
//     path: '/dash',
//     redirect: 'dashboard/basic-dashboard',
//     component: () => import('@/layouts/Layout'),
//     children: [
//       // Components
//       {
//         name: 'Alerts',
//         path: 'pages/alerts',
//         component: () => import('@/views/pages/Alerts'),
//       },

//       {
//         name: 'Profile',
//         path: 'pages/profile',
//         component: () => import('@/views/pages/Profile'),
//       },

//        {
//         name: 'Icons',
//         path: 'pages/icons',
//         component: () => import('@/views/pages/Icons'),
//       },

//       {
//         name: 'TableSimple',
//         path: 'pages/tables-simple',
//         component: () => import('@/views/pages/TableSimple'),
//       },

//       {
//         name: 'Dashboard',
//         path: 'dashboard/basic-dashboard',
//         component: () => import('@/views/dashboard/BasicDashboard'),
//       },

//     ]
//   }
// ])

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
