import Vue from 'vue'
import Vuex from 'vuex'
import users from './modules/users'
import currentUser from './modules/currentUser'
import cars from './modules/cars'
import trips from './modules/trips'
import earnings from './modules/earnings'
import calculator from './modules/calculator'

import dialogUsers from './modules/dialog/users'
import dialogCars from './modules/dialog/cars'
import dialogTrips from './modules/dialog/trips'
import note from './modules/dialog/notes/Note'
import menu from './modules/menu'
Vue.use(Vuex)
export default new Vuex.Store({
    modules: {
        users,
        currentUser,
        cars,
        trips,
        earnings,
        calculator,
        dialogUsers,
        dialogCars,
        dialogTrips,
        note,
        menu
    }
})
