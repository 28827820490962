<template>
  <div>
	<slot></slot>
    <b-modal :id="id" hide-footer  no-close-on-backdrop :title="title + ' User'">
		<v-container grid-list-md>
            <v-layout wrap>
				
				<v-timeline align-top dense clipped md12 sm12 xs12 xl12 lg12 style="width:100%!important;">
					<v-timeline-item
						fill-dot
						class="white--text mb-5"
						color="white"
						style="color:orange!important;"
						large
						md12 sm12 xs12 xl12 lg12
					>
        				<template v-slot:icon >
          					<span style="color:white!important;">COMA</span>
        					</template>
								<v-text-field
								v-model="input"
								hide-details
								flat
								label="Leave a comment..."
								solo
								@keydown.enter="comment"
								md12 sm12 xs12 xl12 lg12
							>
          						<template v-slot:append>
									<v-btn
										class="mx-0"
										depressed
										@click="comment"
										>
										Post
									</v-btn>
          						</template>
        					</v-text-field>
    				</v-timeline-item>
					<v-slide-x-transition group>
						<v-timeline-item
							v-for="event in timeline"
							:key="event.id"
							class="mb-3"
							color="pink"
							style="background-color:pink!important;"
							small
						>
							<v-layout justify-space-between>
								<v-flex xs7 v-text="`${currentUser} ${event.text}`"></v-flex>
								<v-flex xs5 text-xs-right v-text="event.time"></v-flex>
							</v-layout>
						</v-timeline-item>
					</v-slide-x-transition>
					<v-timeline-item
						class="mb-4"
						hide-dot
					>
						<span>Now</span>
					</v-timeline-item>

					<v-timeline-item
						class="mb-3"
						color="grey"
						small
						v-for="event in data" :key="event.id"
					>
						<v-layout justify-space-between>
							<v-flex xs7>
								{{ `${event.added_by ? event.added_by.name : ''} (${event.added_by ? event.added_by.email : ''})` }} {{event.comment}} 
							</v-flex>
							<v-flex xs5 text-xs-right>{{event.created_at | toDateFormat}}</v-flex>
						</v-layout>
					</v-timeline-item>
				</v-timeline>
		</v-layout>
	</v-container>
            
    <v-btn class="mt-3" default block @click="$bvModal.hide(id)">Close Me</v-btn>
	
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment'
export default {
    props: ['status', 'title', 'id'],
    data () {
        return {
			dialog: false,
			allow: false,
            search: '',
			dialogDelete: false,
			events: [],
			input: null,
			nonce: 0,
			currentUser: ''
        }
	},
	computed: {
            loading: {
                get () {
                    return this.$store.getters['note/getLoader']
                },
                set (value) {
                    this.$store.getters['note/getLoader']
                }
			},
			data: {
				get () {
					return this.$store.state.note.data
				}
			},
			timeline: {
				get () {
					return this.events.slice().reverse()
				},
				set (value) {
					this.timeline = null
				}
			}
		},
		watch: {
			loading (newVal, oldVal) {
				this.loading = this.$store.getters['note/getLoader']
			}
		},
		methods: {
			closeDialog () {
				this.dialog = false
				this.allow = true
			},
			loadingStatus () {
				return this.$store.getters['note/getLoader']
			},
            showDeleteDialog (value) {
                this.dialogDelete = true
                this.selectedItem = value
			},
			comment () {
				if (this.input !== null && this.input !== '') {
					let data = {'url': '/add_note_user', 'request_id': this.id}
					let form = {'user_id': this.id, 'note': this.input}
					this.$store.dispatch('note/setAllAction', data)
					this.$store.dispatch('note/SetFormData', form)
					this.$store.dispatch('note/addNote')
					this.currentUser = localStorage.getItem('user')
					const time = new Date()
					this.events.push({
						id: this.nonce++,
						text: this.input,
						time: moment(String(time)).format('MM/DD/YYYY hh:mm')
					})
				} else {
					alert("Type your comment first!")
				}

				this.input = null
			}
		},
		filters: {
            toDateFormat (value) {
                if (value) {
					return moment(String(value)).format('MM/DD/YYYY hh:mm')
				}
            }
        },
		mounted () {
		}

}
</script>
<style scoped>
	.border {
	border: 2px dashed orange
	}
</style>