<template>
    <div>
        <v-container fluid>
            <alert :text="text" :status="alertStatus"></alert>
        <v-btn>
            <v-icon>content_copy</v-icon>
            <download-excel
                :fetch="fetchData"
                :before-generate = "startDownload"
                :before-finish   = "finishDownload" 
                :fields="fields"
                type="csv"
                :name="param.filename + '.csv'"  
            >
                CSV
            </download-excel>
        </v-btn>
        <v-btn>
            <v-icon>content_copy</v-icon>
            <download-excel
                :fetch="fetchData"
                :before-generate = "startDownload"
                :before-finish   = "finishDownload" 
                :fields="fields"
                type="xls"
                :name="param.filename"  
            >
                Excel
            </download-excel>
        </v-btn>
        <v-btn @click="pdfExports()">
            <v-icon>content_copy</v-icon>
            pdf
        </v-btn>
        <!-- <v-btn @click="doCopy">
            <v-icon>content_copy</v-icon>
            COPY
        </v-btn> -->
        </v-container>
    </div>
</template>

<script>
import { default as JSPDF } from 'jspdf'
import autoTable from 'jspdf-autotable'
import alert from './alert/alert'
export default {
    props: ['json_data', 'param', 'fields', 'fieldsPDF'],
    components: {
        alert
    },
    data () {
        return {
            alertStatus: false,
            text: ''
        }
    },
    methods: {
        async fetchData () {
            const response = await this.axios.get(this.param.url + '?model=' + this.param.model + '&search=' + this.param.search + '&type=' + this.param.type + '&status=' + this.param.status + '&duration=' + this.param.duration + '&dates=' + this.param.date_range, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("id_token")
                }
            })
            // console.log(response.data.users)
            /* eslint-disable */
            if (this.param.model === "User") {
                return response.data.users
            }
            else if (this.param.model === "Car") {
                return response.data.cars
            }
            else if (this.param.model === "Trip") {
                return response.data.trips
            }
        },
        startDownload () {
            this.alertStatus = true
            this.text = "In progress..."
        },
        finishDownload () {
            this.alertStatus = true
            this.text = "Finish Downloading..."
        },
        pdfExports () {
            this.alertStatus = true
            this.text = "In progress..."
            this.axios.get(this.param.url + '?model=' + this.param.model + '&search=' + this.param.search + '&type=' + this.param.type + '&status=' + this.param.status + '&duration=' + this.param.duration + '&dates=' + this.param.date_range,
				{
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("id_token")
                    }
                })
				.then(response => {
                    let result = []
                    if (this.param.model === "User") {
                        result = response.data.users
                    }
                    else if (this.param.model === "Car") {
                        result = response.data.cars
                    }
                    else if (this.param.model === "Trip") {
                        result = response.data.trips
                    }
                    const doc = new JSPDF('p', 'pt', 'letter')
                    doc.autoTable({
                        columnStyles: { europe: { halign: 'center' } },
                        body: result,
                        columns: this.fieldsPDF
                    })
                    doc.save('table.pdf')
                    // console.log(this.fieldsPDF)
                    this.alertStatus = true
                    this.text = "Finish Downloading..."
                })
				.catch(error => {
					this.alertStatus = true
                    this.text = "Failed... Try again"
				})
        },
        doCopy () {
            this.alertStatus = true
            this.text = "In progress..."
            this.axios.get(this.param.url + '?model=' + this.param.model + '&search=' + this.param.search + '&type=' + this.param.type + '&status=' + this.param.status + '&duration=' + this.param.duration + '&dates=' + this.param.date_range,
				{
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("id_token")
                    }
                })
				.then(response => {
                    let result = []
                    if (this.param.model === "User") {
                        result = response.data.users
                    }
                    else if (this.param.model === "Car") {
                        result = response.data.cars
                    }
                    else if (this.param.model === "Trip") {
                        result = response.data.trips
                    }

                    this.$copyText(result).then(function (e) {
                        console.log(e)
                    }, function (e) {
                        console.log(e)
                    })
                    
                    this.alertStatus = true
                    this.text = "Finish Copying..."
                })
				.catch(error => {
					this.alertStatus = true
                    this.text = "Failed... Try again"
				})
        }
    }
}
</script>