<template>
    <!-- <div class="v-data-footer" style="padding-top:20px!important; padding-bottom:20px!important;">
        <div class="v-data-footer__pagination" style="float:right; padding:20px; font-size:20pt;">{{currentPage}} of {{lastPage}}</div>
        <v-pagination v-model="currentPage" :length="lastPage" :total-vsible="total"></v-pagination>
    </div> -->
    <div class="text-center">
        <div class="v-data-footer__pagination" style="float:right; padding:20px; font-size:20pt;">{{currentPage}} of {{lastPage}}</div>
        <v-pagination color="grey" circle :total-visible="10" v-model="currentPage" :length="lastPage" :total-vsible="total"></v-pagination>
    </div>
</template>

<script>
export default {
    props: ['store', 'collection'],
    watch: {
        currentPage (newVal, oldVal) {
            this.paginatePage(newVal)
        }
    },
    computed: {
        currentPage: {
            get () {
                return this.$store.state[this.store][this.collection] ? this.$store.state[this.store][this.collection].current_page : 1
            },
            set (value) {
                this.$store.commit(this.store + '/setCurrentPage', value)
            }
        },
        lastPage: {
            get () {
                return this.$store.state[this.store][this.collection] ? this.$store.state[this.store][this.collection].last_page : 10
            }
        },
        total: {
            get () {
                return this.$store.state[this.store][this.collection] ? this.$store.state[this.store][this.collection].total : 10
            }
        }
    },
    methods: {
        paginatePage (pageNumber) {
            this.$store.dispatch(this.store + '/getList', pageNumber)
        }
    }
}
</script>
<style>
/* .theme--light.v-pagination .v-pagination__item--active{
  color:gray!important;
} */
</style>