<template>
  <div>
	<slot></slot>
    <b-modal :id="id" hide-footer size="xl" no-close-on-backdrop :title="displayText + ' Cars'">
		<v-container grid-list-md>
            <v-layout wrap>
			<v-flex xs12 md4 lg4 sm>
				<v-text-field
					v-model="search"
					append-icon="search"
					label="Search"
					single-line
					hide-details
				>
				</v-text-field>
        	</v-flex>
		
			<v-spacer></v-spacer>
			<vueBtn 
				:json_data = "cars"
				:param="{
					model: model,
					search: search,
					type: type,
					status: status,
					duration:duration,
					url: '/report/getCountsRecordsExport',
					filename: duration + '_Cars'
				}"
				:fields="headerfileExport"
				:fieldsPDF="headerfileExportPDF"
			/>
			<template #modal-title-cars>
				{{duration}} Cars
			</template>
              <v-flex xs12 sm12 md12>
                  <v-data-table
						:headers="headers"
						:items="cars"
						:loading="loadingStatus()"
						:hide-default-footer=false
						:hideActions=true
						:footer-props="{ disablePagination : false, disableItemsPerPage : false }"
						:single-expand="singleExpand"
						:expanded.sync="expanded"
						show-expand
					>
					<template v-slot:item.ownerName="{ item }">
						{{ `${item.owner.first_name}  ${item.owner.last_name}` }}
					</template>
					<template v-slot:item.created_at="{ item }">
						{{ `${item.created_at}` }}
					</template>

					<template v-slot:item.ownerId="{ item }">
						{{ `${item.owner.id}` }}
					</template>

					<template v-slot:item.active="{ item }">
						{{ item.active ? "Yes" : "No" }}
					</template>

					<template v-slot:item.delete_restore="{ item }">
						<div
							v-if="item.active === 1"
							class="text-xs-left">
							<v-icon
								@click="showMsgDelete(item.id)"
								color="error"
							>
								mdi-delete
							</v-icon>
						</div>
						<div
							v-else
							class="text-xs-left"
						>
							<v-icon
								@click="showMsgRestore(item.id)"
								color="success"
							>
							mdi-check
							</v-icon>
						</div>
					</template>
					<template v-slot:item.Verified="{ item }">
						<v-icon v-if="item.verified_insurance === 1 && item.verified_registration === 1 " color="green">check_circle</v-icon>
						<v-icon v-else color="red">highlight_off</v-icon>
					</template>
					<template v-slot:expanded-item="{ headers, item }">
						<td :colspan="headers.length">
							<selected-car :carId="item.id"></selected-car>
						</td>
					</template>
						
					</v-data-table>
					<paginate store="dialogCars" collection="meta"/>
                    <v-progress-linear v-if="loadingStatus()" :indeterminate="true"></v-progress-linear>
			
				<!-- delete dialog -->
				<v-dialog v-model="dialogDelete" v-if="selectedItem" persistent max-width="290">
					<v-card>
						<v-card-title class="headline">Are you sure?</v-card-title>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="info" @click.native="dialogDelete = false; deleteCar(selectedItem)">DELETE</v-btn>
							<v-btn color="error" @click.native="dialogDelete = false">CANCEL</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
				<!-- end of delete dialog-->

				<!-- restore dialog -->
				<v-dialog v-model="dialogRestore" v-if="selectedItem" persistent max-width="290">
					<v-card>
						<v-card-title class="headline">Are you sure?</v-card-title>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="info" @click.native="dialogRestore = false; restoreCar(selectedItem)">RESTORE</v-btn>
							<v-btn color="error" @click.native="dialogRestore = false">CANCEL</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
				<!-- end of restore dialog-->

              </v-flex>
            </v-layout>
          </v-container>
                   
    	<b-button class="mt-3" block @click="$bvModal.hide(id)">Close Me </b-button>
    </b-modal>
  </div>
</template>

<script>
import _ from 'lodash'
import paginate from '../../paginate'
import vueBtn from '../../vueBtnDownload'
export default {
    props: ['model', 'status', 'type', 'duration', 'id', 'displayText'],
    components: {
		paginate,
		vueBtn
	},
    data () {
        return {
			expanded: [],
        	singleExpand: true,
			filename: "Cars",
            dialog: false,
            search: '',
            dialogDelete: false,
            dialogRestore: false,
			selectedItem: null,
			numOfunverifiedUsers: [],
			pagination: {
				sortBy: 'id',
				rowsPerPage: 10
			},
			headers: [
				{
                    text: 'CAR ID',
					align: 'left',
					sortable: true,
					value: 'id'
				},
				{ text: 'Manufacturer', value: 'car_manufacturer' },
				{ text: 'Model', value: 'car_model' },
				{ text: 'Year', value: 'production_year' },
				{ text: 'City', value: 'car_city' },
				{ text: 'Active', value: 'active' },
				{ text: 'Owner ID', value: 'ownerId' },
				{ text: 'Owner Name', value: 'ownerName' },
				{ text: 'Created On', value: 'created_at' },
                { text: 'Delete/Restore', value: 'delete_restore' },
                { text: 'Verified', value: 'Verified' }
			],
			headerfileExport: {
				'id': 'id',
				'car_manufacturer': 'car_manufacturer',
				'car_model': 'car_model',
				'production_year': 'production_year',
				'car_city': 'car_city',
				'active': 'active',
				'owner_first': 'owner.first_name',
				'owner_last': 'owner.last_name',
				'created_at': 'created_at'
			},
			headerfileExportPDF: [
				{ header: 'ID', dataKey: 'id' },
				{ header: 'Car Manufacturer', dataKey: 'car_manufacturer' },
				{ header: 'Car Model', dataKey: 'car_model' },
				{ header: 'Production Year', dataKey: 'production_year' },
				{ header: 'Car City', dataKey: 'car_city' },
				{ header: 'Owner First Name', dataKey: 'owner.first_name' },
				{ header: 'Owner Last Name', dataKey: 'owner.last_name' },
				{ header: 'Active', dataKey: 'active' },
				{ header: 'Created_at', dataKey: 'created_at' }
			]
        }
	},
	computed: {
		loading: {
			get () {
				return this.$store.getters['dialogCars/getLoader']
			},
			set (value) {
				this.$store.getters['dialogCars/getLoader']
			}
		},
		cars: {
			get () {
				return this.$store.state.dialogCars.cars
			}
		},
		pages () {
			if (this.pagination.rowsPerPage == null ||
				this.pagination.totalItems == null
			) return 0
                return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
			}
		},
		watch: {
			loading (newVal, oldVal) {
				this.loading = this.loadingStatus()
			},
			search (newVal, oldVal) {
				let data = {'model': this.model, 'type': this.type, 'status': this.status, 'duration': this.duration}
				this.$store.dispatch('dialogCars/setAllAction', data)
				this.$store.dispatch('dialogCars/setCurrentUrl', '/report/getCountsRecords')
				this.$store.dispatch('dialogCars/setSearchText', newVal)
				this.$store.dispatch('dialogCars/getList', 1).then(response => {
					this.loading = this.$store.getters['dialogCars/getLoader']
				})
			}
		},
		methods: {
			showMsgRestore(data) {
				this.boxTwo = ''
				this.$bvModal.msgBoxConfirm('Please confirm that you want to restore everything.', {
				title: 'Please Confirm',
				size: 'sm',
				buttonSize: 'sm',
				okVariant: 'danger',
				okTitle: 'YES',
				cancelTitle: 'NO',
				footerClass: 'p-2',
				hideHeaderClose: false,
				centered: true
				})
				.then(value => {
					console.log(value)
					if(value){
						this.restoreCar(data)
					}else{
						alert('Cancelled successfully')
					}
				})
				.catch(err => {
					// An error occurred
				})
			},
			showMsgDelete(data) {
				this.boxTwo = ''
				this.$bvModal.msgBoxConfirm('Please confirm that you want to delete everything.', {
				title: 'Please Confirm',
				size: 'sm',
				buttonSize: 'sm',
				okVariant: 'danger',
				okTitle: 'YES',
				cancelTitle: 'NO',
				footerClass: 'p-2',
				hideHeaderClose: false,
				centered: true
				})
				.then(value => {
					console.log(value)
					if(value){
						this.deleteCar(data)
					}else{
						alert('Cancelled successfully')
					}
				})
				.catch(err => {
					// An error occurred
				})
			},
			loadingStatus () {
				return this.$store.getters['dialogCars/getLoader']
			},
            deleteCar (id) {
                this.loading = true
                this.axios.post('/cars/delete/' + id, {},
				{
					headers: {
						Authorization: "Bearer " + localStorage.getItem("id_token")
					}
				})
				.then(response => {
					this.loading = false
					this.getCars()
				})
				.catch(error => {
					console.log(error)
					this.loading = false
				})
            },

            restoreCar (id) {
                this.loading = true
                this.axios.post('/cars/restore/' + id, {},
				{
					headers: {
						Authorization: "Bearer " + localStorage.getItem("id_token")
					}
				})
				.then(response => {
					this.loading = false
					this.getCars()
				})
				.catch(error => {
					console.log(error)
					this.loading = false
				})
            },
            showDeleteDialog (value) {
                this.dialogDelete = true
                this.selectedItem = value
            },
            showRestoreDialog (value) {
                this.dialogRestore = true
                this.selectedItem = value
            },
			handleChange (dates) {
				this.dates.splice(0, this.dates.length)
				for (let i = 0; i < dates.length; i++) {
					this.dates.push(dates[i].format("YYYY-MM-DD"))
				}
			}
		}
		// mounted () {
		// 	this.getUsers()
		// }

}
</script>

<style>
/* .theme--light.v-pagination .v-pagination__item--active{
  color:gray!important;
}
.theme--light.v-pagination .v-pagination__navigation {
    background: #fff;
    color:black;
} */
</style>