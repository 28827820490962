<template>
<v-container>
    
    <v-layout row justify-center>
    
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      
      <v-card>
        
        <v-toolbar dark color="grey">
            <v-btn icon dark @click="closeDialog()">
                <v-icon>close</v-icon>
            </v-btn>
            <v-toolbar-title>TRIP</v-toolbar-title>
        </v-toolbar>
        <v-card-title>
            <v-flex xs2 sm>
				<v-btn @click="gettrips()" outline type="div" color="primary" :loading="loadingStatus()">
					trips LISTING 
				</v-btn>
            </v-flex>
            <v-flex md7>
            <v-layout row wrap justify-center mb-1>
                <v-flex xs3 sm>
                    <v-card>
                        <v-card-title class="text-xs-center" primary-title>
                            <div >
                                <h3 class="headline mb-0">Active</h3>
                                <div class="sum-numbers">{{data.active | toNumberFormat}}</div>
                            </div>
                        </v-card-title>
                    </v-card>
                </v-flex>

                <v-flex xs3 sm>
                    <v-card>
                        <v-card-title primary-title>
                            <div>
                                <h3 class="headline mb-0">Daily</h3>
                                <div class="sum-numbers">{{data.daily | toNumberFormat}}</div>
                            </div>
                        </v-card-title>
                    </v-card>
                </v-flex>
                <v-flex xs3 sm>
                    <v-card>
                        <v-card-title primary-title>
                            <div>
                                <h3 class="headline mb-0">Weekly</h3>
                                <div class="sum-numbers">{{data.weekly | toNumberFormat}}</div>
                            </div>
                        </v-card-title>
                    </v-card>
                </v-flex>
                <v-flex xs6 sm>
                    <v-card>
                        <v-card-title primary-title>
                            <div>
                                <h3 class="headline mb-0">Monthly</h3>
                                <div class="sum-numbers">{{data.monthly | toNumberFormat}}</div>
                            </div>
                        </v-card-title>
                    </v-card>
                </v-flex>
                <v-flex xs3 sm>
                    <v-card>
                        <v-card-title primary-title>
                            <div>
                                <h3 class="headline mb-0">Yearly</h3>
                                <div class="sum-numbers">{{data.yearly | toNumberFormat}}</div>
                            </div>
                        </v-card-title>
                    </v-card>
                </v-flex>
            
            </v-layout>
            </v-flex>
            <v-flex xs12 md3 lg3 sm>
			<v-text-field
			    v-model="search"
				append-icon="search"
				label="Search"
			    single-line
				hide-details
			>
			</v-text-field>
            </v-flex>
			</v-card-title>
            

			    <v-data-table
						:headers="headers"
						:items="trips"
						:loading="loadingStatus()"
						:pagination.sync="pagination"
						hide-default-footer="false"
						:hideActions=true
						rows-per-page-text=""
						:rows-per-page-items="[10, 20, 30, 50]"
						:footer-props="{ disablePagination : false, disableItemsPerPage : false }"
						>
						<template slot="items" slot-scope="props">
							<tr class="tr-click" @click="props.expanded = !props.expanded">
								<td>{{ props.item.id }}</td>
								<td class="text-xs-left">{{ props.item.start_date }}</td>
								<td class="text-xs-left">{{ props.item.end_date }}</td>
								<td class="text-xs-left">{{ props.item.pickup_location }}</td>
								<td class="text-xs-left">{{ props.item.notice_time }}</td>
								<td class="text-xs-left">{{ props.item.renter_id }}</td>
								<td class="text-xs-left">{{ props.item.renter.first_name }} {{ props.item.renter.last_name }}</td>
								<td class="text-xs-left">{{ props.item.owner_id }}</td>
								<td class="text-xs-left">{{ props.item.owner.first_name }} {{ props.item.owner.last_name }}</td>
								<td class="text-xs-left">{{ props.item.status }}</td>
							</tr>
						</template>
						<!-- expanding component -->
						<template slot="expand" slot-scope="props">
							<selected-trip :tripId="props.item.id"></selected-trip>
						</template>
						<v-alert slot="no-results" :value="true" color="error" icon="warning">
							Your search for "{{ search }}" found no results.
						</v-alert>
					</v-data-table>
				<paginate store="trips" collection="meta"/>
                <v-progress-linear v-if="loadingStatus()" :indeterminate="true"></v-progress-linear>
					 
				<!-- delete dialog -->
				<v-dialog v-model="dialogDelete" v-if="selectedItem" persistent max-width="290">
					<v-card>
						<v-card-title class="headline">Are you sure?</v-card-title>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="info" @click.native="dialogDelete = false; deleteUser(selectedItem)">DELETE</v-btn>
							<v-btn color="error" @click.native="dialogDelete = false">CANCEL</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
				<!-- end of delete dialog-->

				<!-- restore dialog -->
				<v-dialog v-model="dialogRestore" v-if="selectedItem" persistent max-width="290">
					<v-card>
						<v-card-title class="headline">Are you sure?</v-card-title>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="info" @click.native="dialogRestore = false; restoreUser(selectedItem)">RESTORE</v-btn>
							<v-btn color="error" @click.native="dialogRestore = false">CANCEL</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
				<!-- end of restore dialog-->
                </v-card>
            </v-dialog>
        </v-layout>
    </v-container>
</template>
<script>
    import paginate from '../paginate'
    export default {
		props: ['status', 'type'],
        components: {
			paginate
		},
        data () {
        return {
            data: '',
            dialog: false,
            notifications: false,
            sound: true,
            widgets: false,
            search: '',
				loading: '',
                dialogDelete: false,
                dialogRestore: false,
				selectedItem: null,
				numOfunverifiedtrips: [],
				pagination: {
					sortBy: 'id',
					rowsPerPage: 10
				},
				headers: [
					{
					text: 'TRIPS ID',
					align: 'left',
					sortable: true,
					value: 'id'
					},
					{ text: 'Start date', value: 'start_date' },
					{ text: 'End date', value: 'end_date' },
					{ text: 'Pickup location', value: 'pickup_location' },
					{ text: 'Notice time', value: 'notice_time' },
					{ text: 'Renter ID', value: 'renter_id' },
					{ text: 'Renter full name', value: 'renter' },
					{ text: 'Owner ID', value: 'owner_id' },
					{ text: 'Owner full name', value: 'owner' },
					{ text: 'Status', value: 'status' }
				]
        }
        },
        computed: {
			trips: {
				get () {
					return this.$store.state.trips.trips
				},
				set () {
					return this.$store.state.trips.trips
				}
			},
			pages () {
				if (this.pagination.rowsPerPage == null ||
					this.pagination.totalItems == null
				) return 0

				return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
			}
		},
		watch: {
			loading (newVal, oldVal) {
				this.loading = this.loadingStatus()
			},
			search (newVal, oldVal) {
				this.$store.dispatch('trips/setConfirmedAction', this.status)
                this.$store.dispatch('trips/setCurrentUrl', '/trips')
				this.$store.dispatch('trips/setSearchText', newVal)
				this.$store.dispatch('trips/getList', 1).then(response => {
					this.loading = this.$store.getters['trips/getLoader']
				})
			}
		},
		created () {
			this.getCounts()
			this.$store.dispatch('trips/setConfirmedAction', this.status)
            this.$store.dispatch('trips/setCurrentUrl', '/trips')
			this.$store.dispatch('trips/getList', 1).then(response => {
				this.loading = this.$store.getters['trips/getLoader']
            })
            this.dialog = this.$store.getters['trips/getDialog']
		},
		methods: {
            closeDialog () {
				this.$store.dispatch('trips/DiagSet')
			},
			gettrips () {
				this.$store.dispatch('trips/setConfirmedAction', this.status)
                this.$store.dispatch('trips/setCurrentUrl', '/trips')
				this.$store.dispatch('trips/getList', 1).then(response => {
					this.loading = this.$store.getters['trips/getLoader']
				})
				if (this.trips.length > 0) {
					this.numOfunverifiedtrips = []
					this.trips.forEach(elem => {
						if (elem.id_verified === 0 || elem.approved_to_drive === 0) {
							this.numOfunverifiedtrips.push(elem)
						}
					})
					this.loading = this.$store.getters['trips/getLoader']
				} else {
					this.loading = this.$store.getters['trips/getLoader']
				}
			},
			loadingStatus () {
				return this.$store.getters['trips/getLoader']
            },
            getCounts () {
                this.loading = true
                this.axios.get('/popUsers?model=Trip&status=' + this.status + '&type=' + this.type,
				{
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("id_token")
                    }
                })
				.then(response => {
                    this.loading = false
                    // console.log(response.data)
                    this.data = response.data
				})
				.catch(error => {
					console.log(error)
					this.loading = false
				})
            },
            deleteUser (id) {
                this.loading = true
                this.axios.post('/trips/delete/' + id, {},
				{
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("id_token")
                    }
                })
				.then(response => {
					this.loading = false
                    this.gettrips()
				})
				.catch(error => {
					console.log(error)
					this.loading = false
				})
            },

            restoreUser (id) {
                this.loading = true
                this.axios.post('/trips/restore/' + id, {},
				{
					headers: {
						Authorization: "Bearer " + localStorage.getItem("id_token")
					}
				})
				.then(response => {
					this.loading = false
                    this.gettrips()
				})
				.catch(error => {
					console.log(error)
					this.loading = false
				})
            },

            showDeleteDialog (value) {
                this.dialogDelete = true
                this.selectedItem = value
            },

            showRestoreDialog (value) {
                this.dialogRestore = true
                this.selectedItem = value
            }
        },
        filters: {
            toNumberFormat (value) {
                value = value > 0 ? value : 0
                return `${value.toLocaleString()}`
            }
        },
		mounted () {
			// this.gettrips()
		}
    }
</script>