<template>
<v-container fluid grid-list-md style="overflow: auto;">
		<v-slide-y-transition mode="out-in">
			<v-layout wrap fill-height>
				<!-- buttons for export(download table) and button for refresh page after export table.
        button refresh page is because after export can't expand table. After refresh it work perfectly.
           -->
				 <v-card>
             <!-- tooltip for export to excel button -->
              <v-tooltip bottom>
                <v-btn @click="exportTableToExcel('tableID', 'ESAR-Earnings')" outlined  color="primary" slot="activator" id="downloadButton">Export to excel</v-btn>
                  <span>Click here for export table in excel</span>
              </v-tooltip>
              <!-- tooltip end for export to excel button -->
              <!-- tooltip for refresh page button -->
              <v-tooltip bottom>
                <v-btn outlined  color="primary" @click="refreshPage" id="downloadButton" slot="activator" dark>Refresh page after export</v-btn>
                  <span>Click here for refresh page</span>
              </v-tooltip>
               <!-- tooltip end for  refresh page button -->
           <!-- title for name table -->
					<v-card-title>
							
						
						<v-flex xs12 sm6 md6 lg6>
							<v-md-date-range-picker
								show-year-select
								max-year="2030"
    							min-year="2010"
								@change="handleChange"
							>

							</v-md-date-range-picker>
						</v-flex>
						

						<v-flex xs12 sm6 md6 lg6>
						<v-text-field
							v-model="search"
							append-icon="search"
							label="Search"
							single-line
							hide-details
							>
						</v-text-field>
						</v-flex>
					</v-card-title>
          <!-- there is begin table -->
					<v-data-table
						:headers="headers"
						:items="users"
						id="tableID"
            :loading="loadingStatus()"
						:hide-default-footer="false"
						:hideActions=true
						:footer-props="{ disablePagination : false, disableItemsPerPage : false }"
            :single-expand="singleExpand"
						:expanded.sync="expanded"
						show-expand
						>
            <template v-slot:expanded-item="{ headers, item }" v-if="ExportNotShow" style="display: none!important;">
              <td :colspan="headers.length">
                <selected-earnings  :userId="item.id"></selected-earnings>
              </td>
            </template>

						<!-- <template slot="items" slot-scope="props"> -->
              <!-- props.expanded is for expanded row for additionnal details -->
							<!-- <tr class="tr-click" @click="props.expanded = !props.expanded">
								<td  class="text-xs-left">{{ props.item.id }}</td>
								<td  class="text-xs-left">{{ props.item.bank_name }}</td>
								<td  class="text-xs-left">{{ props.item.iban }}</td>
								<td  class="text-xs-left">{{ props.item.account_number }}</td>
								<td  class="text-xs-left">{{ props.item.first_name }}</td>
								<td  class="text-xs-left">{{ props.item.holder_name }}</td>
								<td  class="text-xs-left">{{ props.item.last_name }}</td>
								<td  class="text-xs-left">{{ props.item.phone_number }}</td>
								<td  class="text-xs-left">{{ props.item.penalty_amount }}</td>
                <td  class="text-xs-left">{{ props.item.earnings }}</td>
							</tr>
						</template> -->
						<!-- expanding component -->
						<!-- <template slot="expand" slot-scope="props" v-if="ExportNotShow" style="display: none!important;"> -->
              <!-- selected-earnings is new child component which get additional details -->
							<!-- <selected-earnings  :userId="props.item.id"></selected-earnings> -->
							<!-- <selected-earnings  :userId="props.item.id" @click="showDetails(props.item.id)"></selected-earnings> -->
						<!-- </template> -->
						    <!-- <v-alert slot="no-results" :value="true" color="error" icon="warning">
							      Your search for "{{ search }}" found no results.
						    </v-alert> -->
					</v-data-table>
          <v-progress-linear v-if="loadingStatus()" :indeterminate="true"></v-progress-linear>
          <paginate store="earnings" collection="meta"/>
				</v-card>
			</v-layout>
		</v-slide-y-transition>
	</v-container>
</template>

<script>
import paginate from './paginate'
export default {
  components: {
			paginate
		},
  data () {
    return {
      expanded: [],
      singleExpand: false,
      dates: [],
      search: "",
      ExportNotShow: true,
      loading: false,
      trips: "",
      pagination: {
          sortBy: 'id',
					rowsPerPage: 10
      },
      headers: [
        { text: "ID", value: "id" },
        { text: "Bank Name", value: "bank_name" },
        { text: "Iban", value: "iban" },
        { text: "Bank Number", value: "account_number" },
        { text: "First Name", value: "first_name" },
        { text: "Holder Name", value: "holder_name" },
        { text: "Last Name", value: "last_name" },
        { text: "Phone number", value: "phone_number" },
        { text: "Penalty", value: "penalty_amount" },
        { text: "Earnings", value: "earnings" }
      ],
      // users: [],
      combinate: []
    }
  },
  computed: {
			users: {
				get () {
					return this.$store.state.earnings.users
				}
			},
			pages () {
				if (this.pagination.rowsPerPage == null ||
					this.pagination.totalItems == null
				) return 0

				return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
			}
		},
		watch: {
			loading (newVal, oldVal) {
				this.loading = this.loadingStatus()
			},
			search (newVal, oldVal) {
        this.$store.dispatch('earnings/setCurrentUrl', '/report/earners/get/all')
				this.$store.dispatch('earnings/setSearchText', newVal)
				this.$store.dispatch('earnings/getList', 1).then(response => {
					this.loading = this.$store.getters['earnings/getLoader']
				})
			},
			dates (newVal, oldVal) {
				this.$store.dispatch('earnings/setCurrentUrl', '/report/earners/get/all')
				this.$store.dispatch('earnings/setDateRangeNow', newVal)
				this.$store.dispatch('earnings/getList', 1).then(response => {
					this.loading = this.$store.getters['earnings/getLoader']
				})
			}
    },
  methods: {
    getUsers () {
      this.$store.dispatch('earnings/Unset_Date_range')
      this.$store.dispatch('earnings/setCurrentUrl', '/report/earners/get/all')
      this.$store.dispatch('earnings/getList', 1).then(response => {
        this.loading = this.$store.getters['earnings/getLoader']
      })
      if (this.users.length > 0) {
					this.users = this.users
          this.loading = this.$store.getters['earnings/getLoader']
			} else {
					this.loading = this.$store.getters['earnings/getLoader']
			}
    },
    loadingStatus () {
				return this.$store.getters['earnings/getLoader']
		},
    exportTableToExcel (tableID, filename = '') {
          this.ExportNotShow = false
          let downloadLink
          let dataType = 'application/vnd.ms-excel'
          let tableSelect = document.getElementById(tableID).cloneNode(true)
          tableSelect.querySelectorAll('.datatable__expand-row').forEach(expandrowdelete => expandrowdelete.remove())
          tableSelect.querySelectorAll('.material-icons').forEach(icondelete => icondelete.remove())
          tableSelect.querySelectorAll('.datatable__actions').forEach(rowperpagedelete => rowperpagedelete.remove())
          let tableHTML = tableSelect.outerHTML.replace(/ /g, '%20')
          // Specify file name
          filename = filename ? filename + '.xls' : 'excel_data.xls'
          // Create download link element
          downloadLink = document.createElement("a")
          document.body.appendChild(downloadLink)
          if (navigator.msSaveOrOpenBlob) {
              let blob = new Blob(['\ufeff', tableHTML], {
                  type: dataType
              })
              navigator.msSaveOrOpenBlob(blob, filename)
          } else {
              downloadLink.href = 'data:' + dataType + ', ' + tableHTML
              downloadLink.download = filename
              downloadLink.click()
          }
      },
      refreshPage () {
        window.location.reload()
      },
			handleChange (dates) {
				this.dates.splice(0, this.dates.length)
				for (let i = 0; i < dates.length; i++) {
					this.dates.push(dates[i].format("YYYY-MM-DD"))
				}
			}
  },
  created () {
    this.$store.dispatch('earnings/setCurrentUrl', '/report/earners/get/all')
    this.$store.dispatch('earnings/getList', 1).then(response => {
			this.loading = this.$store.getters['earnings/getLoader']
		})
    this.getUsers()
    this.$bus.$on("sumofTrip", (userid, sum) => {
      this.users.find(u => u.id === userid).sumofTrips = sum
    })
  },
  beforeDestroy () {
    this.$bus.$off("sumofTrip")
  }
}
</script>

<style scoped>
#downloadButton {
  width: 220px;
  height: 50px;
}
#tableID{
  cursor: pointer;
}
</style>
