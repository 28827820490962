<template>
	<!-- spinner -->
	<v-container  v-if="loading">
		<div justify-center>
			<v-progress-circular :size="50" indeterminate color="primary"></v-progress-circular>
		</div>
	</v-container>
	<!-- data -->
	<div v-else class="vd-data-container">
		<!-- first row -->
		<div>
			<!-- first column -->
			<div column>
			<v-flex class="vd-text-container" xs12 sm6 md6 lg6>
				<v-card>
					<v-list-item class="vd-list-item">
						<v-list-item-content>Owner Name:</v-list-item-content>
						<v-list-item-content class="align-end">{{ trip.owner ? trip.owner.first_name : '' }} - {{ trip.owner ? trip.owner.last_name : '' }}</v-list-item-content>
					</v-list-item>
					<v-list-item class="vd-list-item">
						<v-list-item-content>Owner Email:</v-list-item-content>
						<v-list-item-content class="align-end">{{ trip.owner ? trip.owner.email : '' }}</v-list-item-content>
					</v-list-item>
					<v-list-item class="vd-list-item">
						<v-list-item-content>Owner Country Code:</v-list-item-content>
						<v-list-item-content class="align-end">{{ trip.owner ? trip.owner.country_code : '' }}</v-list-item-content>
					</v-list-item>
					<v-list-item class="vd-list-item">
						<v-list-item-content>Owner Phone:</v-list-item-content>
						<v-list-item-content class="align-end">{{ trip.owner ? trip.owner.phone_number : '' }}</v-list-item-content>
					</v-list-item>

					<v-list-item class="vd-list-item">
						<v-list-item-content>Start date:</v-list-item-content>
						<v-list-item-content class="align-end">{{ trip.start_date }}</v-list-item-content>
					</v-list-item>
					<v-list-item class="vd-list-item">
						<v-list-item-content>End date:</v-list-item-content>
						<v-list-item-content class="align-end">{{ trip.end_date }}</v-list-item-content>
					</v-list-item>
					<v-list-item class="vd-list-item">
						<v-list-item-content>Created at:</v-list-item-content>
						<v-list-item-content class="align-end">{{ trip.created_at }}</v-list-item-content>
					</v-list-item>
					<v-list-item class="vd-list-item">
						<v-list-item-content>Update at:</v-list-item-content>
						<v-list-item-content class="align-end">{{ trip.updated_at }}</v-list-item-content>
					</v-list-item>
					<v-list-item class="vd-list-item">
						<v-list-item-content>Notice time:</v-list-item-content>
						<v-list-item-content class="align-end">{{ trip.notice_time }}</v-list-item-content>
					</v-list-item>
					<v-list-item class="vd-list-item">
						<v-list-item-content>Pickup location:</v-list-item-content>
						<v-list-item-content class="align-end">{{ trip.pickup_location }}</v-list-item-content>
					</v-list-item>
				</v-card>
			</v-flex>
			<v-flex class="vd-text-container">
				<v-card>
					<v-list-item class="vd-list-item">
						<v-list-item-content>
							<div>
							<v-icon color="green">monetization_on</v-icon>
							</div>
						</v-list-item-content>
						<v-list-item-content class="align-end" v-if="totalBill.length > 0">{{totalBill}} SAR</v-list-item-content>
						<v-list-item-content class="align-end" v-else> 0 </v-list-item-content>
					</v-list-item>
				</v-card>
			</v-flex>
			</div>
			<!-- second column -->
			<v-flex class="vd-text-container" xs12 sm6 md6 lg6>
				<v-card>
					<v-list-item class="vd-list-item">
						<v-list-item-content>Renter Name:</v-list-item-content>
						<v-list-item-content class="align-end">{{ trip.renter ? trip.renter.first_name : '' }} - {{ trip.renter ? trip.renter.last_name : '' }}</v-list-item-content>
					</v-list-item>
					<v-list-item class="vd-list-item">
						<v-list-item-content>Renter Email:</v-list-item-content>
						<v-list-item-content class="align-end">{{ trip.renter ? trip.renter.email : '' }}</v-list-item-content>
					</v-list-item>
					<v-list-item class="vd-list-item">
						<v-list-item-content>Renter Country Code:</v-list-item-content>
						<v-list-item-content class="align-end">{{ trip.renter ? trip.renter.country_code : '' }}</v-list-item-content>
					</v-list-item>
					<v-list-item class="vd-list-item">
						<v-list-item-content>Renter Phone:</v-list-item-content>
						<v-list-item-content class="align-end">{{ trip.renter ? trip.renter.phone_number : '' }}</v-list-item-content>
					</v-list-item>
					<v-list-item class="vd-list-item">
						<v-list-item-content>Renter confirm trip:</v-list-item-content>
						<v-list-item-content class="align-end">{{ trip.renter_confirm_trip }}</v-list-item-content>
					</v-list-item>
					<v-list-item class="vd-list-item">
						<v-list-item-content>Owner confirm trip:</v-list-item-content>
						<v-list-item-content class="align-end">{{ trip.owner_confirm_trip}}</v-list-item-content>
					</v-list-item>
					<v-list-item class="vd-list-item">
						<v-list-item-content>Renter confirm trip update:</v-list-item-content>
						<v-list-item-content class="align-end">{{ trip.renter_confirm_trip_update}}</v-list-item-content>
					</v-list-item>
					<v-list-item class="vd-list-item">
						<v-list-item-content>Owner confirm trip update:</v-list-item-content>
						<v-list-item-content class="align-end">{{ trip.owner_confirm_trip_update}}</v-list-item-content>
					</v-list-item>
					<v-list-item class="vd-list-item">
						<v-list-item-content>Booked instantly:</v-list-item-content>
						<v-list-item-content class="align-end">
							<v-icon v-if="trip.booked_instantly" color="green">check_circle</v-icon>
							<v-icon v-else color="red">highlight_off</v-icon>
						</v-list-item-content>
					</v-list-item>
					<v-list-item class="vd-list-item">
						<v-list-item-content>Delivery on airport:</v-list-item-content>
						<v-list-item-content class="align-end">
							<v-icon v-if="trip.delivery_on_airport" color="green">check_circle</v-icon>
							<v-icon v-else color="red">highlight_off</v-icon>
						</v-list-item-content>
					</v-list-item>
					<v-list-item class="vd-list-item">
						<v-list-item-content>Delivery on car location:</v-list-item-content>
						<v-list-item-content class="align-end">
							<v-icon v-if="trip.delivery_on_car_location" color="green">check_circle</v-icon>
							<v-icon v-else color="red">highlight_off</v-icon>
						</v-list-item-content>
					</v-list-item>
					<v-list-item class="vd-list-item">
						<v-list-item-content>Delivery on renter location:</v-list-item-content>
						<v-list-item-content class="align-end">
							<v-icon v-if="trip.delivery_on_renter_location" color="green">check_circle</v-icon>
							<v-icon v-else color="red">highlight_off</v-icon>
						</v-list-item-content>
					</v-list-item>
					<v-list-item class="vd-list-item">
						<v-list-item-content>Trip modified:</v-list-item-content>
						<v-list-item-content class="align-end">
							<v-icon v-if="trip.trip_modified" color="green">check_circle</v-icon>
							<v-icon v-else color="red">highlight_off</v-icon>
						</v-list-item-content>
					</v-list-item>
				</v-card>
			</v-flex>
		</div>
		<!-- photos section -->

		<div>
			<v-expansion-panels>
			<v-expansion-panel class="vd-photos-expansion">
				<v-expansion-panel-header>
					<h3>{{trip ? `Photos: ${trip.trip_images.length}` : 'Photos'}}</h3>
				</v-expansion-panel-header>
				<v-expansion-panel-content>
				<v-divider></v-divider>
				<!-- <div slot="header"><h3>{{trip ? `Photos: ${trip.trip_images.length}` : 'Photos'}}</h3></div> -->
					<v-card>
						<v-container fluid grid-list-md>
							<div row wrap>
								<v-flex
									v-for="(image, index) in trip.trip_images"
									lg4 md6 xs12 sm6
									:key="index"
									>
									<v-card class="elevation-10">
										<v-card-media
											:src="$imageURL + image.image_path"
											height="200px"
											>
										</v-card-media>
										<v-card-title>
											<div>created at: {{ image.created_at }}</div>
											<div>updated at: {{ image.updated_at }}</div>
											<div>
												before trip:
												<v-icon v-if="image.before_trip" color="green">check_circle</v-icon>
												<v-icon v-else color="red">highlight_off</v-icon>
											</div>
										</v-card-title>
									</v-card>
								</v-flex>
							</div>
						</v-container>
					</v-card>
				</v-expansion-panel-content>
			</v-expansion-panel>
			<v-expansion-panel v-if="cars.length > 0" class="vd-photos-expansion">
				<v-expansion-panel-header>
					<div>
						<h3>Cars</h3>
					</div>
				</v-expansion-panel-header>
				<v-expansion-panel-content v-for="(car, index) in cars" :key="index">
					
					<!-- <div slot="header">
						<div>
							 <div class="vd-cars-numbers">
								<h3>{{ index + 1 }} </h3>
							</div> 
							<h3>{{`${car.production_year} ${car.car_manufacturer} ${car.car_model} ${car.trim}`}}</h3>
						</div>
					</div> -->
					<v-divider></v-divider>
					<!-- DATA SECTION -->
					<div>
						<!-- first column -->
						<v-flex class="vd-text-container" xs12 sm6 md6 lg4>
							<v-card>
								<v-list-item class="vd-list-item">
									<v-list-item-content>Style:</v-list-item-content>
									<v-list-item-content class="align-end">{{ car.style }}</v-list-item-content>
								</v-list-item>
								<v-list-item class="vd-list-item">
									<v-list-item-content>Odometer:</v-list-item-content>
									<v-list-item-content class="align-end">{{ car.car_odometer ? car.car_odometer : car.real_odometer }}</v-list-item-content>
								</v-list-item>
								<v-list-item class="vd-list-item">
									<v-list-item-content>Value:</v-list-item-content>
									<v-list-item-content class="align-end">{{ car.car_value }}</v-list-item-content>
								</v-list-item>
								<v-list-item class="vd-list-item">
									<v-list-item-content>Created at:</v-list-item-content>
									<v-list-item-content class="align-end">{{ car.created_at }}</v-list-item-content>
								</v-list-item>
								<v-list-item class="vd-list-item">
									<v-list-item-content>Updated at:</v-list-item-content>
									<v-list-item-content class="align-end">{{ car.updated_at }}</v-list-item-content>
								</v-list-item>
								<v-list-item class="vd-list-item">
									<v-list-item-content>Deposit:</v-list-item-content>
									<v-list-item-content class="align-end">{{ car.deposit }}</v-list-item-content>
								</v-list-item>
								<v-list-item class="vd-list-item">
									<v-list-item-content>Star:</v-list-item-content>
									<v-list-item-content class="align-end">{{ car.stars }}</v-list-item-content>
								</v-list-item>
							</v-card>
						</v-flex>
						<!-- second column -->
						<v-flex class="vd-text-container lr-border" xs12 sm6 md6 lg4>
							<v-card>
								<v-list-item class="vd-list-item">
									<v-list-item-content>Shortest trip:</v-list-item-content>
									<v-list-item-content class="align-end">{{ car.short_trip }}</v-list-item-content>
								</v-list-item>
								<v-list-item class="vd-list-item">
									<v-list-item-content>Long trip:</v-list-item-content>
									<v-list-item-content class="align-end">{{ car.long_trip }}</v-list-item-content>
								</v-list-item>
								<v-list-item class="vd-list-item">
									<v-list-item-content>Car notice:</v-list-item-content>
									<v-list-item-content class="align-end">{{ car.notice }}</v-list-item-content>
								</v-list-item>
								<v-list-item class="vd-list-item">
									<v-list-item-content>Guest location notice:</v-list-item-content>
									<v-list-item-content class="align-end">{{ car.guest_location_notice }}</v-list-item-content>
								</v-list-item>
								<v-list-item class="vd-list-item">
									<v-list-item-content>Car location notice:</v-list-item-content>
									<v-list-item-content class="align-end">{{ car.car_location_notice }}</v-list-item-content>
								</v-list-item>
								<v-list-item class="vd-list-item">
									<v-list-item-content>Airport notice:</v-list-item-content>
									<v-list-item-content class="align-end">{{ car.airport_notice }}</v-list-item-content>
								</v-list-item>
								<v-list-item class="vd-list-item">
									<v-list-item-content>Key handoff:</v-list-item-content>
									<v-list-item-content class="align-end">{{ car.key_handoff }}</v-list-item-content>
								</v-list-item>
								<v-list-item class="vd-list-item">
									<v-list-item-content>Parking details:</v-list-item-content>
									<v-list-item-content class="align-end">{{ car.parking_details }}</v-list-item-content>
								</v-list-item>
							</v-card>
						</v-flex>
						<!-- third column -->
						<v-flex class="vd-text-container" xs12 sm6 md6 lg4>
							<v-card>
								<v-list-item class="vd-list-item">
									<v-list-item-content>ID:</v-list-item-content>
									<v-list-item-content class="align-end">{{ car.id }}</v-list-item-content>
								</v-list-item>
								<v-list-item class="vd-list-item">
									<v-list-item-content>Car active:</v-list-item-content>
									<v-list-item-content class="align-end">
										<v-icon v-if="car.car_is_active" color="green">check_circle</v-icon>
										<v-icon v-else color="red">highlight_off</v-icon>
									</v-list-item-content>
								</v-list-item>
								<v-list-item class="vd-list-item">
									<v-list-item-content>Verified insurance:</v-list-item-content>
									<v-list-item-content class="align-end">
										<v-icon v-if="car.is_insurance_verified" color="green">check_circle</v-icon>
										<v-icon v-else color="red">highlight_off</v-icon>
									</v-list-item-content>
								</v-list-item>
								<v-list-item class="vd-list-item">
									<v-list-item-content>Verified registration:</v-list-item-content>
									<v-list-item-content class="align-end">
										<v-icon v-if="car.is_registration_car_verified" color="green">check_circle</v-icon>
										<v-icon v-else color="red">highlight_off</v-icon>
									</v-list-item-content>
								</v-list-item>
								<v-list-item class="vd-list-item">
									<v-list-item-content>Weekend trip:</v-list-item-content>
									<v-list-item-content class="align-end">
										<v-icon v-if="car.weekend_trip" color="green">check_circle</v-icon>
										<v-icon v-else color="red">highlight_off</v-icon>
									</v-list-item-content>
								</v-list-item>
								<v-list-item class="vd-list-item">
									<v-list-item-content>Long term trip:</v-list-item-content>
									<v-list-item-content class="align-end">
										<v-icon v-if="car.long_term_trip" color="green">check_circle</v-icon>
										<v-icon v-else color="red">highlight_off</v-icon>
									</v-list-item-content>
								</v-list-item>
								<v-list-item class="vd-list-item">
									<v-list-item-content>Paid advertaising:</v-list-item-content>
									<v-list-item-content class="align-end">
										<v-icon v-if="car.paid_advertising" color="green">check_circle</v-icon>
										<v-icon v-else color="red">highlight_off</v-icon>
									</v-list-item-content>
								</v-list-item>
							</v-card>
						</v-flex>
					</div>
				</v-expansion-panel-content>
			</v-expansion-panel>


			<v-expansion-panel class="vd-photos-expansion">
				<v-expansion-panel-header>
					<div>
						<h3>{{ trip ? `Trip bills: ${trip.trip_bills.length}` : 'Trip bills' }}</h3>
					</div>
				</v-expansion-panel-header>
				<v-expansion-panel-content>
					<div slot="header">
						<!-- <div>
							<h3>{{ trip ? `Trip bills: ${trip.trip_bills.length}` : 'Trip bills' }}</h3>
						</div> -->
					</div>
					<v-divider></v-divider>
					<!-- BILLS SECTION -->
					<div v-for="(bill, index) in trip.trip_bills" :key="index" style="borderBottom:2px solid #dadada">
						<!-- first column -->
						<v-flex class="vd-text-container" xs12 sm12 md6 lg6 style="borderRight:1px solid #dadada">
							<v-card>
								<v-list-item class="vd-list-item">
									<v-list-item-content>Average price:</v-list-item-content>
									<v-list-item-content class="align-end">{{ bill.average_price }}</v-list-item-content>
								</v-list-item>
								<v-list-item class="vd-list-item">
									<v-list-item-content>Delivery fee:</v-list-item-content>
									<v-list-item-content class="align-end">{{ bill.delivery_fee }}</v-list-item-content>
								</v-list-item>
								<v-list-item class="vd-list-item">
									<v-list-item-content>Deposit:</v-list-item-content>
									<v-list-item-content class="align-end">{{ bill.deposit }}</v-list-item-content>
								</v-list-item>
								<v-list-item class="vd-list-item">
									<v-list-item-content>Monthly discount:</v-list-item-content>
									<v-list-item-content class="align-end">{{ bill.discount_month }}</v-list-item-content>
								</v-list-item>
								<v-list-item class="vd-list-item">
									<v-list-item-content>Weekly discount:</v-list-item-content>
									<v-list-item-content class="align-end">{{ bill.discount_week }}</v-list-item-content>
								</v-list-item>
								<v-list-item class="vd-list-item">
									<v-list-item-content>Weekly discount:</v-list-item-content>
									<v-list-item-content class="align-end">{{ bill.discount_week }}</v-list-item-content>
								</v-list-item>
								<v-list-item class="vd-list-item">
									<v-list-item-content>Promo code:</v-list-item-content>
									<v-list-item-content class="align-end">{{ bill.promo_code }}</v-list-item-content>
								</v-list-item>
								<v-list-item class="vd-list-item">
									<v-list-item-content>Promo code discount:</v-list-item-content>
									<v-list-item-content class="align-end">{{ bill.promo_code_discount }}</v-list-item-content>
								</v-list-item>
								<v-list-item class="vd-list-item">
									<v-list-item-content>Promo discount:</v-list-item-content>
									<v-list-item-content class="align-end">{{ bill.promo_discount }}</v-list-item-content>
								</v-list-item>
								<v-list-item class="vd-list-item">
									<v-list-item-content>Service fee:</v-list-item-content>
									<v-list-item-content class="align-end">{{ bill.service_fee }}</v-list-item-content>
								</v-list-item>
							</v-card>
						</v-flex>
						<!-- second column -->
						<v-flex class="vd-text-container" xs12 sm12 md6 lg6>
							<v-card>
								<v-list-item class="vd-list-item">
									<v-list-item-content>Trip paid:</v-list-item-content>
									<v-list-item-content class="align-end">
										<v-icon v-if="bill.trip_paid" color="green">check_circle</v-icon>
										<v-icon v-else color="red">highlight_off</v-icon>
									</v-list-item-content>
								</v-list-item>
								<v-list-item class="vd-list-item">
									<v-list-item-content>ESAR earning:</v-list-item-content>
									<v-list-item-content class="align-end">{{ bill.esar_earning }}</v-list-item-content>
								</v-list-item>
								<v-list-item class="vd-list-item">
									<v-list-item-content>Owner earning:</v-list-item-content>
									<v-list-item-content class="align-end">{{ bill.owner_earning }}</v-list-item-content>
								</v-list-item>
								<v-list-item class="vd-list-item">
									<v-list-item-content>Price with discount:</v-list-item-content>
									<v-list-item-content class="align-end">{{ bill.price_with_discount }}</v-list-item-content>
								</v-list-item>
								<v-list-item class="vd-list-item">
									<v-list-item-content>Price with promo discount:</v-list-item-content>
									<v-list-item-content class="align-end">{{ bill.price_with_promo_discount}}</v-list-item-content>
								</v-list-item>
								<v-list-item class="vd-list-item">
									<v-list-item-content>Transaction reference:</v-list-item-content>
									<v-list-item-content class="align-end">{{ bill.tran_ref }}</v-list-item-content>
								</v-list-item>
								<v-list-item class="vd-list-item">
									<v-list-item-content>Trip bill status:</v-list-item-content>
									<v-list-item-content class="align-end">{{ bill.trip_bill_status }}</v-list-item-content>
								</v-list-item>
								<v-list-item class="vd-list-item">
									<v-list-item-content>Trip days:</v-list-item-content>
									<v-list-item-content class="align-end">{{ bill.trip_days }}</v-list-item-content>
								</v-list-item>
								<v-list-item class="vd-list-item">
									<v-list-item-content>Trip price:</v-list-item-content>
									<v-list-item-content class="align-end">{{ bill.trip_price }}</v-list-item-content>
								</v-list-item>
								<v-list-item class="vd-list-item">
									<v-list-item-content>Trip total:</v-list-item-content>
									<v-list-item-content class="align-end">{{ bill.trip_total }}</v-list-item-content>
								</v-list-item>
								<!-- <v-list-item class="vd-list-item" >
									<v-list-item-content>Trip bill start date</v-list-item-content>
									<v-list-item-content class="align-end" v-for="(startDate, index) in billDate" :key="index">{{ startDate.trip_start_date }}</v-list-item-content>
								</v-list-item>

								<v-list-item class="vd-list-item" >
									<v-list-item-content> Trip bill end date</v-list-item-content>
									<v-list-item-content class="align-end" v-for="(endDate, index) in billDate" :key="index">{{ endDate.trip_end_date }}</v-list-item-content>
								</v-list-item> -->


								<v-list-item class="vd-list-item" >
									<v-list-item-content>Trip bill start date</v-list-item-content>
									<v-list-item-content class="align-end">{{bill.trip_start_date}}</v-list-item-content>
								</v-list-item>
								<v-list-item class="vd-list-item" >
									<v-list-item-content>Trip bill start date</v-list-item-content>
									<v-list-item-content class="align-end">{{bill.trip_end_date}}</v-list-item-content>
								</v-list-item>
								<!-- <v-list-item class="vd-list-item" >
									<v-list-item-content> Trip bill end date</v-list-item-content>
									<v-list-item-content class="align-end">{{bill.end_date}}</v-list-item-content>
								</v-list-item> -->
							</v-card>
						</v-flex>
					</div>
					<!-- TOTAL -->
					<div>
						<v-flex class="vd-text-container" xs12 sm6 md6 lg6>
							<v-card>
								<v-list-item class="vd-list-item">
									<v-list-item-content>
										TOTAL:
										<!-- <div>
											<v-icon color="green">monetization_on</v-icon>
										</div> -->
									</v-list-item-content>
									<!-- <div v-if="this.trip.id == this.dateOfbill.id">
									<ul v-for="dateOfBill in billDate" >
										<li > DDDD {{dateOfBill.trip_start_date}}</li>
										<li > WWWW {{dateOfBill.trip_end_date}}</li>
									</ul>
										</div> -->
									<v-list-item-content class="align-end">{{totalBill}} SAR</v-list-item-content>
									<!-- <v-list-item-content class="align-end">{{totalBill}} SAR</v-list-item-content> -->
								</v-list-item>
							</v-card>
						</v-flex>
					</div>
				</v-expansion-panel-content>
			</v-expansion-panel>

			<v-expansion-panel class="vd-photos-expansion">
				<v-expansion-panel-header>
					<div>
						<h3>{{ messages ? `Chat: ${messages.length}` : 'chat: 0' }}</h3>
					</div>
				</v-expansion-panel-header>
				<v-expansion-panel-content>
					<!-- <div slot="header">
						<div>
							<h3>{{ messages ? `Chat: ${messages.length}` : 'chat: 0' }}</h3>
						</div>
					</div> -->
					<v-divider></v-divider>
					<!-- MESSAGE SECTION -->
					<div  style="borderBottom:2px solid #dadada">
						<v-flex class="vd-text-container" xs12 sm12 md12 lg12 style="borderRight:1px solid #dadada">
							<v-card v-for="(m, index) in messages" :key="index">
								<v-list-item class="vd-list-item">
									<v-list-item-content>
										<span style="fontWeight:bold">{{ usersObj[m.user_id]["full"] }}</span>
										<span>{{ m.created_at }}</span>
									</v-list-item-content>
								</v-list-item>
								<v-list-item class="vd-list-item">
									<v-list-item-content>{{ m.message }}</v-list-item-content>
								</v-list-item>
								<v-divider></v-divider>
							</v-card>
						</v-flex>
					</div>
				</v-expansion-panel-content>
			</v-expansion-panel>
			</v-expansion-panels>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		tripId: {
			type: String
		}
	},

	data () {
		return {
			trip: '',
			loading: false,
			bill: '',
			cars: [],
			chats: '',
			messages: '',
			usersObj: '',
			billDate: '',
			billDatePrint: '',
			totalBill: ''
		}
	},

	methods: {
		getTrip () {
			this.loading = true
			this.axios.get('/trip/' + this.tripId, {
				headers: {
					Authorization: "Bearer " + localStorage.getItem("id_token")
				}
			})
			.then(response => {
				this.trip = response.data
				this.bill = this.trip.trip_bills
				this.totalBill = 0
				this.bill.forEach(elem => {
					if (elem.trip_bill_status === 'accepted') {
						this.totalBill += parseInt(elem.trip_total)
					}
				})
				this.billDate = []
				this.billDatePrint = []
				this.trip.trip_bills.forEach(elem => {
					this.billDate.push(elem.trip_start_date)
				})
				this.cars.push(response.data.car)
				// console.log(this.cars)
				this.loading = false
			})
			.catch(error => {
				console.log(error)
				this.loading = false
			})
		},
		getChats () {
			this.loading = true
			this.axios.get('chat/trip/' + this.tripId, {
				headers: {
					Authorization: "Bearer " + localStorage.getItem("id_token")
				}
			})
			.then(response => {
				this.chats = response.data
				this.messages = this.chats.messages
				this.renter = this.chats.renter
				this.owner = this.chats.owner
				if (this.chats) {
					this.usersObj = this.createOR(this.renter, this.owner)
				}
				this.loading = false
			})
			.catch(error => {
				console.log(error)
				this.loading = false
			})
		},
		sumBills (arr) {
			return arr.reduce((acc, cur) => {
						return acc + cur.trip_total
			}, 0)
		},
		createOR (owner, renter) {
			return {
				[owner.id]: {
					firstName: owner.first_name,
					lasttName: owner.last_name,
					full: `${owner.first_name} ${owner.last_name} ${owner.id}`
				},
				[renter.id]: {
					firstName: renter.first_name,
					lasttName: renter.last_name,
					full: `${renter.first_name} ${renter.last_name} ${renter.id}`
				}
			}
		}
	},

	created () {
		this.getTrip()
		this.getChats()
	}
}
</script>
