<template>
    <v-card class="card">
        <v-card-text class="pa-1">
            <line-chart :data="linearMultipleChartdata" />
        </v-card-text>
    </v-card>
</template>
<script>
export default {
    props: {
        title: {
            type: String,
            required: false
        },
        data: {
            required: true
        },
        color: {
            type: Array,
            required: true
        }
    },
  data() {
    return {
        linearMultipleChartdata: [
        {name: 'Workout',
          data: {
            '2017-01-01 00:00:00 -0800': 3,
            '2017-01-02 00:00:00 -0800': 9,
            '2017-01-03 00:00:00 -0800': 14,
            '2017-01-04 00:00:00 -0800': 7,
            '2017-01-05 00:00:00 -0800': 4
          }
        },
        {name: 'Go to concert',
          data: {
            '2017-01-01 00:00:00 -0800': 3,
            '2017-01-02 00:00:00 -0800': 2,
            '2017-01-03 00:00:00 -0800': 5,
            '2017-01-04 00:00:00 -0800': 9,
            '2017-01-05 00:00:00 -0800': 14
          }
        },
        {name: 'Call Parents',
          data: {
            '2017-01-01 00:00:00 -0800': 9,
            '2017-01-02 00:00:00 -0800': 13,
            '2017-01-03 00:00:00 -0800': 8,
            '2017-01-04 00:00:00 -0800': 4,
            '2017-01-05 00:00:00 -0800': 9
          }
        },
        {name: 'Eat Breakfast',
          data: {
            '2017-01-01 00:00:00 -0800': 5,
            '2017-01-02 00:00:00 -0800': 3,
            '2017-01-03 00:00:00 -0800': 5,
            '2017-01-04 00:00:00 -0800': 8,
            '2017-01-05 00:00:00 -0800': 5
          }
        }
      ]
    }
  }
}
</script>

<style>
  .card {
    border-radius: 3px;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.21);
    background-color: transparent;
  }
</style>
