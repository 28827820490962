var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._t("default"),_c('b-modal',{attrs:{"id":_vm.id,"hide-footer":"","size":"xl","no-close-on-backdrop":"","title":_vm.displayText + ' Trips'}},[_c('v-container',{attrs:{"grid-list-md":""}},[_c('v-layout',{attrs:{"wrap":""},scopedSlots:_vm._u([{key:"modal-title-trip",fn:function(){return [_vm._v(" "+_vm._s(_vm.duration)+" Trips ")]},proxy:true}])},[_c('v-flex',{attrs:{"xs12":"","md4":"","lg4":"","sm":""}},[_c('v-text-field',{attrs:{"append-icon":"search","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer'),_c('vueBtn',{attrs:{"json_data":_vm.trips,"param":{
					model: _vm.model,
					search: _vm.search,
					type: _vm.type,
					status: _vm.status,
					duration:_vm.duration,
					url: '/report/getCountsRecordsExport',
					filename: _vm.duration + '_Trips'
				},"fields":_vm.headerfileExport,"fieldsPDF":_vm.headerfileExportPDF}}),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.trips,"loading":_vm.loadingStatus(),"hide-default-footer":false,"hideActions":true,"footer-props":{ disablePagination : false, disableItemsPerPage : false },"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.renter",fn:function(ref){
				var item = ref.item;
return [_vm._v(" "+_vm._s(item.renter.first_name)+" "+_vm._s(item.renter.last_name)+" ")]}},{key:"item.owner",fn:function(ref){
				var item = ref.item;
return [_vm._v(" "+_vm._s(item.owner.first_name)+" "+_vm._s(item.owner.last_name)+" ")]}},{key:"item.renter_phone",fn:function(ref){
				var item = ref.item;
return [_vm._v(" "+_vm._s(item.renter.phone_number)+" ")]}},{key:"item.owner_phone",fn:function(ref){
				var item = ref.item;
return [_vm._v(" "+_vm._s(item.owner.phone_number)+" ")]}},{key:"item.trip_days",fn:function(ref){
				var item = ref.item;
return [_vm._v(" "+_vm._s(item.trip_bill != null ? item.trip_bill.trip_days : '')+" ")]}},{key:"item.price_per_day",fn:function(ref){
				var item = ref.item;
return [_vm._v(" "+_vm._s(item.trip_bill != null ? item.trip_bill.price_per_day : '')+" ")]}},{key:"item.promo_discount",fn:function(ref){
				var item = ref.item;
return [_vm._v(" "+_vm._s(item.trip_bill != null ? item.trip_bill.promo_discount : '')+" ")]}},{key:"item.discount",fn:function(ref){
				var item = ref.item;
return [_vm._v(" "+_vm._s(item.trip_bill != null ? item.trip_bill.discount_amount : '')+" ")]}},{key:"item.tot_trip_price",fn:function(ref){
				var item = ref.item;
return [_vm._v(" "+_vm._s(item.trip_bill != null ? item.trip_bill.trip_price : '')+" ")]}},{key:"item.trans_ref",fn:function(ref){
				var item = ref.item;
return [_vm._v(" "+_vm._s(item.trip_bill != null ? item.trip_bill.tran_ref : '')+" ")]}},{key:"item.esar_commission",fn:function(ref){
				var item = ref.item;
return [_vm._v(" "+_vm._s(item.trip_bill != null ? item.trip_bill.esar_earning : '')+" ")]}},{key:"item.owner_earnings",fn:function(ref){
				var item = ref.item;
return [_vm._v(" "+_vm._s(item.trip_bill != null ? item.trip_bill.owner_earning : '')+" ")]}},{key:"item.car_id",fn:function(ref){
				var item = ref.item;
return [_vm._v(" "+_vm._s(item.car != null ? item.car.id : '')+" ")]}},{key:"item.car_manufacturer",fn:function(ref){
				var item = ref.item;
return [_vm._v(" "+_vm._s(item.car != null ? item.car.car_manufacturer : '')+" ")]}},{key:"item.car_model",fn:function(ref){
				var item = ref.item;
return [_vm._v(" "+_vm._s(item.car != null ? item.car.car_model : '')+" ")]}},{key:"item.production_year",fn:function(ref){
				var item = ref.item;
return [_vm._v(" "+_vm._s(item.car != null ? item.car.production_year : '')+" ")]}},{key:"item.note",fn:function(ref){
				var item = ref.item;
return [_c('trip-note',{attrs:{"status":item.status,"id":item.id,"title":((item.renter.first_name) + " " + (item.renter.last_name) + " @ (" + (item.pickup_location) + ")")}},[_c('v-btn',{attrs:{"icon":"","id":"show-btn"},on:{"click":function($event){$event.stopPropagation();_vm.fetchNotes(item.id); _vm.$bvModal.show(item.id)}}},[_c('v-icon',{key:item.id},[_vm._v("content_copy")]),_vm._v("Note ")],1)],1),(item.note != null && Object.entries(item.note).length != 0)?_c('div',{staticClass:"text-xs-left"},[_c('div',{staticClass:"badge badge-success",attrs:{"title":item.note.comment}},[_vm._v("Has a comment")])]):_vm._e()]}},{key:"expanded-item",fn:function(ref){
				var headers = ref.headers;
				var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('selected-trip',{attrs:{"tripId":item.id}})],1)]}},{key:"expand",fn:function(props){return [_c('selected-trip',{attrs:{"tripId":props.item.id}})]}}])},[_c('v-alert',{attrs:{"slot":"no-results","value":true,"color":"error","icon":"warning"},slot:"no-results"},[_vm._v(" Your search for \""+_vm._s(_vm.search)+"\" found no results. ")])],1),(_vm.loadingStatus())?_c('v-progress-linear',{attrs:{"indeterminate":true}}):_vm._e(),_c('paginate',{attrs:{"store":"dialogTrips","collection":"meta"}})],1)],1)],1),_c('b-button',{staticClass:"mt-3",attrs:{"block":""},on:{"click":function($event){return _vm.$bvModal.hide(_vm.id)}}},[_vm._v("Close")])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }