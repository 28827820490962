<template>
  <div>
	<slot></slot>
    <b-modal :id="id" hide-footer size="xl" no-close-on-backdrop :title="displayText + ' Trips'">
		<v-container grid-list-md>
            <v-layout wrap>
			<v-flex xs12 md4 lg4 sm>
				<v-text-field
					v-model="search"
					append-icon="search"
					label="Search"
					single-line
					hide-details
				>
				</v-text-field>
        	</v-flex>
		
			<v-spacer></v-spacer>
			<vueBtn 
				:json_data = "trips"
				:param="{
					model: model,
					search: search,
					type: type,
					status: status,
					duration:duration,
					url: '/report/getCountsRecordsExport',
					filename: duration + '_Trips'
				}"
				:fields="headerfileExport"
				:fieldsPDF="headerfileExportPDF"
			/>
			<template #modal-title-trip>
				{{duration}} Trips
			</template>
              <v-flex xs12 sm12 md12>
                  <v-data-table
						:headers="headers"
						:items="trips"
						:loading="loadingStatus()"
						:hide-default-footer=false
						:hideActions=true
						:footer-props="{ disablePagination : false, disableItemsPerPage : false }"
						:single-expand="singleExpand"
						:expanded.sync="expanded"
						show-expand
					>

						<template v-slot:item.renter="{ item }">
							{{ item.renter.first_name }} {{ item.renter.last_name }}
						</template>
						<template v-slot:item.owner="{ item }">
							{{ item.owner.first_name }} {{ item.owner.last_name }}
						</template>

						<template v-slot:item.renter_phone="{ item }">
							{{ item.renter.phone_number }}
						</template>
						<template v-slot:item.owner_phone="{ item }">
							{{ item.owner.phone_number }}
						</template>

						<template v-slot:item.trip_days="{ item }">
							{{ item.trip_bill != null ? item.trip_bill.trip_days : '' }}
						</template>
						<template v-slot:item.price_per_day="{ item }">
							{{ item.trip_bill != null ? item.trip_bill.price_per_day : '' }}
						</template>
						<template v-slot:item.promo_discount="{ item }">
							{{ item.trip_bill != null ? item.trip_bill.promo_discount : '' }}
						</template>
						<template v-slot:item.discount="{ item }">
							{{ item.trip_bill != null ? item.trip_bill.discount_amount : '' }}
						</template>
						<template v-slot:item.tot_trip_price="{ item }">
							{{ item.trip_bill != null ? item.trip_bill.trip_price : '' }}
						</template>
						<template v-slot:item.trans_ref="{ item }">
							{{ item.trip_bill != null ? item.trip_bill.tran_ref : '' }}
						</template>
						<template v-slot:item.esar_commission="{ item }">
							{{ item.trip_bill != null ? item.trip_bill.esar_earning : '' }}
						</template>



						<template v-slot:item.owner_earnings="{ item }">
							{{ item.trip_bill != null ? item.trip_bill.owner_earning : '' }}
						</template>
						<template v-slot:item.car_id="{ item }">
							{{ item.car != null ? item.car.trip_price : '' }}
						</template>
						<template v-slot:item.car_id="{ item }">
							{{ item.car != null ? item.car.id : '' }}
						</template>
						<template v-slot:item.car_manufacturer="{ item }">
							{{ item.car != null ? item.car.car_manufacturer : '' }}
						</template>
						<template v-slot:item.car_model="{ item }">
							{{ item.car != null ? item.car.car_model : '' }}
						</template>
						<template v-slot:item.production_year="{ item }">
							{{ item.car != null ? item.car.production_year : '' }}
						</template>
						<template v-slot:item.note="{ item }">
							<trip-note
								:status="item.status"
								:id="item.id"
								:title=" `${item.renter.first_name} ${item.renter.last_name} @ (${item.pickup_location})`"
							>
								<v-btn 
									icon id="show-btn" 
									@click.stop="fetchNotes(item.id); $bvModal.show(item.id)"
								>
									<v-icon :key="item.id">content_copy</v-icon>Note
								</v-btn>
							</trip-note>
							<div
								v-if="item.note != null && Object.entries(item.note).length != 0"
								class="text-xs-left">
								<div class="badge badge-success" :title="item.note.comment">Has a comment</div>
							</div>
						</template>
						<template v-slot:expanded-item="{ headers, item }">
							<td :colspan="headers.length">
								<selected-trip :tripId="item.id"></selected-trip>
							</td>
						</template>

						<!-- <template slot="items" slot-scope="props">
							<tr class="tr-click" @click="props.expanded = !props.expanded">
								<td>{{ props.item.id }}</td>
								<td class="text-xs-left">{{ props.item.start_date }}</td>
								<td class="text-xs-left">{{ props.item.end_date }}</td>
								<td class="text-xs-left">{{ props.item.pickup_location }}</td>
								<td class="text-xs-left">{{ props.item.notice_time }}</td>
								<td class="text-xs-left">{{ props.item.renter_id }}</td>
								<td class="text-xs-left">{{ props.item.renter.first_name }} {{ props.item.renter.last_name }}</td>
								<td class="text-xs-left">{{ props.item.owner_id }}</td>
								<td class="text-xs-left">{{ props.item.owner.first_name }} {{ props.item.owner.last_name }}</td>
								<td class="text-xs-left">{{ props.item.status }}</td>
								<td>
									<trip-note
										:status="props.item.status"
										:id="props.item.id"
										:title=" `${props.item.renter.first_name} ${props.item.renter.last_name} @ (${props.item.pickup_location})`"
									>
										<v-btn 
											icon id="show-btn" 
											@click.stop="fetchNotes(props.item.id); $bvModal.show(props.item.id)"
										>
											<v-icon :key="props.item.id">content_copy</v-icon>Note
										</v-btn>
									</trip-note>
								</td>
							</tr>
						</template> -->
						<!-- expanding component -->
						<template slot="expand" slot-scope="props">
							<selected-trip :tripId="props.item.id"></selected-trip>
						</template>
						<v-alert slot="no-results" :value="true" color="error" icon="warning">
							Your search for "{{ search }}" found no results.
						</v-alert>
					</v-data-table>
                     <v-progress-linear v-if="loadingStatus()" :indeterminate="true"></v-progress-linear>
					 <paginate store="dialogTrips" collection="meta"/>
              </v-flex>
            </v-layout>
          </v-container>
                   
    	<b-button class="mt-3" block @click="$bvModal.hide(id)">Close</b-button>
    </b-modal>
  </div>
</template>

<script>
import paginate from '../../paginate'
import vueBtn from '../../vueBtnDownload'
import tripNote from '../dialogs/notes/tripNote'
export default {
    props: ['model', 'status', 'type', 'duration', 'id', 'displayText'],
    components: {
		paginate,
		vueBtn,
		tripNote
	},
    data () {
        return {
			expanded: [],
        	singleExpand: true,
			filename: "Trip",
            dialog: false,
            // loading: '',
            search: '',
            dialogDelete: false,
            dialogRestore: false,
			selectedItem: null,
			numOfunverifiedUsers: [],
			pagination: {
				sortBy: 'id',
				rowsPerPage: 10
			},
			headers: [
				{
					text: 'TRIPS ID',
					align: 'left',
					sortable: true,
					value: 'id'
				},
				{ text: 'Booking Date', value: 'created_at' },
					{ text: 'Start date', value: 'start_date' },
					{ text: 'End date', value: 'end_date' },
					{ text: 'Status', value: 'status' },
					
					{ text: 'Trip Days', value: 'trip_days' },
					{ text: 'Price Per Day', value: 'price_per_day' },
					{ text: 'Promo Discount', value: 'promo_discount' },
					{ text: 'Discount', value: 'discount' },
					{ text: 'Total Trip Price', value: 'tot_trip_price' },
					{ text: 'Trans Ref', value: 'trans_ref' },
					{ text: 'Esar Commision', value: 'esar_commission' },

					{ text: 'Pickup location', value: 'pickup_location' },
					{ text: 'Notice time', value: 'notice_time' },
					{ text: 'Renter ID', value: 'renter_id' },
					{ text: 'Renter full name', value: 'renter' },
					{ text: 'Renter Phone', value: 'renter_phone' },
					{ text: 'Owner ID', value: 'owner_id' },
					{ text: 'Owner full name', value: 'owner' },
					{ text: 'Owner Phone', value: 'owner_phone' },
					
					{ text: 'Owner Earning', value: 'owner_earnings' },
					{ text: 'Car ID', value: 'car_id' },
					{ text: 'Manufacturer', value: 'car_manufacturer' },
					{ text: 'Model', value: 'car_model' },
					{ text: 'Year', value: 'production_year' },
					
					
					{ text: 'Note', value: 'note' }
			],
			headerfileExport: {
				'id': 'id',
				'start_date': 'start_date',
				'end_date': 'end_date',
				'Trip Days': 'trip_bill.trip_days',
				'Promo Discount': 'trip_bill.promo_discount',
				'Discount': 'trip_bill.discount_amount',
				'Price Per Day': 'trip_bill.price_per_day',
				'Total Trip Price': 'trip_bill.trip_price',
				'Trans Ref': 'trip_bill.tran_ref',
				'Esar Commision': 'trip_bill.esar_earning',
				'pickup_location': 'pickup_location',
				'notice_time': 'notice_time',
				'renter_id': 'renter_id',
				'renter_first_name': 'renter.first_name',
				'renter_last_name': 'renter.last_name',
				'owner_id': 'owner_id',
				'owner_first_name': 'owner.first_name',
				'owner_last_name': 'owner.last_name',
				'Owner Earning': 'trip_bill.owner_earning',
				'Car ID': 'car.car_id',
				'Manufacturer': 'car.car_manufacturer',
				'Model': 'car.car_model',
				'Year': 'car.production_year',
				'status': 'status'
			},
			headerfileExportPDF: [
				{ header: 'ID', dataKey: 'id' },
				{ header: 'Start Date', dataKey: 'start_date' },
				{ header: 'End Date', dataKey: 'end_date' },
				{ header: 'Trip Days', dataKey: 'trip_bill.trip_days'},
				{ header: 'Promo Discount', dataKey: 'trip_bill.promo_discount'},
				{ header: 'Discount', dataKey: 'trip_bill.discount_amount'},
				{ header: 'Total Trip Price', dataKey: 'trip_bill.trip_price'},
				{ header: 'Trans Ref', dataKey: 'trip_bill.tran_ref'},
				{ header: 'Esar Commision', dataKey: 'trip_bill.esar_earning'},
				{ header: 'Pickup Location', dataKey: 'pickup_location' },
				{ header: 'Notice Time', dataKey: 'notice_time' },
				{ header: 'Rental First Name', dataKey: 'renter.first_name' },
				{ header: 'Rental Last Name', dataKey: 'renter.last_name' },
				{ header: 'Owner First Name', dataKey: 'owner.first_name' },
				{ header: 'Owner Last Name', dataKey: 'owner.last_name' },
				{ header: 'Status', dataKey: 'status' },
				{ header: 'Owner Earning', dataKey: 'trip_bill.owner_earning' },
				{ header: 'Car ID', dataKey: 'car.car_id' },
				{ header: 'Manufacturer', dataKey: 'car.car_manufacturer' },
				{ header: 'Model', dataKey: 'car.car_model' },
				{ header: 'Year', dataKey: 'car.production_year' }
			]
        }
	},
	computed: {
		loading: {
			get () {
				return this.$store.getters['dialogTrips/getLoader']
			},
			set (value) {
				this.$store.getters['dialogTrips/getLoader']
			}
		},
		trips: {
			get () {
				return this.$store.state.dialogTrips.trips
			}
		},
		pages () {
			if (this.pagination.rowsPerPage == null ||
				this.pagination.totalItems == null
			) return 0
                return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
			}
		},
		watch: {
			loading (newVal, oldVal) {
				this.loading = this.loadingStatus()
			},
			search (newVal, oldVal) {
				let data = {'model': this.model, 'type': this.type, 'status': this.status, 'duration': this.duration}
				this.$store.dispatch('dialogTrips/setAllAction', data)
				this.$store.dispatch('dialogTrips/setCurrentUrl', '/report/getCountsRecords')
				this.$store.dispatch('dialogTrips/setSearchText', newVal)
				this.$store.dispatch('dialogTrips/getList', 1).then(response => {
					this.loading = this.$store.getters['dialogTrips/getLoader']
				})
			}
		},
		methods: {
			loadingStatus () {
				return this.$store.getters['dialogTrips/getLoader']
			},
			handleChange (dates) {
				this.dates.splice(0, this.dates.length)
				for (let i = 0; i < dates.length; i++) {
					this.dates.push(dates[i].format("YYYY-MM-DD"))
				}
			},
			fetchNotes (id) {
				let data = {'url': '/get_note_trip', 'request_id': id}
				this.$store.dispatch('note/setAllAction', data)
				this.$store.dispatch('note/getList')
			}
		}

}
</script>

<style>
.theme--light.v-pagination .v-pagination__item--active{
  color:gray;
}
.theme--light.v-pagination .v-pagination__navigation {
    background: #fff;
    color:black;
}
</style>