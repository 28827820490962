<template>
  <div>
	<slot></slot>
    <b-modal :id="id" hide-footer size="xl" no-close-on-backdrop :title="displayText + ' Users'">
		
		<v-container grid-list-md>
            <v-layout wrap>
			<!-- <v-flex xs12 md4 lg4 sm>
				<v-text-field
					v-model="search"
					append-icon="search"
					label="Search"
					single-line
					hide-details
				>
				</v-text-field>
        	</v-flex> -->
		
			<v-spacer></v-spacer>
			<vueBtn 
				:json_data = "users"
				:param="{
					model: model,
					search: search,
					type: type,
					status: status,
					duration:duration,
					url: '/report/getCountsRecordsExport',
					filename: duration + '_Users'
				}"
				:fields="headerfileExport"
				:fieldsPDF="headerfileExportPDF"
			/>
			<template #modal-title>
				{{duration}} Users
			</template>
        	<v-flex xs12 sm12 md12>
                <v-data-table
					:headers="headers"
					:items="users"
      				class="elevation-0"
					:loading="loadingStatus()"
					:option="pagination"
					:hide-default-footer=false
					:hideActions=true
					:footer-props="{ disablePagination : false, disableItemsPerPage : false }"
					:sort-desc="[false, true]"
					:single-expand="singleExpand"
					:expanded.sync="expanded"
					show-expand
				>
				<!-- <template slot="items" slot-scope="props">
					<tr class="tr-click">
						<td @click="props.expanded = !props.expanded">{{ props.item.id }}</td>
						<td @click="props.expanded = !props.expanded" class="text-xs-left">{{ props.item.first_name }}</td>
						<td @click="props.expanded = !props.expanded" class="text-xs-left">{{ props.item.last_name }}</td>
						<td @click="props.expanded = !props.expanded" class="text-xs-left">{{ props.item.email }}</td>
					    <td @click="props.expanded = !props.expanded" class="text-xs-left">{{ props.item.cars_count }}</td>
						<td @click="props.expanded = !props.expanded" class="text-xs-left">{{ props.item.joined }}</td>
						<td @click="props.expanded = !props.expanded" class="text-xs-left">{{ props.item.phone_number }}</td>
						<td @click="props.expanded = !props.expanded" class="text-xs-left">{{ props.item.car_trips }}</td>
						<td @click="props.expanded = !props.expanded" class="text-xs-left">{{ props.item.active ? "Yes" : "No" }}</td>
					    <td
							v-if="props.item.active === 'Yes'"
							class="text-xs-left">
							<v-btn
								@click="showDeleteDialog(props.item.id)"
								color="error">
								DELETE
							</v-btn>
						</td>
						<td
						    v-else
							class="text-xs-left">
							<v-btn class="badge badge-success"
								@click="showRestoreDialog(props.item.id)"
								color="success">
								RESTORE
							</v-btn>
						</td>
						<td @click="props.expanded = !props.expanded" class="text-xs-left">

							<v-icon v-if="props.item.id_verified === 1 && props.item.approved_to_drive === 1 " color="green">check_circle</v-icon>
							<v-icon v-else color="red">highlight_off</v-icon>

						</td>
						<td>
							<user-note
								:status="props.item.active"
								:id="props.item.id"
								:title="`${props.item.first_name} ${props.item.last_name} (${props.item.email})`"
							>
								<v-btn 
									icon id="show-btn" 
									@click.stop="fetchNotes(props.item.id); $bvModal.show(props.item.id)"
								>
									<v-icon :key="props.item.id">content_copy</v-icon>Note
								</v-btn>
							</user-note>
						</td>
					</tr>
				</template>
			    <template slot="expand" slot-scope="props">
					<selected-user :userId="props.item.id"></selected-user>
				</template>
				<v-alert slot="no-results" :value="true" color="error" icon="warning">
					Your search for "{{ search }}" found no results.
				</v-alert> -->
						<template v-slot:expanded-item="{ headers, item }">
							<td :colspan="headers.length">
								<selected-user :userId="item.id"></selected-user>
							</td>
						</template>
						<template v-slot:top>
							<v-text-field
							v-model="search"
							label="Search (start typing...)"
							class="mx-4"
							></v-text-field>
						</template>

						<template v-slot:item.deleterestore="{ item }">
							<div
								v-if="item.active === 1"
								class="text-xs-left">
								<!-- <v-icon
									@click="showDeleteDialog(item.id)"
									color="error"
								>
									mdi-delete
								</v-icon> -->
								<v-icon @click="showMsgDelete(item.id)">
									mdi-delete
								</v-icon>
							</div>
							<div
								v-else
								class="text-xs-left">
								<!-- <v-icon
									class="badge badge-success"
									@click="showRestoreDialog(item.id)"
									color="success"
								>
									mdi-pencil
								</v-icon> -->
								<v-icon color="success" @click="showMsgRestore(item.id)">
									mdi-table-edit
								</v-icon>
							</div>
						</template>

						<template v-slot:item.note="{ item }">
							<user-note
								:status="item.active"
								:id="item.id"
								:title="`${item.first_name} ${item.last_name} (${item.email})`"
							>
								<v-btn 
									icon id="show-btn" 
									@click.stop="fetchNotes(item.id); $bvModal.show(item.id)"
								>
									<v-icon :key="item.id">content_copy</v-icon>Note
								</v-btn>
							</user-note>
						</template>

						<template v-slot:item.verified="{ item }">
							<v-icon v-if="item.id_verified === 1 && item.approved_to_drive === 1 " color="green">check_circle</v-icon>
							<v-icon v-else color="red">highlight_off</v-icon>
						</template>
				
				</v-data-table>
				<paginate store="dialogUsers" collection="meta"/>
                <v-progress-linear v-if="loadingStatus()" :indeterminate="true"></v-progress-linear>
				
            </v-flex>
			<!-- delete dialog -->
				<v-dialog v-model="dialogDelete" style="z-index:20001;" v-if="selectedItem" persistent max-width="290">
					<v-card>
						<v-card-title class="headline">Are you sure?</v-card-title>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="info" @click.native="dialogDelete = false; deleteUser(selectedItem)">DELETE</v-btn>
							<v-btn color="error" @click.native="dialogDelete = false">CANCEL</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
				<!-- end of delete dialog-->

				<!-- restore dialog -->
				<v-dialog v-model="dialogRestore" v-if="selectedItem" persistent max-width="290">
					<v-card>
						<v-card-title class="headline">Are you sure?</v-card-title>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="info" @click.native="dialogRestore = false; restoreUser(selectedItem)">RESTORE</v-btn>
							<v-btn color="error" @click.native="dialogRestore = false">CANCEL</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
				<!-- end of restore dialog-->
		</v-layout>
	</v-container>
            
    <v-btn class="mt-3" default block @click="$bvModal.hide(id)">Close Me</v-btn>
	
    </b-modal>
  </div>
</template>

<script>
import paginate from '../../paginate'
import vueBtn from '../../vueBtnDownload'
import UserNote from '../dialogs/notes/userNote'
export default {
    props: ['model', 'status', 'type', 'duration', 'id', 'displayText'],
    components: {
		paginate,
		vueBtn,
		UserNote
	},
    data () {
        return {
			expanded: [],
        	singleExpand: true,
			dialog: false,
			allow: false,
            search: '',
            dialogDelete: false,
            dialogRestore: false,
			selectedItem: null,
			numOfunverifiedUsers: [],
			pagination: {
				sortBy: 'id',
				rowsPerPage: 10
			},
			headers: [
				{
					text: 'USER ID',
					align: 'left',
					sortable: true,
					value: 'id'
				},
				{ text: 'First name', value: 'first_name' },
				{ text: 'Last name', value: 'last_name' },
				{ text: 'Email', value: 'email' },
				{ text: 'Listed Cars', value: 'listed_cars' },
				{ text: 'Joined', value: 'joined' },
				{ text: 'Phone number', value: 'phone_number' },
				{ text: 'Car trips', value: 'car_trips' },
				{ text: 'Active', value: 'active' },
            	{ text: 'Delete/Restore', value: 'deleterestore' },
                { text: 'Verified', value: 'verified' },
				{ text: 'Note', value: 'note' }
			],
			headerfileExport: {
				'id': 'id',
				'first_name': 'first_name',
				'last_name': 'last_name',
				'email': 'email',
				'listed_cars': 'cars_count',
				'joined': 'joined',
				'phone_number': 'phone_number',
				'car_trips': 'car_trips',
				'active': 'active'
			},
			headerfileExportPDF: [
				{ header: 'ID', dataKey: 'id' },
				{ header: 'First Name', dataKey: 'first_name' },
				{ header: 'Last Name', dataKey: 'last_name' },
				{ header: 'Email', dataKey: 'email' },
				{ header: 'Car Count', dataKey: 'cars_count' },
				{ header: 'Joined', dataKey: 'joined' },
				{ header: 'Phone', dataKey: 'phone_number' },
				{ header: 'Car Trip', dataKey: 'car_trips' },
				{ header: 'Active', dataKey: 'active' }
			]
        }
	},
	computed: {
		loading: {
			get () {
				return this.$store.getters['dialogUsers/getLoader']
			},
			set (value) {
				this.$store.getters['dialogUsers/getLoader']
			}
		},
		users: {
			get () {
				return this.$store.state.dialogUsers.users
			}
		},
		pages () {
			if (this.pagination.rowsPerPage == null ||
				this.pagination.totalItems == null
			) return 0
                return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
			}
		},
		watch: {
			loading (newVal, oldVal) {
				this.loading = this.$store.getters['dialogUsers/getLoader']
			},
			search (newVal, oldVal) {
				console.log('from search')
				this.$store.dispatch('dialogUsers/UnsetUserAction')
				this.$store.dispatch('dialogUsers/UnsetAllAction')
				let data = {'model': this.model, 'type': this.type, 'status': this.status, 'duration': this.duration}
				this.$store.dispatch('dialogUsers/setAllAction', data)
				this.$store.dispatch('dialogUsers/setCurrentUrl', '/report/getCountsRecords')
				this.$store.dispatch('dialogUsers/setSearchText', newVal)
				this.$store.dispatch('dialogUsers/getList', 1).then(response => {
					this.loading = this.$store.getters['dialogUsers/getLoader']
				})
			}
		},
		methods: {
			showMsgRestore(data) {
				this.boxTwo = ''
				this.$bvModal.msgBoxConfirm('Please confirm that you want to restore everything.', {
				title: 'Please Confirm',
				size: 'sm',
				buttonSize: 'sm',
				okVariant: 'danger',
				okTitle: 'YES',
				cancelTitle: 'NO',
				footerClass: 'p-2',
				hideHeaderClose: false,
				centered: true
				})
				.then(value => {
					console.log(value)
					if(value){
						this.restoreUser(data)
					}else{
						alert('Cancelled successfully')
					}
				})
				.catch(err => {
					// An error occurred
				})
			},
			showMsgDelete(data) {
				this.boxTwo = ''
				this.$bvModal.msgBoxConfirm('Please confirm that you want to delete everything.', {
				title: 'Please Confirm',
				size: 'sm',
				buttonSize: 'sm',
				okVariant: 'danger',
				okTitle: 'YES',
				cancelTitle: 'NO',
				footerClass: 'p-2',
				hideHeaderClose: false,
				centered: true
				})
				.then(value => {
					console.log(value)
					if(value){
						this.deleteUser(data)
					}else{
						alert('Cancelled successfully')
					}
				})
				.catch(err => {
					// An error occurred
				})
			},
			closeDialog () {
				this.dialog = false
				this.allow = true
			},
			loadingStatus () {
				return this.$store.getters['dialogUsers/getLoader']
			},
            deleteUser (id) {
                this.loading = true
                this.axios.post('/users/delete/' + id, {},
				{
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("id_token")
                    }
                })
				.then(response => {
					this.loading = false
                    this.getUsers()
				})
				.catch(error => {
					console.log(error)
					this.loading = false
				})
			},
            restoreUser (id) {
                this.loading = true
                this.axios.post('/users/restore/' + id, {},
				{
					headers: {
						Authorization: "Bearer " + localStorage.getItem("id_token")
					}
				})
				.then(response => {
					this.loading = false
                    this.getUsers()
				})
				.catch(error => {
					console.log(error)
					this.loading = false
				})
            },

            showDeleteDialog (value) {
                this.dialogDelete = true
                this.selectedItem = value
            },

            showRestoreDialog (value) {
                this.dialogRestore = true
                this.selectedItem = value
			},
			handleChange (dates) {
				this.dates.splice(0, this.dates.length)
				for (let i = 0; i < dates.length; i++) {
					this.dates.push(dates[i].format("YYYY-MM-DD"))
				}
			},
			fetchNotes (id) {
				let data = {'url': '/get_note_user', 'request_id': id}
				this.$store.dispatch('note/setAllAction', data)
				this.$store.dispatch('note/getList')
			}
		},
		mounted () {
		}

}
</script>
<style>
.theme--light.v-pagination .v-pagination__item--active{
  color:gray!important;
}
.theme--light.v-pagination .v-pagination__navigation {
    background: #fff;
    color:black;
}
</style>