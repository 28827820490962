<template>
	<!-- spinner -->
	<v-container  v-if="loading">
		<div justify-center>
			<v-progress-circular :size="50" indeterminate color="primary"></v-progress-circular>
		</div>
	</v-container>

	<!-- data -->
	<div v-else>
		<!-- DATA SECTION -->
		<div>
			<!-- first column -->
			<v-row xs12 sm12 md12 lg12>
			<v-flex class="vd-text-container" xs12 sm12 md6 lg4>
				<v-card>
					<v-list-item v-for="(item, key) in carData.dataSection.firstColumn" :key="key" class="vd-list-item">
						<v-list-item-content>{{ item }}</v-list-item-content>
						<v-list-item-content class="align-end">{{ car[key] }}</v-list-item-content>
					</v-list-item>
				</v-card>
			</v-flex>
			<!-- second column -->
			<v-flex class="vd-text-container" xs12 sm12 md6 lg4>
				<v-card>
					<v-list-item v-for="(item, key) in carData.dataSection.secondColumn" :key="key" class="vd-list-item">
						<v-list-item-content>{{ item }}</v-list-item-content>
						<v-list-item-content class="align-end">{{ car[key] }}</v-list-item-content>
					</v-list-item>
				</v-card>
			</v-flex>
			<!-- third column -->
			<v-flex class="vd-text-container" xs12 sm12 md6 lg4>
				<v-card>
					<v-list-item v-for="(item, key) in carData.dataSection.thirdColumn" :key="key" class="vd-list-item">
						<v-list-item-content>{{ item.text }} </v-list-item-content>
						<v-list-item-content v-if="item.booleanProp" class="align-end">
							<v-icon v-if="car[key]" color="green">check_circle</v-icon>
							<v-icon v-else color="red">highlight_off</v-icon>
						</v-list-item-content>
						<v-list-item-content v-else class="align-end">{{ car[key] }}</v-list-item-content>
					</v-list-item>
				</v-card>
			</v-flex>
			</v-row>
		</div>
		<!-- PHOTOS SECTION -->
		<div>
			<v-expansion-panels>
				<v-expansion-panel class="vd-photos-expansion">
					<v-expansion-panel-header>
						<h3>{{ `Photos: ${car.images.length}`}}</h3>
					</v-expansion-panel-header>
					<v-expansion-panel-content>
						<!-- <div slot="header">
							<h3>{{ `Photos: ${car.images.length}`}}</h3>
						</div> -->
						<v-divider></v-divider>
						<v-card>
							<div fluid grid-list-md>
								<div row wrap>
									<v-flex
										v-for="(image, index) in car.images"
										lg4 md6 xs12 sm6
										:key="index"
										>
										<v-card>
											<v-img
												v-if="image.small_image_path"
												class="elevation-10"
												:src="$imageURL + image.small_image_path"
												height="200px"
												:srcset="$imageURL + image.small_image_path"
												>
											</v-img>
										</v-card>
									</v-flex>
								</div>
							</div>
						</v-card>
					</v-expansion-panel-content>
				</v-expansion-panel>
			<!-- CAR INSURANCE SECTION -->
				<v-expansion-panel class="vd-photos-expansion">
					<v-expansion-panel-header @click="$event ? getCarInsurance() : false">
						<h3>Car insurance</h3>
					</v-expansion-panel-header>
					<v-expansion-panel-content @input="$event ? getCarInsurance() : false">
						<!-- <div slot="header">
							<h3>Car insurance</h3>
						</div> -->
						<v-divider></v-divider>
						<v-card>
							<!-- spinner -->
							<v-container  v-if="loadingIns">
								<div justify-center>
									<v-progress-circular :size="50" indeterminate color="primary"></v-progress-circular>
								</div>
							</v-container>
							<!-- Data -->
							<div v-else-if="!loadingIns && carInsurance" fluid grid-list-md>
								<!-- first row -->
								<div >
									<v-row>
									<!-- first column -->
									<v-flex v-bind="{ [`xs12`]: true }">
										<!-- insurance image -->
										<v-card>
											<v-img
												v-if="carInsurance['small_image_policy_card']"
												:src="$imageURL + carInsurance['small_image_policy_card']"
												class="elevation-10"
												height="400px"
												:srcset="$imageURL + carInsurance['small_image_policy_card']"
												>
											</v-img>
										</v-card>
									</v-flex>
									<!-- second column -->
									<v-flex class="vd-text-container" xs12 sm12 md12 lg12>
										<!-- insurance data -->
										<v-card>
											<v-list-item v-for="(item, key) in carData.carInsurance" :key="key" class="vd-list-item">
												<v-list-item-content>{{ item }}</v-list-item-content>
												<v-list-item-content class="align-end">{{ carInsurance[key] }}</v-list-item-content>
											</v-list-item>
										</v-card>
									</v-flex>
									</v-row>
								</div>
								<v-divider></v-divider>
								<!-- second row -->
								<div>
									<v-row>
									<v-flex>
										<v-card>
											<!-- @click="showDeleteDialog(props.item.id)" -->
											<v-btn
												@click="dialogIns = true"
												v-if="car.verified_insurance"
												class="text-xs-left"
												color="error">
												DENY
											</v-btn>
												<!-- @click="showRestoreDialog(props.item.id)" -->
											<v-btn
												@click="dialogIns = true"
												v-else
												class="text-xs-left"
												color="success">
												VERIFY
											</v-btn>
										</v-card>
									</v-flex>
									</v-row>
								</div>
							</div>
						</v-card>
					</v-expansion-panel-content>
				</v-expansion-panel>
			<!-- CAR REGISTRATION SECTION -->
				<v-expansion-panel class="vd-photos-expansion">
					<v-expansion-panel-header @click="$event ? getCarRegistration() : false">
						<h3>Car registration</h3>
					</v-expansion-panel-header>
					<v-expansion-panel-content @input="$event ? getCarRegistration() : false">
						<!-- <div slot="header">
							<h3>Car registration</h3>
						</div> -->
						<v-divider></v-divider>
						<v-card>
							<!-- spinner -->
							<v-container  v-if="loadingReg">
								<div justify-center>
									<v-progress-circular :size="50" indeterminate color="primary"></v-progress-circular>
								</div>
							</v-container>
							<!-- Data -->
							<div v-else-if="!loadingReg && carRegistration" fluid grid-list-md>
								<!-- First row -->
								<div>
									<v-row>
									<!-- First columnd -->
									<v-flex lg4 md6 xs12 sm6>
										<!-- registration image -->
										<v-card>
											<v-img
												:src="$imageURL + carRegistration['small_car_registration_image']"
												class="elevation-10"
												height="400px"
												>
											</v-img>
										</v-card>
									</v-flex>
									<!-- Second columnd -->
									<v-flex class="vd-text-container" xs12 sm12 md6 lg6>
										<!-- registration data -->
										<v-card>
											<v-list-item v-for="(item, key) in carData.carRegistration" :key="key" class="vd-list-item">
												<v-list-item-content>{{ item }}</v-list-item-content>
												<v-list-item-content class="align-end">{{ carRegistration[key] }}</v-list-item-content>
											</v-list-item>
										</v-card>
									</v-flex>
									</v-row>
								</div>
								<v-divider></v-divider>
								<!-- second row -->
								<div>
									<v-row>
									<v-flex>
										<v-card>
											<!-- @click="showDeleteDialog(props.item.id)" -->
											<v-btn
												@click="dialogReg = true"
												v-if="car.verified_registration"
												class="text-xs-left"
												dark
												color="red">
												DENY
											</v-btn>
												<!-- @click="showRestoreDialog(props.item.id)" -->
											<v-btn
												@click="dialogReg = true"
												v-else
												dark
												class="text-xs-left"
												color="green">
												VERIFY
											</v-btn>
										</v-card>
									</v-flex>
									</v-row>
								</div>
							</div>
						</v-card>
					</v-expansion-panel-content>
				</v-expansion-panel>
			<!-- OWNER SECTION -->
				<v-expansion-panel class="vd-photos-expansion">
					<v-expansion-panel-header @click="delay()">
						<div>
							<img :src="country ? country.flag : ''" :alt="car.owner.country_code" class="elevation-4" height="30px">
							<span style="marginLeft:20px; fontSize:16px; fontWeight:700" color="primary">
								Owner
							</span>
							<span style="fontSize:16px">{{ !ownerExpand ? `: ${car.owner.first_name} ${car.owner.last_name} #${car.owner.id}` : ''}}</span>
								
						</div>
					</v-expansion-panel-header>
					<v-expansion-panel-content @input="delay()">
						<!-- <div slot="header" >
							<div>
								<img :src="country ? country.flag : ''" :alt="car.owner.country_code" class="elevation-4" height="30px">
								<span style="marginLeft:20px; fontSize:16px; fontWeight:700" color="primary">
									Owner
								</span>
								<span style="fontSize:16px">{{ !ownerExpand ? `: ${car.owner.first_name} ${car.owner.last_name} #${car.owner.id}` : ''}}</span>
								
							</div>
						</div> -->
						<v-divider></v-divider>
						<!-- data -->
						<div>
							<v-row>
							<!-- first column -->
							<v-flex class="vd-text-container" xs12 sm12 md6 lg6 style="borderRight:1px solid #dadada">
								<v-card>
									<v-list-item v-for="(item, key) in carData.ownerSection.firstColumn" :key="key" class="vd-list-item">
										<v-list-item-content>{{ item }}</v-list-item-content>
										<v-list-item-content class="align-end">{{ car.owner[key] }}</v-list-item-content>
									</v-list-item>
								</v-card>
							</v-flex>
							<!-- second column -->
							<v-flex class="vd-text-container" xs12 sm12 md6 lg6>
								<v-card>
									<v-list-item v-for="(item, key) in carData.ownerSection.secondColumn" :key="key" class="vd-list-item">
										<v-list-item-content>{{ item.text }} </v-list-item-content>
										<v-list-item-content v-if="item.booleanProp" class="align-end">
											<v-icon v-if="car.owner[key]" color="green">check_circle</v-icon>
											<v-icon v-else color="red">highlight_off</v-icon>
										</v-list-item-content>
										<v-list-item-content v-else class="align-end">{{ car.owner[key] }}</v-list-item-content>
									</v-list-item>
								</v-card>
							</v-flex>
							</v-row>
						</div>
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>
		</div>
		<!-- DIALOG -->
		<div row justify-center>
			<!-- REGISTRATION -->
			<v-dialog v-model="dialogReg" persistent max-width="490" height="300">
				<v-card>
					<v-card-title class="headline">Car registration status</v-card-title>
					<v-card-text>{{ `Are you sure you want to change registration verification status of ${car.owner.first_name}'s ${car.car_manufacturer} ${car.car_model}?`}}</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<!-- if car registration allready approved -->
						<v-btn v-if="car.verified_registration" color="success" flat @click.native="dialogFun('registration', 'deny')">Yes</v-btn>
						<!--  else if car registration not approved -->
						<v-btn v-else color="success" flat @click.native="dialogFun('registration', 'approve')">Yes</v-btn>
						<v-btn color="error" flat @click.native="dialogReg = false">No</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
			<!-- INSURANCE -->
			<v-dialog v-model="dialogIns" persistent max-width="490" height="300">
				<v-card>
					<v-card-title class="headline">Car insurance status</v-card-title>
					<v-card-text>{{ `Are you sure you want to change insurance verification status of ${car.owner.first_name}'s ${car.car_manufacturer} ${car.car_model}?`}}</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<!-- if car insurance allready approved -->
						<v-btn v-if="car.verified_insurance" color="success" flat @click.native="dialogFun('insurance', 'deny')">Yes</v-btn>
						<!--  else if car insurance not approved -->
						<v-btn v-else color="success" flat @click.native="dialogFun('insurance', 'approve')">Yes</v-btn>
						<v-btn color="error" flat @click.native="dialogIns = false">No</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</div>

	</div>
</template>

<script>
export default {
	props: {
		carId: {
			type: String
		}
	},
	data () {
		return {
			car: '',
			carInsurance: '',
			carRegistration: '',
			loading: false,
			loadingReg: false,
			loadingIns: false,
			ownerExpand: false,
			dialogReg: false,
			dialogIns: false,
			country: '',
			carData: {
				dataSection: {
					firstColumn: {
						style: 'Car style',
						type: 'Car type',
						odometer: 'Odometer',
						created_at: 'Created at',
						updated_at: 'Updated at',
						deposit: 'Deposit',
						value: 'Value',
						stars: 'Star'
					},
					secondColumn: {
						shortest_trip: 'Shortest trip',
						longest_trip: 'Longest trip',
						notice: 'Car notice',
						guest_location_notice: 'Guest location notice',
						car_location_notice: 'Car location notice',
						airport_notice: 'Airport notice',
						key_handoff: 'Key handoff',
						parking_details: 'Parking details'
					},
					thirdColumn: {
						active: {
							text: 'Car active',
							booleanProp: true
						},
						verified_insurance: {
							text: 'Verified insurance',
							booleanProp: true
						},
						verified_registration: {
							text: 'Verified registration',
							booleanProp: true
						},
						weekend_trip: {
							text: 'Weekend trip',
							booleanProp: true
						},
						long_term_trip: {
							text: 'Long term trip',
							booleanProp: true
						},
						paid_advertising: {
							text: 'Paid advertaising',
							booleanProp: true
						},
						reviews: {
							text: 'Reviews',
							booleanProp: false
						}
					}
				},
				ownerSection: {
					firstColumn: {
						first_name: 'First name',
						last_name: 'Last name',
						id: 'ID',
						email: 'E-mail',
						phone_number: 'Phone number',
						created_at: 'Created at',
						updated_at: 'Updated at',
						work_from_time: 'Work from time',
						work_until_time: 'Work until time',
						country_code: 'Country code',
						account_number: 'Account number',
						holder_name: 'Holder name',
						iban: 'IBAN',
						trips: 'Trips',
						listed: 'Listed'
					},
					secondColumn: {
						user_active_status: {
							text: 'User active',
							booleanProp: true
						},
						approved_to_drive: {
							text: 'Approved to drive',
							booleanProp: true
						},
						email_verified: {
							text: 'E-mail verified',
							booleanProp: true
						},
						id_verified: {
							text: 'ID verified',
							booleanProp: true
						},
						phone_verified: {
							text: 'Phone verified',
							booleanProp: true
						},
						reviewed: {
							text: 'Reviewed',
							booleanProp: true
						},
						sms_notifications: {
							text: 'SMS notifications',
							booleanProp: true
						},
						car_trips: {
							text: 'Car trips',
							booleanProp: false
						},
						count_stars: {
							text: 'Count stars',
							booleanProp: false
						},
						user_stars: {
							text: 'User stars',
							booleanProp: false
						},
						count_penalty_owner: {
							text: 'Penalties as owner',
							booleanProp: false
						},
						count_penalty_renter: {
							text: 'Penalties as renter',
							booleanProp: false
						},
						penalty_amount: {
							text: 'Penalty amount',
							booleanProp: false
						},
						penalty_period: {
							text: 'Penalty period',
							booleanProp: false
						}
					}
				},
				carInsurance: {
					car_id: 'Car ID',
					created_at: 'Created at',
					date_of_issue: 'Date of issue',
					detectable_amount: 'Detectable amount',
					expiration_date: 'Expiration date',
					// expired: 'Expired',
					id: 'ID',
					policy_number: 'Policy number',
					updated_at: 'Updated at'
				},
				carRegistration: {
					car_id: 'Car ID',
					city: 'City',
					state: 'State',
					country: 'Country',
					created_at: 'Created at',
					date_of_issue: 'Date of issue',
					expiration_date: 'Expiration date',
					// expired: 'Expired',
					id: 'ID',
					licence_plate: 'Licence plate',
					updated_at: 'Updated at'
				}
			}
		}
	},

	methods: {
		dialogFun (call, state) {
			if (call === 'registration') {
				this.verifyCarThings('registration', state)
				this.dialogReg = false
			} else if (call === 'insurance') {
				this.verifyCarThings('insurance', state)
				this.dialogIns = false
			}
			this.getCar()
		},

		getCar () {
			this.loading = true
			this.axios.get('/car/' + this.carId, {
				headers: {
					Authorization: "Bearer " + localStorage.getItem("id_token")
				}
			})
			.then(response => {
				this.car = response.data
				// console.log(response.data)
				this.loading = false
				this.getCountry(this.car.owner.country_code)
			})
			.catch(error => {
				console.log(error)
				this.loading = false
			})
		},

		getCarInsurance () {
			this.loadingIns = true
			this.axios.get('/car/insurance/' + this.carId, {
				headers: {
					Authorization: "Bearer " + localStorage.getItem("id_token")
				}
			})
			.then(response => {
				this.carInsurance = response.data
				// console.log(response.data)
				this.loadingIns = false
			})
			.catch(error => {
				console.log(error)
				this.loadingIns = false
			})
		},

		getCarRegistration () {
			this.loadingReg = true
			this.axios.get('/car/registration/' + this.carId, {
				headers: {
					Authorization: "Bearer " + localStorage.getItem("id_token")
				}
			})
			.then(response => {
				this.carRegistration = response.data
				// console.log(response.data)
				this.loadingReg = false
			})
			.catch(error => {
				console.log(error)
				this.loadingReg = false
			})
		},

		verifyCarThings (call = 'registration', state = 'approve') {
			this.loadingReg = true
			this.axios.post(`/car/${call}/${state}/${this.carId}`, {}, {
				headers: {
					Authorization: "Bearer " + localStorage.getItem("id_token")
				}
			})
			.then(response => {
				this.loadingReg = false
				this.getCar()
			})
			.catch(error => {
				console.log(error)
				this.loadingReg = false
			})
		},

		getCountry (countryCode) {
			fetch('https://restcountries.eu/rest/v2/callingcode/' + countryCode)
				.then(response => {
					return response.json()
				})
				.then(data => {
					this.country = data[0]
				})
				.catch(error => {
					console.log(error)
				})
		},

		delay () {
			if (!this.ownerExpand) {
				setTimeout(() => {
					this.ownerExpand = !this.ownerExpand
				}, 120)
			} else {
				setTimeout(() => {
					this.ownerExpand = !this.ownerExpand
				}, 400)
			}
		}
	},

	created () {
		this.getCar()
	}
}
</script>

<style>
	.vd-text-container p {
		margin-bottom: 6px;
	}

	.vd-text-container {
		margin: 4px;
	}

	/* .vd-list-item { */
		/* border-bottom: 1px solid rgba(128, 128, 128, 0.205); */

	/* } */

	.vd-data-container {
		background: rgba(128, 128, 128, 0.207);
	}

	.vd-photos-expansion {
		margin: 4px;
	}
</style>

