import Axios from 'axios'
const state = {
    data: [],
    edit_data: [],
    loading: true,
    error: [],
    dialog: '',
    url: '',
    request_id: '',
    formData: {}
}

const actions = {
    getList ({commit}) {
        commit('setLoading')
        Axios.get(state.url + '/' + state.request_id, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("id_token")
            }
        }).then(response => {
            // console.log(response)
            commit('setData', response.data.data)
            commit('UnsetLoading')
        }).catch(error => {
            commit('UnsetLoading')
            state.error = error
        })
    },
    addNote ({commit}) {
        commit('setLoading')
        Axios.post(state.url, state.formData, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("id_token")
            }
        }).then(response => {
            commit('setEditData', response.data.data)
            commit('UnsetLoading')
        }).catch(error => {
            commit('UnsetLoading')
            state.error = error
        })
    },
    editNote ({commit}) {
        commit('setLoading')
        Axios.post(state.url + '/' + state.request_id, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("id_token")
            }
        }).then(response => {
            commit('setEditData', response.data)
            commit('UnsetLoading')
        }).catch(error => {
            commit('UnsetLoading')
            state.error = error
        })
    },
    showNote ({commit}) {
        commit('setLoading')
        Axios.get(state.url + '?request_id=' + state.request_id, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("id_token")
            }
        }).then(response => {
            commit('setEditData', response.data)
            commit('UnsetLoading')
        }).catch(error => {
            commit('UnsetLoading')
            state.error = error
        })
    },
    UnsetLoader ({commit}) {
        commit('UnsetLoading')
    },
    setLoader ({commit}) {
        commit('setLoading')
    },
    setCurrentUrl ({commit}, url) {
        commit('setUrl', url)
    },
    DiagSet ({commit}) {
        commit('setDialog')
    },
    SetRequest_id ({commit}, payload) {
        commit('setRequest', payload)
    },
    SetFormData ({commit}, payload) {
        commit('setFormData', payload)
    },
    setAllAction ({ commit }, data) {
        console.log(data)
        commit('setUrl', data.url)
        commit('setRequest', data.request_id)
    }
}
const mutations = {
    setData (state, payload) {
        state.data = payload
    },
    setFormData (state, payload) {
        state.formData = payload
    },
    setEditData (state, payload) {
        state.edit_data = payload
    },
    setLoading (state) {
        state.loading = true
    },
    UnsetLoading (state) {
        state.loading = false
    },
    setDialog (state) {
        state.dialog = !state.dialog
    },
    setUrl (state, payload) {
        state.url = payload
    },
    setRequest (state, payload) {
        state.request_id = payload
    }
}
const getters = {
        getData: (state) => state.data,
        getLoader: (state) => state.loading,
        getDialog: (state) => state.dialog,
        getUrl: (state) => state.url
}
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
