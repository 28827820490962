<template>
	<v-container fluid grid-list-md style="overflow: auto;">
		<v-slide-y-transition mode="out-in">
			<v-layout wrap>
				<!-- <h1>CARS</h1> -->
				 <v-card>
					<v-card-title>
						<v-flex xs12 sm6 md3>
						<v-btn @click="getUsers()" outlined type="div" color="blue-grey" :loading="loadingStatus()">
							USERS LISTING 
						</v-btn>
						</v-flex>
						<v-flex xs12 sm6 md4 color="blue-grey">
							<v-md-date-range-picker
								show-year-select
								max-year="2030"
    							min-year="2010"
								@change="handleChange"
							>
							</v-md-date-range-picker>
						</v-flex>
						
						<v-flex xs12 sm6 md5>
						<v-select
							:items="options"
							label="Choose..."
							single-line
							hide-details
							v-model="active"
						></v-select>
						</v-flex>

						<!-- <v-flex xs12 sm6 md4>
						<v-text-field
							v-model="search"
							append-icon="search"
							label="Search"
							single-line
							hide-details
							>
						</v-text-field>
						</v-flex> -->
					</v-card-title>
					
					
					<vueBtn 
						:json_data = "users"
						:param="{
							model: 'User',
							search: search,
							type: `dates`,
							status: null,
							duration:'Total',
							url: '/report/getCountsRecordsExport',
							filename: 'allusers' + '_Users',
							date_range: dates
						}"
						:fields="headerfileExport"
						:fieldsPDF="headerfileExportPDF"
					/>


					<v-card-text>
					<v-flex xs12 sm12 md12>
					<v-data-table
						:headers="headers"
						:items="users"
      					class="elevation-0"
						:loading="loadingStatus()"
						:hide-default-footer="false"
						:hideActions=true
						:footer-props="{ disablePagination : false, disableItemsPerPage : false }"
						:sort-by="['id', 'email']"
    					:sort-desc="[false, true]"
						:single-expand="singleExpand"
						:expanded.sync="expanded"
						show-expand
						>
						<template v-slot:expanded-item="{ headers, item }">
							<td :colspan="headers.length">
							<selected-user :userId="item.id"></selected-user>
							</td>
						</template>
						<template v-slot:top>
							<v-text-field
							v-model="search"
							label="Search (start typing...)"
							class="mx-4"
							></v-text-field>
						</template>

						<template v-slot:item.deleterestore="{ item }">
							<div
								v-if="item.active === 1"
								class="text-xs-left">
								<v-icon
									@click="showDeleteDialog(item.id)"
									color="error"
								>
									mdi-delete
								</v-icon>
							</div>
							<div
								v-else
								class="text-xs-left">
								<v-icon
									@click="showRestoreDialog(item.id)"
									color="success"
								>
									mdi-table-edit
								</v-icon>
							</div>
						</template>

						<template v-slot:item.note="{ item }">
							<user-note
								:status="item.active"
								:id="item.id"
								:title="`${item.first_name} ${item.last_name} (${item.email})`"
							>
								<v-btn 
									icon id="show-btn" 
									@click.stop="fetchNotes(item.id); $bvModal.show(item.id)"
								>
									<v-icon :key="item.id">content_copy</v-icon>Note
								</v-btn>
							</user-note>
						</template>

						<template v-slot:item.note_status="{ item }">
							<div
								v-if="item.note != null && Object.entries(item.note).length != 0"
								class="text-xs-left">
								<span class="badge badge-success" :title="item.note.comment">Has a comment</span >
							</div>
							<div
								v-else
								class="text-xs-left">
								<span  class="badge badge-success">No comment</span >
							</div>
						</template>

						<template v-slot:item.verified="{ item }">
							<v-icon v-if="item.id_verified === 1 && item.approved_to_drive === 1 " color="green">check_circle</v-icon>
							<v-icon v-else color="red">highlight_off</v-icon>
						</template>

					</v-data-table>
					</v-flex>
					<paginate store="users" collection="meta"/>
                     <v-progress-linear v-if="loadingStatus()" :indeterminate="true"></v-progress-linear>
					 </v-card-text>
				</v-card>

				<!-- delete dialog -->
				<v-dialog v-model="dialogDelete" v-if="selectedItem" persistent max-width="290">
					<v-card>
						<v-card-title class="headline">Are you sure?</v-card-title>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="info" @click.native="dialogDelete = false; deleteUser(selectedItem)">DELETE</v-btn>
							<v-btn color="error" @click.native="dialogDelete = false">CANCEL</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
				<!-- end of delete dialog-->

				<!-- restore dialog -->
				<v-dialog v-model="dialogRestore" v-if="selectedItem" persistent max-width="290">
					<v-card>
						<v-card-title class="headline">Are you sure?</v-card-title>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="info" @click.native="dialogRestore = false; restoreUser(selectedItem)">RESTORE</v-btn>
							<v-btn color="error" @click.native="dialogRestore = false">CANCEL</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
				<!-- end of restore dialog-->

			</v-layout>
		</v-slide-y-transition>
	</v-container>
</template>
<script>
	import paginate from './paginate'
	import UserNote from './popups/dialogs/notes/userNote.vue'
	import vueBtn from './vueBtnDownload'
	let today = new Date()
	today.setHours(0, 0, 0, 0)

	let yesterday = new Date()
	yesterday.setDate(today.getDate() - 1)
	yesterday.setHours(0, 0, 0, 0)
	export default {
		components: {
			paginate,
			vueBtn,
			UserNote
		},
		props: {
			on: false
		},
		data () {
			return {
				expanded: [],
        		singleExpand: false,
				options: [
					{
						text: 'All',
						value: ''
					},
					{
						text: 'Active',
						value: 1
					},
					{
						text: 'InActive',
						value: 0
					}
				],
				filename: "users",
				dates: [],
				search: '',
				active: '',
				loading: '',
                dialogDelete: false,
                dialogRestore: false,
				selectedItem: null,
				numOfunverifiedUsers: [],
				pagination: {
					sortBy: 'id',
					rowsPerPage: 10
				},
				headers: [
					{
						text: 'USER ID',
						align: 'left',
						sortable: true,
						value: 'id'
					},
					{ text: 'First name', value: 'first_name' },
					{ text: 'Last name', value: 'last_name' },
					{ text: 'Email', value: 'email' },
					{ text: 'Listed Cars', value: 'listed_cars' },
					{ text: 'Joined', value: 'joined' },
					{ text: 'Phone number', value: 'phone_number' },
					{ text: 'Car trips', value: 'car_trips' },
					{ text: 'Active', value: 'active' },
                    { text: 'Delete/Restore', value: 'deleterestore' },
					{ text: 'Verified', value: 'verified' },
					{ text: 'Note Status', value: 'note_status' },
					{ text: 'Note', value: 'note' }
				],
			headerfileExport: {
				'id': 'id',
				'first_name': 'first_name',
				'last_name': 'last_name',
				'email': 'email',
				'listed_cars': 'cars_count',
				'joined': 'joined',
				'phone_number': 'phone_number',
				'car_trips': 'car_trips',
				'active': 'active'
			},
			headerfileExportPDF: [
				{ header: 'ID', dataKey: 'id' },
				{ header: 'First Name', dataKey: 'first_name' },
				{ header: 'Last Name', dataKey: 'last_name' },
				{ header: 'Email', dataKey: 'email' },
				{ header: 'Car Count', dataKey: 'cars_count' },
				{ header: 'Joined', dataKey: 'joined' },
				{ header: 'Phone', dataKey: 'phone_number' },
				{ header: 'Car Trip', dataKey: 'car_trips' },
				{ header: 'Active', dataKey: 'active' }
			]

				// users: []
			}
		},
		computed: {
			users: {
				get () {
					return this.$store.state.users.users
				}
			},
			pages () {
				if (this.pagination.rowsPerPage == null ||
					this.pagination.totalItems == null
				) return 0

				return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
			}
		},
		watch: {
			loading (newVal, oldVal) {
				this.loading = this.loadingStatus()
			},
			search (newVal, oldVal) {
				this.$store.dispatch('users/setCurrentUrl', '/users')
				this.$store.dispatch('users/setSearchText', newVal)
				this.$store.dispatch('users/getList', 1).then(response => {
					this.loading = this.$store.getters['users/getLoader']
				})
			},
			active (newVal, oldVal) {
				this.$store.dispatch('users/setCurrentUrl', '/users')
				this.$store.dispatch('users/setActiveAction', newVal)
				this.$store.dispatch('users/getList', 1).then(response => {
					this.loading = this.$store.getters['users/getLoader']
				})
			},
			dates (newVal, oldVal) {
				this.$store.dispatch('users/setCurrentUrl', '/users')
				this.$store.dispatch('users/setDateRangeNow', newVal)
				this.$store.dispatch('users/getList', 1).then(response => {
					this.loading = this.$store.getters['users/getLoader']
				})
			}
		},
		created () {
			this.$store.dispatch('users/setCurrentUrl', '/users')
			this.$store.dispatch('users/getList', 1).then(response => {
				this.loading = this.$store.getters['users/getLoader']
			})
		},
		methods: {
			getUsers () {
				this.$store.dispatch('users/Unset_Date_range')
				this.$store.dispatch('users/UnsetActiveAction')
				this.$store.dispatch('users/setCurrentUrl', '/users')
				this.$store.dispatch('users/getList', 1).then(response => {
					this.loading = this.$store.getters['users/getLoader']
				})
				if (this.users.length > 0) {
					this.numOfunverifiedUsers = []
					this.users.forEach(elem => {
						if (elem.id_verified === 0 || elem.approved_to_drive === 0) {
							this.numOfunverifiedUsers.push(elem)
						}
					})
					this.loading = this.$store.getters['users/getLoader']
				} else {
					this.loading = this.$store.getters['users/getLoader']
				}
			},
			loadingStatus () {
				return this.$store.getters['users/getLoader']
			},
            deleteUser (id) {
                this.loading = true
                this.axios.post('/users/delete/' + id, {},
				{
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("id_token")
                    }
                })
				.then(response => {
					this.loading = false
                    this.getUsers()
				})
				.catch(error => {
					console.log(error)
					this.loading = false
				})
			},
            restoreUser (id) {
                this.loading = true
                this.axios.post('/users/restore/' + id, {},
				{
					headers: {
						Authorization: "Bearer " + localStorage.getItem("id_token")
					}
				})
				.then(response => {
					this.loading = false
                    this.getUsers()
				})
				.catch(error => {
					console.log(error)
					this.loading = false
				})
            },

            showDeleteDialog (value) {
                this.dialogDelete = true
                this.selectedItem = value
            },

            showRestoreDialog (value) {
                this.dialogRestore = true
                this.selectedItem = value
			},
			handleChange (dates) {
				this.dates.splice(0, this.dates.length)
				for (let i = 0; i < dates.length; i++) {
					this.dates.push(dates[i].format("YYYY-MM-DD"))
				}
			},
			exportableData () {
                this.loading = true
                this.axios.get('/UsersExport?' + '&search=' + this.search + '&dates=' + this.dates + '&active=' + this.active, {
					headers: {
						Authorization: "Bearer " + localStorage.getItem("id_token")
					}
                })
				.then(response => {
					console.log(response)
				})
				.catch(error => {
					console.log(error)
				})
			},
			fetchNotes (id) {
				let data = {'url': '/get_note_user', 'request_id': id}
				this.$store.dispatch('note/setAllAction', data)
				this.$store.dispatch('note/getList')
			}
		},
		mounted () {
			// console.log(this.$store.state.currentUser.user)
			this.getUsers()
		}
	}
</script>
