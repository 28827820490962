var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._t("default"),_c('b-modal',{attrs:{"id":_vm.id,"hide-footer":"","size":"xl","no-close-on-backdrop":"","title":_vm.displayText + ' Users'}},[_c('v-container',{attrs:{"grid-list-md":""}},[_c('v-layout',{attrs:{"wrap":""},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_vm._v(" "+_vm._s(_vm.duration)+" Users ")]},proxy:true}])},[_c('v-spacer'),_c('vueBtn',{attrs:{"json_data":_vm.users,"param":{
					model: _vm.model,
					search: _vm.search,
					type: _vm.type,
					status: _vm.status,
					duration:_vm.duration,
					url: '/report/getCountsRecordsExport',
					filename: _vm.duration + '_Users'
				},"fields":_vm.headerfileExport,"fieldsPDF":_vm.headerfileExportPDF}}),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":""}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.users,"loading":_vm.loadingStatus(),"option":_vm.pagination,"hide-default-footer":false,"hideActions":true,"footer-props":{ disablePagination : false, disableItemsPerPage : false },"sort-desc":[false, true],"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
				var headers = ref.headers;
				var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('selected-user',{attrs:{"userId":item.id}})],1)]}},{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Search (start typing...)"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.deleterestore",fn:function(ref){
				var item = ref.item;
return [(item.active === 1)?_c('div',{staticClass:"text-xs-left"},[_c('v-icon',{on:{"click":function($event){return _vm.showMsgDelete(item.id)}}},[_vm._v(" mdi-delete ")])],1):_c('div',{staticClass:"text-xs-left"},[_c('v-icon',{attrs:{"color":"success"},on:{"click":function($event){return _vm.showMsgRestore(item.id)}}},[_vm._v(" mdi-table-edit ")])],1)]}},{key:"item.note",fn:function(ref){
				var item = ref.item;
return [_c('user-note',{attrs:{"status":item.active,"id":item.id,"title":((item.first_name) + " " + (item.last_name) + " (" + (item.email) + ")")}},[_c('v-btn',{attrs:{"icon":"","id":"show-btn"},on:{"click":function($event){$event.stopPropagation();_vm.fetchNotes(item.id); _vm.$bvModal.show(item.id)}}},[_c('v-icon',{key:item.id},[_vm._v("content_copy")]),_vm._v("Note ")],1)],1)]}},{key:"item.verified",fn:function(ref){
				var item = ref.item;
return [(item.id_verified === 1 && item.approved_to_drive === 1 )?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("check_circle")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v("highlight_off")])]}}])}),_c('paginate',{attrs:{"store":"dialogUsers","collection":"meta"}}),(_vm.loadingStatus())?_c('v-progress-linear',{attrs:{"indeterminate":true}}):_vm._e()],1),(_vm.selectedItem)?_c('v-dialog',{staticStyle:{"z-index":"20001"},attrs:{"persistent":"","max-width":"290"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Are you sure?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"info"},nativeOn:{"click":function($event){_vm.dialogDelete = false; _vm.deleteUser(_vm.selectedItem)}}},[_vm._v("DELETE")]),_c('v-btn',{attrs:{"color":"error"},nativeOn:{"click":function($event){_vm.dialogDelete = false}}},[_vm._v("CANCEL")])],1)],1)],1):_vm._e(),(_vm.selectedItem)?_c('v-dialog',{attrs:{"persistent":"","max-width":"290"},model:{value:(_vm.dialogRestore),callback:function ($$v) {_vm.dialogRestore=$$v},expression:"dialogRestore"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Are you sure?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"info"},nativeOn:{"click":function($event){_vm.dialogRestore = false; _vm.restoreUser(_vm.selectedItem)}}},[_vm._v("RESTORE")]),_c('v-btn',{attrs:{"color":"error"},nativeOn:{"click":function($event){_vm.dialogRestore = false}}},[_vm._v("CANCEL")])],1)],1)],1):_vm._e()],1)],1),_c('v-btn',{staticClass:"mt-3",attrs:{"default":"","block":""},on:{"click":function($event){return _vm.$bvModal.hide(_vm.id)}}},[_vm._v("Close Me")])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }