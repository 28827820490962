<template>
	<!-- spinner -->
	<v-container  v-if="loading">
		<div justify-center>
			<v-progress-circular :size="50" indeterminate color="primary"></v-progress-circular>
		</div>
	</v-container>
	<!-- data -->
	<div fluid v-else class="vd-data-container" style="padding:20px;">
		<!-- first row -->
		<div>
			<!-- first column -->
			<v-flex class="vd-text-container" xs12 sm6 md6 lg6>
				<v-card>
					<v-list-item class="vd-list-item">
						<v-list-item-content>Accepted trips in row:</v-list-item-content>
						<v-list-item-content class="align-end">{{ user.accepted_trips_in_row }}</v-list-item-content>
					</v-list-item>
					<v-list-item class="vd-list-item">
						<v-list-item-content>Joined:</v-list-item-content>
						<v-list-item-content class="align-end">{{ user.joined }}</v-list-item-content>
					</v-list-item>
					<v-list-item class="vd-list-item">
						<v-list-item-content>Listed cars:</v-list-item-content>
						<v-list-item-content class="align-end">{{ user.cars_count }}</v-list-item-content>
					</v-list-item>
					<v-list-item class="vd-list-item">
						<v-list-item-content>Number of penalties:</v-list-item-content>
						<v-list-item-content class="align-end">{{ user.number_of_penalties }}</v-list-item-content>
					</v-list-item>
					<v-list-item class="vd-list-item">
						<v-list-item-content>Shortest trip:</v-list-item-content>
						<v-list-item-content class="align-end">{{ user.shortest_trip }}</v-list-item-content>
					</v-list-item>
					<v-list-item class="vd-list-item">
						<v-list-item-content>Penalty amount:</v-list-item-content>
						<v-list-item-content class="align-end">{{ user.penalty_amount }}</v-list-item-content>
					</v-list-item>
					<v-list-item class="vd-list-item">
						<v-list-item-content>Stars:</v-list-item-content>
						<v-list-item-content class="align-end">{{ user.stars}}</v-list-item-content>
					</v-list-item>
					<v-list-item class="vd-list-item">
						<v-list-item-content>Stars as owner:</v-list-item-content>
						<v-list-item-content class="align-end">{{ user.stars_as_owner }}</v-list-item-content>
					</v-list-item>
					<v-list-item class="vd-list-item">
						<v-list-item-content>Stars as renter:</v-list-item-content>
						<v-list-item-content class="align-end">{{ user.stars_as_renter }}</v-list-item-content>
					</v-list-item>
					<v-list-item class="vd-list-item">
						<v-list-item-content>Trips taken:</v-list-item-content>
						<v-list-item-content class="align-end">{{ user.trips_taken }}</v-list-item-content>
					</v-list-item>
				</v-card>
			</v-flex>
			<!-- second column -->
			<v-flex class="vd-text-container" xs12 sm6 md6 lg6>
				<v-card>
					<v-list-item class="vd-list-item">
						<v-list-item-content>Active:</v-list-item-content>
						<v-list-item-content class="align-end">
							<v-icon v-if="user.active" color="green">check_circle</v-icon>
							<v-icon v-else color="red">highlight_off</v-icon>
						</v-list-item-content>
					</v-list-item>
					<v-list-item class="vd-list-item">
						<v-list-item-content>Approved to drive:</v-list-item-content>
						<v-list-item-content class="align-end">
							<v-icon v-if="user.approved_to_drive" color="green">check_circle</v-icon>
							<v-icon v-else color="red">highlight_off</v-icon>
						</v-list-item-content>
					</v-list-item>
					<v-list-item class="vd-list-item">
						<v-list-item-content>email verified:</v-list-item-content>
						<v-list-item-content class="align-end">
							<v-icon v-if="user.email_verified" color="green">check_circle</v-icon>
							<v-icon v-else color="red">highlight_off</v-icon>
						</v-list-item-content>
					</v-list-item>
					<v-list-item class="vd-list-item">
						<v-list-item-content>id verified:</v-list-item-content>
						<v-list-item-content class="align-end">
							<v-icon v-if="user.id_verified" color="green">check_circle</v-icon>
							<v-icon v-else color="red">highlight_off</v-icon>
						</v-list-item-content>
					</v-list-item>
					<v-list-item class="vd-list-item">
						<v-list-item-content>Phone verified:</v-list-item-content>
						<v-list-item-content class="align-end">
							<v-icon v-if="user.phone_verified" color="green">check_circle</v-icon>
							<v-icon v-else color="red">highlight_off</v-icon>
						</v-list-item-content>
					</v-list-item>
					<v-list-item class="vd-list-item">
						<v-list-item-content>reviewed:</v-list-item-content>
						<v-list-item-content class="align-end">
							<v-icon v-if="user.reviewed" color="green">check_circle</v-icon>
							<v-icon v-else color="red">highlight_off</v-icon>
						</v-list-item-content>
					</v-list-item>
					<v-list-item class="vd-list-item">
						<v-list-item-content>Bank name:</v-list-item-content>
						<v-list-item-content class="align-end">{{ user.bank_name }}</v-list-item-content>
					</v-list-item>
					<v-list-item class="vd-list-item">
						<v-list-item-content>Account number:</v-list-item-content>
						<v-list-item-content class="align-end">{{ user.account_number }}</v-list-item-content>
					</v-list-item>
					<v-list-item class="vd-list-item">
						<v-list-item-content>IBAN:</v-list-item-content>
						<v-list-item-content class="align-end">{{ user.iban }}</v-list-item-content>
					</v-list-item>
					<v-list-item class="vd-list-item">
						<v-list-item-content>Holder name:</v-list-item-content>
						<v-list-item-content class="align-end">{{ user.holder_full_name }}</v-list-item-content>
					</v-list-item>
				</v-card>
			</v-flex>
		</div>
		<!-- images row -->
		<v-expansion-panels>

			<v-expansion-panel class="vd-photos-expansion">
				<v-expansion-panel-header>
						Photos
				</v-expansion-panel-header>
				<v-expansion-panel-content>
				<v-divider></v-divider>
					
					<div>
						<!-- profile photo -->
						<v-flex xs12 sm6 md4 lg4>
							<v-card class="elevation-10 vd-photos-expansion">
									<!-- v-if="user.profile.profile_photo" -->
								<v-img
									:lazy-src="photoBoolean(user.profile ? user.profile.profile_photo : '../../../static/images/no-image.svg')"
									class=""
									:src="photoBoolean(user.profile ? user.profile.profile_photo : '../../../static/images/no-image.svg')"
									height="260px"
									>
								</v-img>
									<v-card-actions>
									<p>Profile photo</p>
									<a
										target="_blank"
										:href="photoBoolean(user.profile ? user.profile.profile_photo : '../../../static/images/no-image.svg')"
										class="btn btn-info" style="margin-left:5px;">
										VIEW
									</a>
								</v-card-actions>
							</v-card>
						</v-flex>
						<!-- ID photo -->
						<v-flex xs12 sm6 md4 lg4>
							<v-card class="elevation-10 vd-photos-expansion">
								<v-img
									class=""
									:lazy-src="photoBoolean(user.profile ? user.profile.id_image_path : '../../../static/images/no-image.svg')"
									:src="photoBoolean(user.profile ? user.profile.id_image_path : '../../../static/images/no-image.svg')"
									height="260px"
									>

								</v-img>

								<v-card-actions>
									<div>
										<span>ID photo</span>
									</div>
								</v-card-actions>

								<v-divider light></v-divider>

								<v-card-actions>
									<v-btn
										v-if="user.id_verified === 0"
										@click="approveIdPhoto"
										color="success">
										APPROVE
									</v-btn>
									<v-btn
										v-else
										@click="denyIdPhoto"
										color="error">
										DENY
									</v-btn>
									<a
										target="_blank"
										:href="photoBoolean(user.profile ? user.profile.id_image_path : '../../../static/images/no-image.svg')"
										class="btn btn-info" style="margin-left:5px;">
										VIEW
									</a>
								</v-card-actions>

							</v-card>
						</v-flex>
						<v-flex xs12 sm6 md4 lg4>
							<v-card class="elevation-10 vd-photos-expansion">
								<v-img
									class=""
									:lazy-src="photoBoolean(user.profile ? user.profile.profile_photo : '../../../static/images/no-image.svg')"
									:src="photoBoolean(user.profile ? user.profile.driver_licence_image_path : '../../../static/images/no-image.svg')"
									height="260px"
									>
								</v-img>

								<v-card-actions>
									<span>Driver licence photo</span>
								</v-card-actions>

								<v-divider light></v-divider>

								<v-card-actions>
									<v-btn
										v-if="user.approved_to_drive === 0"
										@click="approveLicencePhoto"
										color="success">
										APPROVE
									</v-btn>
									<v-btn
										v-else
										@click="denyLicencePhoto"
										color="error">
										DENY
									</v-btn>
									<a
										target="_blank"
										:href="photoBoolean(user.profile ? user.profile.driver_licence_image_path : '../../../static/images/no-image.svg')"
										class="btn btn-info" style="margin-left:5px;">
										VIEW
									</a>
								</v-card-actions>

							</v-card>
						</v-flex>
					</div>
				</v-expansion-panel-content>
			</v-expansion-panel>
			<!-- Profile details row -->
			<v-expansion-panel class="vd-photos-expansion">
				<v-expansion-panel-header>
						Profile details
				</v-expansion-panel-header>
				<v-expansion-panel-content >
				<v-divider></v-divider>
					
					<v-card>
						<!-- divider -->
						<v-divider></v-divider>
						<!-- data -->
						<v-list-item class="vd-list-item">
							<v-list-item-content>Address:</v-list-item-content>
							<v-list-item-content class="align-end">{{ user.profile ? user.profile.address : '' }}</v-list-item-content>
						</v-list-item>
						<v-list-item class="vd-list-item">
							<v-list-item-content>Created at:</v-list-item-content>
							<v-list-item-content class="align-end">{{ user.profile ? user.profile.created_at :'' }}</v-list-item-content>
						</v-list-item>
						<v-list-item class="vd-list-item">
							<v-list-item-content>Date of issue::</v-list-item-content>
							<v-list-item-content class="align-end">{{ user.profile ? user.profile.date_of_issue : '' }}</v-list-item-content>
						</v-list-item>
						<v-list-item class="vd-list-item">
							<v-list-item-content>Date of birth:</v-list-item-content>
							<v-list-item-content class="align-end">{{ user.profile ? user.profile.dob : '' }}</v-list-item-content>
						</v-list-item>
						<v-list-item class="vd-list-item">
							<v-list-item-content>Driver licence date of issue:</v-list-item-content>
							<v-list-item-content class="align-end">{{ user.profile ? user.profile.driver_licence_data_of_issue : '' }}</v-list-item-content>
						</v-list-item>
						<v-list-item class="vd-list-item">
							<v-list-item-content>Driver licence expiration date:</v-list-item-content>
							<v-list-item-content class="align-end">{{ user.profile ? user.profile.driver_licence_expiration_date : '' }}</v-list-item-content>
						</v-list-item>
						<v-list-item class="vd-list-item">
							<v-list-item-content>Driver licence number:</v-list-item-content>
							<v-list-item-content class="align-end">{{ user.profile ? user.profile.driver_licence_number : '' }}</v-list-item-content>
						</v-list-item>
						<v-list-item class="vd-list-item">
							<v-list-item-content>Expiration date:</v-list-item-content>
							<v-list-item-content class="align-end">{{ user.profile ? user.profile.expiration_date : '' }}</v-list-item-content>
						</v-list-item>
						<v-list-item class="vd-list-item">
							<v-list-item-content>Expired driver licence:</v-list-item-content>
							<v-list-item-content class="align-end">{{ user.profile ? user.profile.Expired_id : '' }}</v-list-item-content>
						</v-list-item>
						<v-list-item class="vd-list-item">
							<v-list-item-content>City id:</v-list-item-content>
							<v-list-item-content class="align-end">{{ user.profile ? user.profile.id_city : '' }}</v-list-item-content>
						</v-list-item>
						<v-list-item class="vd-list-item">
							<v-list-item-content>Country id:</v-list-item-content>
							<v-list-item-content class="align-end">{{ user.profile ? user.profile.id_country : '' }}</v-list-item-content>
						</v-list-item>
						<v-list-item class="vd-list-item">
							<v-list-item-content>Number ID:</v-list-item-content>
							<v-list-item-content class="align-end">{{ user.profile ? user.profile.id_number : '' }}</v-list-item-content>
						</v-list-item>
						<v-list-item class="vd-list-item">
							<v-list-item-content>State ID:</v-list-item-content>
							<v-list-item-content class="align-end">{{ user.profile ? user.profile.id_state : '' }}</v-list-item-content>
						</v-list-item>
						<v-list-item class="vd-list-item">
							<v-list-item-content>Issued by:</v-list-item-content>
							<v-list-item-content class="align-end">{{ user.profile ? user.profile.issued_by : '' }}</v-list-item-content>
						</v-list-item>
						<v-list-item class="vd-list-item">
							<v-list-item-content>Language:</v-list-item-content>
							<v-list-item-content class="align-end">{{ user.profile ? user.profile.language :'' }}</v-list-item-content>
						</v-list-item>
						<v-list-item class="vd-list-item">
							<v-list-item-content>First name:</v-list-item-content>
							<v-list-item-content class="align-end">{{ user.profile ? user.profile.first_name : '' }}</v-list-item-content>
						</v-list-item>
						<v-list-item class="vd-list-item">
							<v-list-item-content>Middle name:</v-list-item-content>
							<v-list-item-content class="align-end">{{ user.profile ? user.profile.middle_name : '' }}</v-list-item-content>
						</v-list-item>
						<v-list-item class="vd-list-item">
							<v-list-item-content>Last name:</v-list-item-content>
							<v-list-item-content class="align-end">{{ user.profile ? user.profile.last_name : '' }}</v-list-item-content>
						</v-list-item>
						<v-list-item class="vd-list-item">
							<v-list-item-content>School:</v-list-item-content>
							<v-list-item-content class="align-end">{{ user.profile ? user.profile.school : '' }}</v-list-item-content>
						</v-list-item>
						<v-list-item class="vd-list-item">
							<v-list-item-content>Updated at:</v-list-item-content>
							<v-list-item-content class="align-end">{{ user.profile ? user.profile.updated_at : '' }}</v-list-item-content>
						</v-list-item>
						<v-list-item class="vd-list-item">
							<v-list-item-content>Works:</v-list-item-content>
							<v-list-item-content class="align-end">{{ user.profile ? user.profile.id_country : '' }}</v-list-item-content>
						</v-list-item>
					</v-card>
				</v-expansion-panel-content>
			</v-expansion-panel>
			<!-- CARS -->
			

			<v-expansion-panel v-if="user.cars.length > 0" class="vd-photos-expansion" v-model="panel">
				<div v-for="(car, index) in user.cars" :key="index" :id="car.id" @input="$event ? getCarPhotos(car.id) : false">
				<v-expansion-panel-header>
					<div>
						<div class="vd-cars-numbers">
							<h3>{{ index + 1 }} </h3>
						</div>
						<h3>{{`${car ? car.production_year : ''} | ${car ? car.car_manufacturer : ''} | ${car ? car.car_model : ''} | ${car ? car.trim : ''}`}}</h3>
					</div>
				</v-expansion-panel-header>
				<v-expansion-panel-content >
					
					<v-divider></v-divider>
					<div>
						<v-flex class="vd-text-container" xs12 sm6 md6 lg4>
							<v-card>
								<v-list-item class="vd-list-item">
									<v-list-item-content>Style:</v-list-item-content>
									<v-list-item-content class="align-end">{{ car ? car.style : '' }}</v-list-item-content>
								</v-list-item>
								
								<v-list-item class="vd-list-item">
									<v-list-item-content>Odometer:</v-list-item-content>
									<v-list-item-content class="align-end">{{ (car && car.car_odometer) ? car.car_odometer : car.real_odometer }}</v-list-item-content>
								</v-list-item>
								<v-list-item class="vd-list-item">
									<v-list-item-content>Value:</v-list-item-content>
									<v-list-item-content class="align-end">{{ car ? car.car_value : '' }}</v-list-item-content>
								</v-list-item>
								<v-list-item class="vd-list-item">
									<v-list-item-content>Created at:</v-list-item-content>
									<v-list-item-content class="align-end">{{ car ? car.created_at : '' }}</v-list-item-content>
								</v-list-item>
								<v-list-item class="vd-list-item">
									<v-list-item-content>Updated at:</v-list-item-content>
									<v-list-item-content class="align-end">{{ car ? car.updated_at: '' }}</v-list-item-content>
								</v-list-item>
								<v-list-item class="vd-list-item">
									<v-list-item-content>Deposit:</v-list-item-content>
									<v-list-item-content class="align-end">{{ car ? car.deposit: '' }}</v-list-item-content>
								</v-list-item>
								<v-list-item class="vd-list-item">
									<v-list-item-content>Star:</v-list-item-content>
									<v-list-item-content class="align-end">{{ car ? car.stars : '' }}</v-list-item-content>
								</v-list-item>
							</v-card>
						</v-flex>
						
						

						<v-flex class="vd-text-container lr-border" xs12 sm6 md6 lg4>
							<v-card>
								<v-list-item class="vd-list-item">
									<v-list-item-content>Shortest trip:</v-list-item-content>
									<v-list-item-content class="align-end">{{ car ? car.short_trip : '' }}</v-list-item-content>
								</v-list-item>
								<v-list-item class="vd-list-item">
									<v-list-item-content>Long trip:</v-list-item-content>
									<v-list-item-content class="align-end">{{ car ? car.long_trip :'' }}</v-list-item-content>
								</v-list-item>
								<v-list-item class="vd-list-item">
									<v-list-item-content>Car notice:</v-list-item-content>
									<v-list-item-content class="align-end">{{ car ? car.notice :'' }}</v-list-item-content>
								</v-list-item>
								<v-list-item class="vd-list-item">
									<v-list-item-content>Guest location notice:</v-list-item-content>
									<v-list-item-content class="align-end">{{ car ? car.guest_location_notice :'' }}</v-list-item-content>
								</v-list-item>
								<v-list-item class="vd-list-item">
									<v-list-item-content>Car location notice:</v-list-item-content>
									<v-list-item-content class="align-end">{{ car ? car.car_location_notice : '' }}</v-list-item-content>
								</v-list-item>
								<v-list-item class="vd-list-item">
									<v-list-item-content>Airport notice:</v-list-item-content>
									<v-list-item-content class="align-end">{{ car ? car.airport_notice :'' }}</v-list-item-content>
								</v-list-item>
								<v-list-item class="vd-list-item">
									<v-list-item-content>Key handoff:</v-list-item-content>
									<v-list-item-content class="align-end">{{ car ? car.key_handoff : '' }}</v-list-item-content>
								</v-list-item>
								<v-list-item class="vd-list-item">
									<v-list-item-content>Parking details:</v-list-item-content>
									<v-list-item-content class="align-end">{{ car ? car.parking_details :'' }}</v-list-item-content>
								</v-list-item>
							</v-card>
						</v-flex>
						

						<v-flex class="vd-text-container" xs12 sm6 md6 lg4>
							<v-card>
								<v-list-item class="vd-list-item">
									<v-list-item-content>ID:</v-list-item-content>
									<v-list-item-content class="align-end">{{ car ? car.id : '' }}</v-list-item-content>
								</v-list-item>
								<v-list-item class="vd-list-item">
									<v-list-item-content>Car active:</v-list-item-content>
									<v-list-item-content class="align-end">
										<v-icon v-if="car ? car.car_is_active : ''" color="green">check_circle</v-icon>
										<v-icon v-else color="red">highlight_off</v-icon>
									</v-list-item-content>
								</v-list-item>
								<v-list-item class="vd-list-item">
									<v-list-item-content>Verified insurance:</v-list-item-content>
									<v-list-item-content class="align-end">
										<v-icon v-if="car ? car.is_insurance_verified : ''" color="green">check_circle</v-icon>
										<v-icon v-else color="red">highlight_off</v-icon>
									</v-list-item-content>
								</v-list-item>
								<v-list-item class="vd-list-item">
									<v-list-item-content>Verified registration:</v-list-item-content>
									<v-list-item-content class="align-end">
										<v-icon v-if="car ? car.is_registration_car_verified : ''" color="green">check_circle</v-icon>
										<v-icon v-else color="red">highlight_off</v-icon>
									</v-list-item-content>
								</v-list-item>
								<v-list-item class="vd-list-item">
									<v-list-item-content>Weekend trip:</v-list-item-content>
									<v-list-item-content class="align-end">
										<v-icon v-if="car ? car.weekend_trip : ''" color="green">check_circle</v-icon>
										<v-icon v-else color="red">highlight_off</v-icon>
									</v-list-item-content>
								</v-list-item>
								<v-list-item class="vd-list-item">
									<v-list-item-content>Long term trip:</v-list-item-content>
									<v-list-item-content class="align-end">
										<v-icon v-if="car ? car.long_term_trip : ''" color="green">check_circle</v-icon>
										<v-icon v-else color="red">highlight_off</v-icon>
									</v-list-item-content>
								</v-list-item>
								<v-list-item class="vd-list-item">
									<v-list-item-content>Paid advertaising:</v-list-item-content>
									<v-list-item-content class="align-end">
										<v-icon v-if="car ? car.paid_advertising : ''" color="green">check_circle</v-icon>
										<v-icon v-else color="red">highlight_off</v-icon>
									</v-list-item-content>
								</v-list-item>
							</v-card>
						</v-flex>
					</div>
					
					
					
					<v-divider></v-divider>
					<div>
						<v-flex>
						<v-card>
							<v-container fluid grid-list-md>
								<div row wrap>
									<v-flex
										v-for="(image, index) in carImages"
										lg4 md6 xs12 sm6
										:key="index"
										>
										<v-card>
											<v-img
												class="elevation-10"
												:src="$imageURL + image.small_image_path"
												height="200px"
												>
											</v-img>
										</v-card>
									</v-flex>
								</div>
							</v-container>
						</v-card>
						</v-flex>
					</div>


				</v-expansion-panel-content>
				</div>
			</v-expansion-panel> 
			<v-chip v-else outlined>NO CARS</v-chip>
			<!-- <h1 v-else style="margin: 4px"> NO CARS</h1> -->
			</v-expansion-panels>

		
	</div>
</template>

<script>
export default {
	props: {
		userId: {
			type: String
		}
	},

	data () {
		return {
			panel: [true, false, false],
			carImages: '',
			user: '',
			loading: false
		}
	},

	methods: {
		getUser () {
			this.loading = true
			this.axios.get('/user/' + this.userId, {
				headers: {
					Authorization: "Bearer " + localStorage.getItem("id_token")
				}
			})
			.then(response => {
				this.user = response.data
				this.loading = false
			})
			.catch(error => {
				console.log(error)
				this.loading = false
			})
		},

		getCarPhotos (carId) {
			this.axios.get('/cars/images/' + carId, {
				headers: {
					Authorization: "Bearer " + localStorage.getItem("id_token")
				}
			})
			.then(response => {
				this.carImages = response.data
			})
			.catch(error => {
				console.log(error)
			})
		},

		photoBoolean (photo) {
			return photo ? this.$imageURL + photo : '../../../static/images/no-image.svg'
		},

        approveIdPhoto () {
            this.loading = true
            this.axios.post('/users/approve/id/' + this.user.id, {},
			{
				headers: {
					Authorization: "Bearer " + localStorage.getItem("id_token")
				}
			})
			.then(response => {
				this.loading = false
                this.getUser()
			})
			.catch(error => {
				console.log(error)
				this.loading = false
			})
        },

        denyIdPhoto () {
            this.loading = true
            this.axios.post('/users/deny/id/' + this.user.id, {},
			{
				headers: {
					Authorization: "Bearer " + localStorage.getItem("id_token")
				}
			})
			.then(response => {
				this.loading = false
				this.getUser()
			})
			.catch(error => {
				console.log(error)
				this.loading = false
			})
        },

        approveLicencePhoto () {
            this.loading = true
            this.axios.post('/users/approve/licence/' + this.user.id, {},
			{
				headers: {
					Authorization: "Bearer " + localStorage.getItem("id_token")
				}
			})
			.then(response => {
				this.loading = false
				this.getUser()
			})
			.catch(error => {
				console.log(error)
				this.loading = false
			})
        },

        denyLicencePhoto () {
            this.loading = true
            this.axios.post('/users/deny/licence/' + this.user.id, {},
			{
				headers: {
					Authorization: "Bearer " + localStorage.getItem("id_token")
				}
			})
			.then(response => {
				this.loading = false
				this.getUser()
			})
			.catch(error => {
				console.log(error)
				this.loading = false
			})
        }
	},

	created () {
		this.getUser()
	}

}
</script>
 <style>
	.lr-border {
		border-right: 1px solid #dadada;
		border-left: 1px solid #dadada;
	}

	.vd-cars-numbers {
		padding-left: 5px;
		padding-right: 5px;
		margin-right: 20px;

		color: #fdb633;
		/* padding-top: 2px; */
		/* padding-bottom: 2px; */
		/* border: 2px solid #fdb63343; */
		/* border-radius: 7px; */
		/* background: #fdb63343; */
		/* border: 2px solid #fdb633; */
	}
 </style>
