<template>
    <v-card  
        elevation="20"
        outlined
        shaped
        justify="center" 
        class="justify-center mx-auto" 
        :color="`white`" 
        light max-width="400"
        style="border:2px solid black;"
    >
        <!-- <activeUsers 
            v-if="loadingDialog()  && userStatus  === 0 && type === 'active'" 
            :dialog="loadingDialog()" 
            :status="userStatus" 
            :type='type'
            v-once
        ></activeUsers> -->
        <v-card-title primary-title justify="center" class="justify-center">
            <div>
                <h3 class="headline mb-0">{{title}} </h3>
                <div class="sum-numbers">{{ value }}</div>
            </div>
        </v-card-title>
        <v-card-actions v-if="hasButton" justify="center" class="justify-center" >
            <dropdown
                :status="status" 
                :type='type'
                :model='model'
            ></dropdown>
        </v-card-actions>
    </v-card>

</template>

<script>
import dropdown from '../popups/generalDropdown'
export default {
    components: {
        dropdown
	},
    props: {
        hasButton: {
            type: Boolean,
            required: true
        },
        title: {
            type: String,
            required: false
        },
        value: {
            type: Number,
            required: false
        },
        status: {
            type: Number,
            required: false
        },
        type: {
            type: String,
            required: false
        },
        model: {
            type: String,
            required: false
        },
        color: {
            type: String,
            required: false
        }
    },

    data() {
        return {
        }
    }
}
</script>

<style>
  .widget-card {
    border-radius: 5%;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.21);
    background-color: transparent;
  }
</style>
