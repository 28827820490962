<template>
<v-container>
    
    <v-layout row justify-center>
    
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      
      <v-card>
        
        <v-toolbar dark color="grey">
            <v-btn icon dark @click="closeDialog()">
                <v-icon>close</v-icon>
            </v-btn>
            <v-toolbar-title>{{title}}</v-toolbar-title>
        </v-toolbar>
        <v-card-title>
            <v-flex xs2 sm>
				<v-btn @click="getUsers()" outline type="div" color="primary" :loading="loadingStatus()">
					USERS LISTING 
				</v-btn>
            </v-flex>
            <v-flex md7>
            <v-layout row wrap justify-center mb-1>
                <v-flex xs3 sm>
                    <v-card>
                        <v-card-title class="text-xs-center" primary-title>
                            <div >
                                <h3 class="headline mb-0">Active</h3>
                                <div class="sum-numbers">{{data.active | toNumberFormat}}</div>
                            </div>
                        </v-card-title>
                    </v-card>
                </v-flex>

                <v-flex xs3 sm>
                    <v-card>
                        <v-card-title primary-title>
                            <div>
                                <h3 class="headline mb-0">Daily</h3>
                                <div class="sum-numbers">{{data.daily | toNumberFormat}}</div>
                            </div>
                        </v-card-title>
                    </v-card>
                </v-flex>
                <v-flex xs3 sm>
                    <v-card>
                        <v-card-title primary-title>
                            <div>
                                <h3 class="headline mb-0">Weekly</h3>
                                <div class="sum-numbers">{{data.weekly | toNumberFormat}}</div>
                            </div>
                        </v-card-title>
                    </v-card>
                </v-flex>
                <v-flex xs6 sm>
                    <v-card>
                        <v-card-title primary-title>
                            <div>
                                <h3 class="headline mb-0">Monthly</h3>
                                <div class="sum-numbers">{{data.monthly | toNumberFormat}}</div>
                            </div>
                        </v-card-title>
                    </v-card>
                </v-flex>
                <v-flex xs3 sm>
                    <v-card>
                        <v-card-title primary-title>
                            <div>
                                <h3 class="headline mb-0">Yearly</h3>
                                <div class="sum-numbers">{{data.yearly | toNumberFormat}}</div>
                            </div>
                        </v-card-title>
                    </v-card>
                </v-flex>
            
            </v-layout>
            </v-flex>
            <v-flex xs12 md3 lg3 sm>
			<v-text-field
			    v-model="search"
				append-icon="search"
				label="Search"
			    single-line
				hide-details
			>
			</v-text-field>
            </v-flex>
			</v-card-title>
            
			<v-card-text>
			    <v-data-table
					:headers="headers"
					:items="users"
      				class="elevation-0"
					:loading="loadingStatus()"
					:pagination.sync="pagination"
					hide-default-footer="false"
					:hideActions=true
					rows-per-page-text=""
					:rows-per-page-items="[10, 20, 30, 50]"
					:footer-props="{ disablePagination : false, disableItemsPerPage : false }"
				>
				<template slot="items" slot-scope="props">
					<tr class="tr-click">
						<td @click="props.expanded = !props.expanded">{{ props.item.id }}</td>
						<td @click="props.expanded = !props.expanded" class="text-xs-left">{{ props.item.first_name }}</td>
						<td @click="props.expanded = !props.expanded" class="text-xs-left">{{ props.item.last_name }}</td>
						<td @click="props.expanded = !props.expanded" class="text-xs-left">{{ props.item.email }}</td>
					    <td @click="props.expanded = !props.expanded" class="text-xs-left">{{ props.item.cars_count }}</td>
						<td @click="props.expanded = !props.expanded" class="text-xs-left">{{ props.item.joined }}</td>
						<td @click="props.expanded = !props.expanded" class="text-xs-left">{{ props.item.phone_number }}</td>
						<td @click="props.expanded = !props.expanded" class="text-xs-left">{{ props.item.car_trips }}</td>
						<td @click="props.expanded = !props.expanded" class="text-xs-left">{{ props.item.active ? "Yes" : "No" }}</td>
					    <td
							v-if="props.item.active === 'Yes'"
							class="text-xs-left">
							<v-btn
								@click="showDeleteDialog(props.item.id)"
								color="error">
								DELETE
							</v-btn>
						</td>
						<td
						    v-else
							class="text-xs-left">
							<v-btn class="badge badge-success"
								@click="showRestoreDialog(props.item.id)"
								color="success">
								RESTORE
							</v-btn>
						</td>
						<td @click="props.expanded = !props.expanded" class="text-xs-left">

							<v-icon v-if="props.item.id_verified === 1 && props.item.approved_to_drive === 1 " color="green">check_circle</v-icon>
							<v-icon v-else color="red">highlight_off</v-icon>

						</td>
					</tr>
				</template>
				<!-- expanding component -->
			    <template slot="expand" slot-scope="props">
					<selected-user :userId="props.item.id"></selected-user>
				</template>
				<v-alert slot="no-results" :value="true" color="error" icon="warning">
					Your search for "{{ search }}" found no results.
				</v-alert>
				</v-data-table>
				<paginate store="users" collection="meta"/>
                <v-progress-linear v-if="loadingStatus()" :indeterminate="true"></v-progress-linear>

				</v-card-text>	 
				<!-- delete dialog -->
				<v-dialog v-model="dialogDelete" v-if="selectedItem" persistent max-width="290">
					<v-card>
						<v-card-title class="headline">Are you sure?</v-card-title>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="info" @click.native="dialogDelete = false; deleteUser(selectedItem)">DELETE</v-btn>
							<v-btn color="error" @click.native="dialogDelete = false">CANCEL</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
				<!-- end of delete dialog-->

				<!-- restore dialog -->
				<v-dialog v-model="dialogRestore" v-if="selectedItem" persistent max-width="290">
					<v-card>
						<v-card-title class="headline">Are you sure?</v-card-title>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="info" @click.native="dialogRestore = false; restoreUser(selectedItem)">RESTORE</v-btn>
							<v-btn color="error" @click.native="dialogRestore = false">CANCEL</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
				<!-- end of restore dialog-->
                </v-card>
            </v-dialog>
        </v-layout>
    </v-container>
</template>
<script>
    import paginate from '../paginate'
    export default {
		props: ['status', 'type'],
        components: {
			paginate
		},
        data () {
        return {
            data: '',
            dialog: false,
            notifications: false,
            sound: true,
            widgets: false,
            search: '',
				loading: '',
                dialogDelete: false,
                dialogRestore: false,
				selectedItem: null,
				numOfunverifiedUsers: [],
				pagination: {
					sortBy: 'id',
					rowsPerPage: 10
				},
				headers: [
					{
						text: 'USER ID',
						align: 'left',
						sortable: true,
						value: 'id'
					},
					{ text: 'First name', value: 'first_name' },
					{ text: 'Last name', value: 'last_name' },
					{ text: 'Email', value: 'email' },
					{ text: 'Listed Cars', value: 'listed_cars' },
					{ text: 'Joined', value: 'joined' },
					{ text: 'Phone number', value: 'phone_number' },
					{ text: 'Car trips', value: 'car_trips' },
					{ text: 'Active', value: 'active' },
                    { text: 'Delete/Restore', value: 'delete_restore' },
                    { text: 'Verified', value: 'active' }
				]
        }
        },
        computed: {
			users: {
				get () {
					return this.$store.state.users.users
				}
			},
			pages () {
				if (this.pagination.rowsPerPage == null ||
					this.pagination.totalItems == null
				) return 0

				return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
			},
			title: {
				get () {
					let text = this.type === "active" ? 'Active' : 'Verified'
					let status = this.status === 1 ? '' : this.type === "active" ? 'In-' : 'Un-'
					return status + text + ' Users'
				}
			}
		},
		watch: {
			loading (newVal, oldVal) {
				this.loading = this.loadingStatus()
			},
			search (newVal, oldVal) {
				console.log("Search method")
				if (this.type === "active") {
					this.$store.dispatch('users/UnsetVerifiedAction')
					this.$store.dispatch('users/setActiveAction', this.status)
				} else if (this.type === "verified") {
					this.$store.dispatch('users/UnsetActiveAction')
					this.$store.dispatch('users/setVerifiedAction', this.status)
				}
                this.$store.dispatch('users/setCurrentUrl', '/users')
				this.$store.dispatch('users/setSearchText', newVal)
				this.$store.dispatch('users/getList', 1).then(response => {
					this.loading = this.$store.getters['users/getLoader']
				})
			}
		},
		created () {
			this.getCounts()
			console.log("Create method")
			if (this.type === "active") {
				this.$store.dispatch('users/UnsetVerifiedAction')
				this.$store.dispatch('users/setActiveAction', this.status)
			} else if (this.type === "verified") {
				this.$store.dispatch('users/UnsetActiveAction')
				this.$store.dispatch('users/setVerifiedAction', this.status)
			}
            this.$store.dispatch('users/setCurrentUrl', '/users')
			this.$store.dispatch('users/getList', 1).then(response => {
				this.loading = this.$store.getters['users/getLoader']
            })
            this.dialog = this.$store.getters['users/getDialog']
		},
		methods: {
            closeDialog () {
				this.$store.dispatch('users/DiagSet')
			},
			getUsers () {
				console.log("getUser method")
				if (this.type === "active") {
					this.$store.dispatch('users/UnsetVerifiedAction')
					this.$store.dispatch('users/setActiveAction', this.status)
				} else if (this.type === "verified") {
					this.$store.dispatch('users/UnsetActiveAction')
					this.$store.dispatch('users/setVerifiedAction', this.status)
				}
                this.$store.dispatch('users/setCurrentUrl', '/users')
				this.$store.dispatch('users/getList', 1).then(response => {
					this.loading = this.$store.getters['users/getLoader']
				})
				if (this.users.length > 0) {
					this.numOfunverifiedUsers = []
					this.users.forEach(elem => {
						if (elem.id_verified === 0 || elem.approved_to_drive === 0) {
							this.numOfunverifiedUsers.push(elem)
						}
					})
					this.loading = this.$store.getters['users/getLoader']
				} else {
					this.loading = this.$store.getters['users/getLoader']
				}
			},
			loadingStatus () {
				return this.$store.getters['users/getLoader']
            },
            getCounts () {
                this.loading = true
                this.axios.get('/popUsers?model=User&status=' + this.status + '&type=' + this.type,
				{
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("id_token")
                    }
                })
				.then(response => {
                    this.loading = false
                    // console.log(response.data)
                    this.data = response.data
				})
				.catch(error => {
					console.log(error)
					this.loading = false
				})
            },
            deleteUser (id) {
                this.loading = true
                this.axios.post('/users/delete/' + id, {},
				{
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("id_token")
                    }
                })
				.then(response => {
					this.loading = false
                    this.getUsers()
				})
				.catch(error => {
					console.log(error)
					this.loading = false
				})
            },

            restoreUser (id) {
                this.loading = true
                this.axios.post('/users/restore/' + id, {},
				{
					headers: {
						Authorization: "Bearer " + localStorage.getItem("id_token")
					}
				})
				.then(response => {
					this.loading = false
                    this.getUsers()
				})
				.catch(error => {
					console.log(error)
					this.loading = false
				})
            },

            showDeleteDialog (value) {
                this.dialogDelete = true
                this.selectedItem = value
            },

            showRestoreDialog (value) {
                this.dialogRestore = true
                this.selectedItem = value
            }
        },
        filters: {
            toNumberFormat (value) {
                value = value > 0 ? value : 0
                return `${value.toLocaleString()}`
            }
        },
		mounted () {
			// this.getUsers()
		}
    }
</script>