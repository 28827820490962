import Axios from 'axios'
const state = {
    users: [],
    meta: {},
    link: {},
    loading: true,
    error: [],
    search: '',
    dialog: '',
    url: '',
    date_range: ''
}

const actions = {
    getList ({commit}, pageNumber) {
        commit('setLoading')
        Axios.get(state.url + '?page=' + pageNumber + '&search=' + state.search + '&dates=' + state.date_range, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("id_token")
            }
        }).then(response => {
            commit('setEarnings', response.data.data.users)
            commit('setMeta', response.data.meta)
            commit('setLink', response.data.links)
            commit('UnsetLoading')
        }).catch(error => {
            commit('UnsetLoading')
            state.error = error
        })
    },
    setLoader (context) {
        context.commit('UnsetLoading')
    },
    setSearchText ({commit}, search) {
        commit('setSearch', search)
    },
    setDateRangeNow ({commit}, dates) {
        commit('setDateRange', dates)
    },
    setCurrentUrl ({commit}, url) {
        commit('setUrl', url)
    },
    DiagSet ({commit}) {
        commit('setDialog')
    },
    Unset_Date_range ({commit}) {
        commit('UnsetDateRange')
    }
}
const mutations = {
    setEarnings (state, payload) {
        state.users = payload
    },
    setSearch (state, payload) {
        state.search = payload
    },
    setDateRange (state, payload) {
        state.date_range = payload
    },
    setMeta (state, payload) {
        state.meta = payload
    },
    setLink (state, payload) {
        state.link = payload
    },
    setLoading (state) {
        state.loading = true
    },
    UnsetLoading (state) {
        state.loading = false
    },
    setCurrentPage (state, payload) {
        state.meta.current_page = payload
    },
    setDialog (state) {
        state.dialog = !state.dialog
    },
    setUrl (state, payload) {
        state.url = payload
    },
    UnsetDateRange (state) {
        state.date_range = ""
    }
}
const getters = {
        getUsersNow: (state) => state.users,
        getLoader: (state) => state.loading,
        getDialog: (state) => state.dialog,
        getUrl: (state) => state.url
}
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
