var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"overflow":"auto"},attrs:{"fluid":"","grid-list-md":""}},[_c('v-slide-y-transition',{attrs:{"mode":"out-in"}},[_c('v-layout',{attrs:{"wrap":"","fill-height":""}},[_c('v-card',[_c('v-card-title',[_c('v-flex',{attrs:{"xs6":"","sm6":"","md3":""}},[_c('v-btn',{attrs:{"outlined":"","type":"div","id":"open","color":"primary","loading":_vm.loadingStatus()},on:{"click":function($event){return _vm.getCars()}}},[_vm._v(" CARS LISTING ")])],1),_c('v-flex',{attrs:{"xs4":""}},[_c('v-md-date-range-picker',{attrs:{"show-year-select":"","max-year":"2030","min-year":"2010"},on:{"change":_vm.handleChange}})],1),_c('v-flex',{attrs:{"xs12":"","sm6":"","md4":""}},[_c('v-text-field',{attrs:{"append-icon":"search","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer'),_c('vueBtn',{attrs:{"json_data":_vm.cars,"param":{
								model: 'Car',
								search: _vm.search,
								type: _vm.dates,
								status: null,
								duration:'Total',
								url: '/report/getCountsRecordsExport',
								filename: 'Total' + '_Cars',
								date_range: _vm.dates
							},"fields":_vm.headerfileExport,"fieldsPDF":_vm.headerfileExportPDF}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.cars,"loading":_vm.loadingStatus(),"hide-default-footer":false,"hideActions":true,"footer-props":{ disablePagination : false, disableItemsPerPage : false },"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.ownerName",fn:function(ref){
							var item = ref.item;
return [_vm._v(" "+_vm._s(((item.owner.first_name) + " " + (item.owner.last_name)))+" ")]}},{key:"item.created_at",fn:function(ref){
							var item = ref.item;
return [_vm._v(" "+_vm._s(("" + (item.created_at)))+" ")]}},{key:"item.ownerId",fn:function(ref){
							var item = ref.item;
return [_vm._v(" "+_vm._s(("" + (item.owner.id)))+" ")]}},{key:"item.active",fn:function(ref){
							var item = ref.item;
return [_vm._v(" "+_vm._s(item.active ? "Yes" : "No")+" ")]}},{key:"item.delete_restore",fn:function(ref){
							var item = ref.item;
return [(item.active === 1)?_c('div',{staticClass:"text-xs-left"},[_c('v-icon',{attrs:{"color":"error"},on:{"click":function($event){return _vm.showDeleteDialog(item.id)}}},[_vm._v(" mdi-delete ")])],1):_c('div',{staticClass:"text-xs-left"},[_c('v-icon',{attrs:{"color":"success"},on:{"click":function($event){return _vm.showRestoreDialog(item.id)}}},[_vm._v(" mdi-check ")])],1)]}},{key:"item.Verified",fn:function(ref){
							var item = ref.item;
return [(item.verified_insurance === 1 && item.verified_registration === 1 )?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("check_circle")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v("highlight_off")])]}},{key:"expanded-item",fn:function(ref){
							var headers = ref.headers;
							var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('selected-car',{attrs:{"carId":item.id}})],1)]}}])}),_c('paginate',{attrs:{"store":"cars","collection":"meta"}}),(_vm.loadingStatus())?_c('v-progress-linear',{attrs:{"indeterminate":true}}):_vm._e()],1),(_vm.selectedItem)?_c('v-dialog',{attrs:{"persistent":"","max-width":"290"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Are you sure?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"info"},nativeOn:{"click":function($event){_vm.dialogDelete = false; _vm.deleteCar(_vm.selectedItem)}}},[_vm._v("DELETE")]),_c('v-btn',{attrs:{"color":"error"},nativeOn:{"click":function($event){_vm.dialogDelete = false}}},[_vm._v("CANCEL")])],1)],1)],1):_vm._e(),(_vm.selectedItem)?_c('v-dialog',{attrs:{"persistent":"","max-width":"290"},model:{value:(_vm.dialogRestore),callback:function ($$v) {_vm.dialogRestore=$$v},expression:"dialogRestore"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Are you sure?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"info"},nativeOn:{"click":function($event){_vm.dialogRestore = false; _vm.restoreCar(_vm.selectedItem)}}},[_vm._v("RESTORE")]),_c('v-btn',{attrs:{"color":"error"},nativeOn:{"click":function($event){_vm.dialogRestore = false}}},[_vm._v("CANCEL")])],1)],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }