<template>
	<v-container  class="vd-data-container">
		<!-- first row -->
   <v-layout column >
		  	<v-flex class="vd-text-container" xs12 sm6 md6 lg6>
				  <v-card>
				    <v-card>
				    	<v-list-item class="vd-list-item" v-for="(bil,index) in bills" :key="index" v-if="bil.owner_earning > 0">
                      <v-list-item-content >
                         Trip Id {{bil.trip_id}}
                      </v-list-item-content>
                      <v-list-item-content >

                        Owner Earnings  {{ bil.owner_earning }}

                        </v-list-item-content>
                        <v-list-item-content >
                          <v-btn outlined  color="primary"  @click="showDialog(bil)"> {{bil.esar_paid === 0 ? "Unpaid" : "Paid"}}</v-btn>
                         <!-- <v-btn  outlined  color="primary" @click="PaidUnpaid(tr)">{{tr.esar_paid === 0 ? "Unpaid" : "Paid"}}</v-btn> -->
                        </v-list-item-content>
					    </v-list-item>
                                <v-list-item-content class="align-end">
					                     </v-list-item-content>
                                    <v-list-item class="vd-list-item" >
                                          <!-- <v-list-item-content>Total sum: {{sum}} </v-list-item-content> -->
                                    </v-list-item>
                              <v-dialog v-model="dialogPaid" v-if="selectedItem" persistent max-width="500">
                                <v-card>
                                    <v-card-title class="headline">Are you sure?</v-card-title>
                                      <v-card-actions>
                                          <v-spacer></v-spacer>
                                        <v-btn color="info" @click.native="dialogPaid = false; PaidUnpaid(selectedItem)">YES</v-btn>
                                        <v-btn color="error" @click.native="dialogPaid = false">NO</v-btn>
                                    </v-card-actions>
                                  </v-card>
                                </v-dialog>
				    </v-card>
				  </v-card>
		  	</v-flex>
			</v-layout>
	</v-container>
</template>
<script>
export default {
  props: {
    userId: {
      type: String
    },
    tripId: {
      type: String
    }
  },
  data () {
    return {
      detailss: [],
      loading: false,
      bills: "",
      sum: "",
      tripss: [],
      dialog: false,
      selectedItem: null,
      showDeleteDialog: false,
      dialogPaid: false
    }
  },
  methods: {
    getTrip () {
      this.loading = true
      this.axios
        .get("report/earners/" + this.userId, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("id_token")
          }
        })
        .then(response => {
          this.detailss = response.data
          this.bills = response.data.bills

          this.detailss.bills.forEach(trip => {
              this.tripss.push(trip)
          })
          this.loading = false
        })
        .catch(error => {
          console.log(error)
          this.loading = false
        })
    },
    PaidUnpaid (bil) {
      this.axios.post("/users/pay/" + bil.trip_id, {}, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("id_token")
        }
      }).then(response => {
       this.$swal({
  type: 'success',
  title: 'Your work has been saved',
  showConfirmButton: false,
  timer: 3500
})
      }).catch(error => {
        console.log(error)
      })
    },
    showDialog (value) {
      this.dialogPaid = true
      this.selectedItem = value
      // console.log(value)
    }
  },
  created () {
    this.getTrip()
  }
}
</script>
 <style scoped>

</style>
