import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        light:true,
        themes: {
            light: {
                primary: "#101820",
                secondary: "#A5D6A7",
                accent: "#616161",
                error: "#f44336",
                warning: "#ffeb3b",
                info: "#101820",
                success: "#4caf50"
            },
            dark: {
                primary: "#101820",
                secondary: "#A5D6A7",
                accent: "#616161",
                error: "#f44336",
                warning: "#ffeb3b",
                info: "#101820",
                success: "#4caf50"
            }
        }
    },
    icons: {
        iconfont: 'md', // default - only for display purposes
    }
});
