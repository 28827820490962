var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-slide-y-transition',{attrs:{"mode":"out-in"}},[_c('v-layout',{attrs:{"column":"","md12":"","sm12":"","lg12":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.desserts,"sort-by":"name"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("ROLE MANAGEMENT")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" ADD ROLE ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();_vm.editedItem.id != 0 ? _vm.updateRecord(_vm.editedItem) : _vm.submit()}}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":100,"error-messages":errors,"label":"Name","required":""},model:{value:(_vm.editedItem.name),callback:function ($$v) {_vm.$set(_vm.editedItem, "name", $$v)},expression:"editedItem.name"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Role Description","required":""},model:{value:(_vm.editedItem.description),callback:function ($$v) {_vm.$set(_vm.editedItem, "description", $$v)},expression:"editedItem.description"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"level","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.items,"error-messages":errors,"label":"Select role level","data-vv-name":"level","required":""},model:{value:(_vm.editedItem.level),callback:function ($$v) {_vm.$set(_vm.editedItem, "level", $$v)},expression:"editedItem.level"}})]}}],null,true)}),_c('v-btn',{staticClass:"mr-4",attrs:{"type":"submit","disabled":invalid}},[_vm._v(" submit ")]),_c('v-btn',{on:{"click":_vm.clear}},[_vm._v(" clear ")])],1)]}}])})],1)],1)],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.initialize}},[_vm._v(" Reset ")])]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }