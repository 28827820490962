<template>
<v-toolbar
    :color="color"
    flat
>
    <v-toolbar-title class="grey--text text--darken-4">
        {{title}}
    </v-toolbar-title>
    <v-spacer></v-spacer>
</v-toolbar>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: false
        },
        color: {
            type: String,
            required: true
        }
    },
  data() {
    return {
        
    }
  }
}
</script>