<template>
  <v-card>
      <v-card-title primary-title justify="center" class="justify-center">
            {{title}}
            <br/>
            <v-divider></v-divider>
            {{data}}
        </v-card-title>
        <v-card-text class="pa-0">
            <pie-chart :data="data" legend="bottom" :colors="color"></pie-chart>
        </v-card-text>
  </v-card>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: false
        },
        data: {
            type: Object,
            required: true
        },
        color: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
        }
    }
}
</script>

<style>

</style>
