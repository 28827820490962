<template>
    <v-menu transition="fab-transition" :nudge-width="200" offset-x>
        <template v-slot:activator="{ on, attrs }">
            <v-btn text outlined
                    rounded
                    small
            @click="getCounts()"
            v-bind="attrs"
            v-on="on"
            >
                Counts
            </v-btn>
        </template>
        <div class="text-xs-center">
            <v-progress-circular
                indeterminate
                color="primary"
                v-if="loading"
            ></v-progress-circular>
        </div>
        <v-list>
            <v-list-item
            v-for="(item, index) in items"
            :key="index"
            >
                <v-list-item-avatar>
                    <v-icon medium>event</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title>
                        <!-- {{item.model +item.status + item.type + item.text}} -->
                        {{ setText(item.model, item.status, item.type, item.text) +'  '+ item.value}}
                    
                    </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                    <users 
                        v-if="model === 'User' && item.status == status && item.type === type"
                        :model="item.model" 
                        :status="item.status" 
                        :type="item.type"
                        :duration="item.text"
                        :id="item.text + '_' + item.status + '_' + item.model + '_' + item.type"
                        :key="index"
                        :data="users"
                        :displayText="setText(item.model, item.status, item.type, item.text)"
                    >
                        <v-btn icon id="show-btn" @click.stop="UserDialog(item.text); $bvModal.show(item.text + '_' + item.status + '_' + item.model + '_' + item.type)"><v-icon :key="index">double_arrow</v-icon></v-btn>
                    </users>

                    
                    
                    <cars 
                        v-if="model === 'Car'"
                        v-show="model === 'Car'" 
                        :model="item.model" 
                        :status="item.status" 
                        :type="item.type"
                        :duration="item.text"
                        :id="item.text + '_' + item.status + '_' + item.model + '_' + item.type"
                        :key="item.text + '_' + item.status + '_' + item.model + '_' + item.type"
                        :displayText="setText(item.model, item.status, item.type, item.text)"
                    >
                        <v-btn icon id="show-btn" @click.stop="CarDialog(item.text); $bvModal.show(item.text + '_' + item.status + '_' + item.model + '_' + item.type)"><v-icon :key="index">double_arrow</v-icon></v-btn>
                        
                    </cars>

                    <trips 
                        v-if="model === 'Trip'" 
                        :model="item.model" 
                        :status="item.status" 
                        :type="item.type"
                        :duration="item.text"
                        :id="item.text + '_' + item.status + '_' + item.model + '_' + item.type"
                        :key="item.text + '_' + item.status + '_' + item.model + '_' + item.type"
                        :displayText="setText(item.model, item.status, item.type, item.text)"
                    >
                        <v-btn icon id="show-btn" @click.stop="TripDialog(item.text); $bvModal.show(item.text + '_' + item.status + '_' + item.model + '_' + item.type)"><v-icon :key="index">double_arrow</v-icon></v-btn>
                    </trips>

                    
                </v-list-item-action>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
import users from './dialogs/users'
import cars from './dialogs/cars'
import trips from './dialogs/trips'

export default {
    components: {
        users,
        cars,
        trips
	},
    props: ['status', 'type', 'model'],
    data () {
        return {
            items: [],
            uniqueParameter: '',
            loading: false,
            users: '',
            cars: '',
            trips: ''
        }
    },
    methods: {
        /* eslint-disable */
        setText (model, status, type, text) {
            console.log(model + ' Status: ' + status + 'Type: ' + type + 'Text: ' + text)
            if (model === "User" && status == 1 && type === "active" && text === "Active") {
                return 'Active'
            }
            else if (model === "User" && status == 0 && type === "active" && text === "Active") {
                return 'In-active'
            }
            else if (model === "User" && status == 1 && type === "verified" && text === "Active") {
                return 'Verified'
            }
            else if (model === "User" && status == 0 && type === "verified" && text === "Active") {
                return 'Unverified'
            }
            else if (model === "Car" && status == 1 && type === "active" && text === "Active") {
                return 'Listed'
            }
            else if (model === "Car" && status == 0 && type === "active" && text === "Active") {
                return 'Prospect'
            }
            else if (model === "Car" && status == 0 && type === "expired" && text === "Active") {
                return 'Expired'
            }
            else if (model === "Car" && status == 1 && type === "expired" && text === "Active") {
                return 'Valid'
            }
            else if (model === "Car" && status == 1 && type === "verified" && text === "Active") {
                return 'Verified'
            }
            else if (model === "Car" && status == 0 && type === "verified" && text === "Active") {
                return 'Unverified'
            }
            else if (model === "Trip" && status == 1 && type === "confirmed" && text === "Active") {
                return 'Confirmed'
            }
            else if (model === "Trip" && status == 0 && type === "cancelled" && text === "Active") {
                return 'Cancelled'
            }
            else if (model === "Trip" && status == 2 && type === "finished" && text === "Active") {
                return 'Finished'
            }
            else if (model === "Trip" && status == 3 && type === "waiting" && text === "Active") {
                return 'Pending'
            }
            else{
                return text
            }
        },
        clicking (data) {
            console.log(data)
        },
        getCounts () {
            this.loading = true
            this.axios.get('/report/getCounts?model=' + this.model + '&status=' + this.status + '&type=' + this.type,
			{
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("id_token")
                }
            })
			.then(response => {
                this.loading = false
                this.items = response.data
			}).catch(error => {
				console.log(error)
				this.loading = false
			})
        },
        UserDialog (duration) {
            console.log("welcome:  " + duration)
            this.$store.dispatch('dialogUsers/UnsetSearchText')
			this.$store.dispatch('dialogUsers/UnsetUserAction')
			this.$store.dispatch('dialogUsers/UnsetAllAction')
			let data = {'model': this.model, 'type': this.type, 'status': this.status, 'duration': duration}
			this.$store.dispatch('dialogUsers/setAllAction', data)
			this.$store.dispatch('dialogUsers/setCurrentUrl', '/report/getCountsRecords')
			this.$store.dispatch('dialogUsers/getList', 1)
        },
        CarDialog (duration) {
            console.log("welcome:  " + duration)
            this.$store.dispatch('dialogCars/UnsetSearchText')
			let data = {'model': this.model, 'type': this.type, 'status': this.status, 'duration': duration}
			this.$store.dispatch('dialogCars/setAllAction', data)
			this.$store.dispatch('dialogCars/setCurrentUrl', '/report/getCountsRecords')
			this.$store.dispatch('dialogCars/getList', 1)
        },
        TripDialog (duration) {
            console.log("welcome:  " + duration)
            this.$store.dispatch('dialogTrips/UnsetSearchText')
			let data = {'model': this.model, 'type': this.type, 'status': this.status, 'duration': duration}
			this.$store.dispatch('dialogTrips/setAllAction', data)
			this.$store.dispatch('dialogTrips/setCurrentUrl', '/report/getCountsRecords')
			this.$store.dispatch('dialogTrips/getList', 1)
        }

    },
    mounted () {
        // this.getCounts()
    }
}
</script>