var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"overflow":"auto"},attrs:{"fluid":"","grid-list-md":""}},[_c('v-slide-y-transition',{attrs:{"mode":"out-in"}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-card',[_c('v-card-title',[_c('v-flex',{attrs:{"xs12":"","sm6":"","md3":""}},[_c('v-btn',{attrs:{"outlined":"","type":"div","color":"blue-grey","loading":_vm.loadingStatus()},on:{"click":function($event){return _vm.getUsers()}}},[_vm._v(" USERS LISTING ")])],1),_c('v-flex',{attrs:{"xs12":"","sm6":"","md4":"","color":"blue-grey"}},[_c('v-md-date-range-picker',{attrs:{"show-year-select":"","max-year":"2030","min-year":"2010"},on:{"change":_vm.handleChange}})],1),_c('v-flex',{attrs:{"xs12":"","sm6":"","md5":""}},[_c('v-select',{attrs:{"items":_vm.options,"label":"Choose...","single-line":"","hide-details":""},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}})],1)],1),_c('vueBtn',{attrs:{"json_data":_vm.users,"param":{
							model: 'User',
							search: _vm.search,
							type: "dates",
							status: null,
							duration:'Total',
							url: '/report/getCountsRecordsExport',
							filename: 'allusers' + '_Users',
							date_range: _vm.dates
						},"fields":_vm.headerfileExport,"fieldsPDF":_vm.headerfileExportPDF}}),_c('v-card-text',[_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":""}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.users,"loading":_vm.loadingStatus(),"hide-default-footer":false,"hideActions":true,"footer-props":{ disablePagination : false, disableItemsPerPage : false },"sort-by":['id', 'email'],"sort-desc":[false, true],"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
						var headers = ref.headers;
						var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('selected-user',{attrs:{"userId":item.id}})],1)]}},{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Search (start typing...)"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.deleterestore",fn:function(ref){
						var item = ref.item;
return [(item.active === 1)?_c('div',{staticClass:"text-xs-left"},[_c('v-icon',{attrs:{"color":"error"},on:{"click":function($event){return _vm.showDeleteDialog(item.id)}}},[_vm._v(" mdi-delete ")])],1):_c('div',{staticClass:"text-xs-left"},[_c('v-icon',{attrs:{"color":"success"},on:{"click":function($event){return _vm.showRestoreDialog(item.id)}}},[_vm._v(" mdi-table-edit ")])],1)]}},{key:"item.note",fn:function(ref){
						var item = ref.item;
return [_c('user-note',{attrs:{"status":item.active,"id":item.id,"title":((item.first_name) + " " + (item.last_name) + " (" + (item.email) + ")")}},[_c('v-btn',{attrs:{"icon":"","id":"show-btn"},on:{"click":function($event){$event.stopPropagation();_vm.fetchNotes(item.id); _vm.$bvModal.show(item.id)}}},[_c('v-icon',{key:item.id},[_vm._v("content_copy")]),_vm._v("Note ")],1)],1)]}},{key:"item.note_status",fn:function(ref){
						var item = ref.item;
return [(item.note != null && Object.entries(item.note).length != 0)?_c('div',{staticClass:"text-xs-left"},[_c('span',{staticClass:"badge badge-success",attrs:{"title":item.note.comment}},[_vm._v("Has a comment")])]):_c('div',{staticClass:"text-xs-left"},[_c('span',{staticClass:"badge badge-success"},[_vm._v("No comment")])])]}},{key:"item.verified",fn:function(ref){
						var item = ref.item;
return [(item.id_verified === 1 && item.approved_to_drive === 1 )?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("check_circle")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v("highlight_off")])]}}])})],1),_c('paginate',{attrs:{"store":"users","collection":"meta"}}),(_vm.loadingStatus())?_c('v-progress-linear',{attrs:{"indeterminate":true}}):_vm._e()],1)],1),(_vm.selectedItem)?_c('v-dialog',{attrs:{"persistent":"","max-width":"290"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Are you sure?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"info"},nativeOn:{"click":function($event){_vm.dialogDelete = false; _vm.deleteUser(_vm.selectedItem)}}},[_vm._v("DELETE")]),_c('v-btn',{attrs:{"color":"error"},nativeOn:{"click":function($event){_vm.dialogDelete = false}}},[_vm._v("CANCEL")])],1)],1)],1):_vm._e(),(_vm.selectedItem)?_c('v-dialog',{attrs:{"persistent":"","max-width":"290"},model:{value:(_vm.dialogRestore),callback:function ($$v) {_vm.dialogRestore=$$v},expression:"dialogRestore"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Are you sure?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"info"},nativeOn:{"click":function($event){_vm.dialogRestore = false; _vm.restoreUser(_vm.selectedItem)}}},[_vm._v("RESTORE")]),_c('v-btn',{attrs:{"color":"error"},nativeOn:{"click":function($event){_vm.dialogRestore = false}}},[_vm._v("CANCEL")])],1)],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }