import Axios from 'axios'
const state = {
    trips: [],
    meta: {},
    link: {},
    loading: true,
    error: [],
    search: '',
    dialog: '',
    url: '',
    model: '',
    status: '',
    type: '',
    duration: ''
}

const actions = {
    getList ({commit}, pageNumber) {
        commit('setLoading')
        Axios.get(state.url + '?page=' + pageNumber + '&search=' + state.search + '&model=' + state.model + '&status=' + state.status + '&type=' + state.type + '&duration=' + state.duration, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("id_token")
            }
        }).then(response => {
            console.log('State Type: ' + state.type + ' State Status: ' + state.status)
            commit('setTrips', response.data.data.trips)
            commit('setMeta', response.data.meta)
            commit('setLink', response.data.links)
            commit('UnsetLoading')
        }).catch(error => {
            commit('UnsetLoading')
            state.error = error
        })
    },
    UnsetLoader ({commit}) {
        commit('UnsetLoading')
    },
    setLoader ({commit}) {
        commit('setLoading')
    },
    setSearchText ({commit}, search) {
        commit('setSearch', search)
    },
    UnsetSearchText ({commit}) {
        commit('UnsetSearch')
    },
    setStatus ({commit}, status) {
        commit('setStatus', status)
    },
    setCurrentUrl ({commit}, url) {
        commit('setUrl', url)
    },
    DiagSet ({commit}) {
        commit('setDialog')
    },
    Unset_Status ({commit}) {
        commit('UnsetStatus')
    },
    setModelAction ({commit}, model) {
        commit('setModel', model)
    },
    UnsetModelAction ({commit}) {
        commit('UnsetModel')
    },
    setTypeAction ({commit}, type) {
        commit('setType', type)
    },
    UnsetTypeAction ({commit}) {
        commit('UnsetType')
    },
    setDurationAction ({commit}, duration) {
        commit('setDuration', duration)
    },
    UnsetDurationAction ({commit}) {
        commit('UnsetDuration')
    },
    setAllAction ({commit}, data) {
        commit('setModel', data.model)
        commit('setType', data.type)
        commit('setDuration', data.duration)
        commit('setStatus', data.status)
    }
}
const mutations = {
    setTrips (state, payload) {
        state.trips = payload
    },
    setSearch (state, payload) {
        state.search = payload
    },
    UnsetSearch (state) {
        state.search = ''
    },
    setStatus (state, payload) {
        state.status = payload
    },
    setMeta (state, payload) {
        state.meta = payload
    },
    setLink (state, payload) {
        state.link = payload
    },
    setLoading (state) {
        state.loading = true
    },
    UnsetLoading (state) {
        state.loading = false
    },
    setCurrentPage (state, payload) {
        state.meta.current_page = payload
    },
    setDialog (state) {
        state.dialog = !state.dialog
    },
    setUrl (state, payload) {
        state.url = payload
    },
    UnsetStatus (state) {
        state.status = ""
    },
    setModel (state, payload) {
        state.model = payload
    },
    UnsetModel (state) {
        state.model = ""
    },
    setType (state, payload) {
        state.type = payload
    },
    UnsetType (state) {
        state.type = ""
    },
    setDuration (state, payload) {
        state.duration = payload
    },
    UnsetDuration (state) {
        state.duration = ""
    }
}
const getters = {
        getUsersNow: (state) => state.users,
        getLoader: (state) => state.loading,
        getDialog: (state) => state.dialog,
        getUrl: (state) => state.url
}
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
