<template>
    <v-container fluid grid-list-md text-sm-center>
        
        <!-- statistic layout -->
        <tool-bar :color="`white`" :title="`CARDS`"></tool-bar>
        <!-- {{profile}} -->
        <v-slide-y-transition mode="out-in">
            <v-layout row wrap justify="center" class="justify-center">

                <v-flex xs12 sm6 md6 lg4>
                    <widget 
                        title="Signup Users" 
                        :value="sum.allusers" 
                        :status="1" 
                        :type='`allusers`'
                        :model='`User`'
                        color="#0866C6"
                        :hasButton='true'
                    />
                </v-flex>
            
                <v-flex xs12 sm6 md6 lg4>
                    <widget 
                        title="Active Users" 
                        :value="sum.users" 
                        :status="1" 
                        :type='`active`'
                        :model='`User`'
                        color="#00b297"
                        :hasButton='true'
                    />
                </v-flex>

                <v-flex xs12 sm6 md6 lg4>

                    <widget 
                        title="Inactive Users" 
                        :value="sum.usersInactive" 
                        :status="0" 
                        :type='`active`'
                        :model='`User`'
                        color="#1D2939"
                        :hasButton='true'
                    />
                </v-flex>

                <v-flex xs12 sm6 md6 lg4>
                    <widget 
                        title="Verfied Users" 
                        :value="sum.verified_users" 
                        :status="1" 
                        :type='`verified`'
                        :model='`User`'
                        color="#00b297"
                        :hasButton='true'
                    />
                </v-flex>


                <v-flex xs12 sm6 md6 lg4>
                    <widget 
                        title="Un-Verified Users" 
                        :value="sum.unverified_users" 
                        :status="0" 
                        :type='`verified`'
                        :model='`User`'
                        color="#dc3545"
                        :hasButton='true'
                    />
                </v-flex>


                <v-flex xs12 sm6 md6 lg4>
                    <widget 
                        title="All Cars" 
                        :value="sum.allcars" 
                        :status="1" 
                        :type='`allcars`'
                        :model='`Car`'
                        color="#0866C6"
                        :hasButton='true'
                    />
                    
                </v-flex>

                <v-flex xs12 sm6 md6 lg4>
                    <widget 
                        title="Listed Cars" 
                        :value="sum.listedCar" 
                        :status="1" 
                        :type='`active`'
                        :model='`Car`'
                        color="#1D2939"
                        :hasButton='true'
                    />
                </v-flex>

                <v-flex xs12 sm6 md6 lg4>
                    <widget 
                        title="Valid Cars" 
                        :value="sum.valid_car" 
                        :status="1" 
                        :type='`expired`'
                        :model='`Car`'
                        color="#00b297"
                        :hasButton='true'
                    />
                </v-flex>

                <v-flex xs12 sm6 md6 lg4>
                    <widget 
                        title="Expired Cars" 
                        :value="sum.expired_car" 
                        :status="0" 
                        :type='`expired`'
                        :model='`Car`'
                        color="#dc3545"
                        :hasButton='true'
                    />
                </v-flex>

                <v-flex xs12 sm6 md6 lg4>
                    <widget 
                        title="Prospect Cars" 
                        :value="sum.prospectCars" 
                        :status="0" 
                        :type='`active`'
                        :model='`Car`'
                        color="#00b297"
                        :hasButton='true'
                    />
                </v-flex>

                 <v-flex xs12 sm6 md6 lg4>
                     <widget 
                        title="Verified Cars" 
                        :value="sum.verified_cars" 
                        :status="1" 
                        :type='`verified`'
                        :model='`Car`'
                        color="#00b297"
                        :hasButton='true'
                    />
                </v-flex>

                <v-flex xs12 sm6 md6 lg4>
                    <widget 
                        title="Un-Verified Cars" 
                        :value="sum.unverified_cars" 
                        :status="0" 
                        :type='`verified`'
                        :model='`Car`'
                        color="#dc3545"
                        :hasButton='true'
                    />
                </v-flex>



                <v-flex xs12 sm6 md6 lg4>
                    <widget 
                        title="All Trips" 
                        :value="sum.alltrips" 
                        :status="1" 
                        :type='`alltrips`'
                        :model='`Trip`'
                        color="#0866C6"
                        :hasButton='true'
                    />
                </v-flex>




                <v-flex xs12 sm6 md6 lg4>
                    <widget 
                        title="Confirmed Trips" 
                        :value="sum.trips" 
                        :status="1" 
                        :type='`confirmed`'
                        :model='`Trip`'
                        color="#00b297"
                        :hasButton='true'
                    />
                </v-flex>

                <v-flex xs12 sm6 md6 lg4>
                    <widget 
                        title="Canceled Trips" 
                        :value="sum.canceled_trips" 
                        :status="0" 
                        :type='`cancelled`'
                        :model='`Trip`'
                        color="#dc3545"
                        :hasButton='true'
                    />
                </v-flex>

                <v-flex xs12 sm6 md6 lg4>
                    <widget 
                        title="Started Trips" 
                        :value="sum.started_trips" 
                        :status="0" 
                        :type='`started`'
                        :model='`Trip`'
                        color="#0866C6"
                        :hasButton='true'
                    />
                </v-flex>

                <v-flex xs12 sm6 md6 lg4>
                    <widget 
                        title="Waiting Trips" 
                        :value="sum.waiting_trips" 
                        :status="0" 
                        :type='`waiting`'
                        :model='`Trip`'
                        color="#dc3545"
                        :hasButton='true'
                    />
                </v-flex>

                <v-flex xs12 sm6 md6 lg4>
                    <widget 
                        title="Finished Trips" 
                        :value="sum.finished_trips" 
                        :status="2" 
                        :type='`finished`'
                        :model='`Trip`'
                        color="#1D2939"
                        :hasButton='true'
                    />
                </v-flex>

                <v-flex xs12 sm6 md6 lg4>
                    <widget 
                        title="Pending Trips" 
                        :value="sum.pending_trips" 
                        :status="3" 
                        :type='`pending`'
                        :model='`Trip`'
                        color="#dc3545"
                        :hasButton='true'
                    />
                </v-flex>

                <v-flex xs12 sm6 md6 lg4>
                    <widget 
                        title="Unfinished Trips" 
                        :value="sum.unfinished_trips" 
                        :status="0" 
                        :type='`unfinished`'
                        :model='`Trip`'
                        color="#dc3545"
                        :hasButton='true'
                    />
                </v-flex>

                <v-flex xs12 sm6 md6 lg4>
                    <widget 
                        title="Active Owners" 
                        :value="sum.owners"
                        color="#dc3545"
                        :hasButton='false'
                    />
                    
                </v-flex>

                <v-flex xs12 sm6 md6 lg4>
                    <widget 
                        title="Trips in last 24 hours" 
                        :value="sum.last_day"
                        color="#dc3545"
                        :hasButton='false'
                    />
                    
                </v-flex>

                <v-flex xs12 sm6 md6 lg4>
                    <widget 
                        title="Trips in last 30 days" 
                        :value="sum.last_month"
                        color="#dc3545"
                        :hasButton='false'
                    />
                </v-flex>
            </v-layout>
        </v-slide-y-transition>
        <v-divider></v-divider>
        <br/>
        <br/>
        <tool-bar :color="`grey`" :title="`CHARTS`"></tool-bar>
        <v-spacer></v-spacer>
        <v-slide-y-reverse-transition mode="out-in">
            <v-layout row wrap justify="center" class="justify-center">
                <v-flex lg4 sm6 xs12>
                    <site-view-statistic :title="`Active Against Inactive Users`" :color="['#d11', '#112', '#23f']" :data="{'All':sum.allusers, 'active':sum.users, 'inactive':sum.usersInactive}"/>
                </v-flex>
                <v-flex lg4 sm6 xs12>
                    <site-view-statistic :title="`Verified Against Unverified Users`" :color="['#d11', '#112', '#23f']" :data="{'All':sum.allusers, 'Unverified':sum.unverified_users, 'Verified Users':sum.verified_users}"/>
                </v-flex>
                <v-flex lg4 sm6 xs12>
                    <site-view-statistic :title="`Listed Against Prospect Cars`" :color="['#d11', '#112', '#23f']" :data="{'All':sum.allcars, 'Listed':sum.listedCar, 'Prospect':sum.prospectCars}"/>
                </v-flex>
                <v-flex lg4 sm6 xs12>
                    <site-view-statistic :title="`Verified Against Unverified Cars`" :color="['#d11', '#112', '#23f']" :data="{'All':sum.allcars, 'Verified':sum.verified_cars, 'Unverified':sum.unverified_cars}"/>
                </v-flex>
                <!-- <v-flex lg4 sm6 xs12>
                    <location-statistic/>
                </v-flex> -->
                <v-flex lg4 sm6 xs12>
                    <total-earnings-statistic :title="`Car Trend`" :color="['#d11']" :data="cars_trend"/>
                </v-flex>
                <v-flex lg4 sm6 xs12>
                    <total-earnings-statistic :title="`User Trend`" :color="[ '#23f']" :data="users_trend"/>
                </v-flex>

                <!-- <v-flex lg4 sm6 xs12>
                    <multiple-chart-graph/>
                </v-flex> -->
            </v-layout>
        </v-slide-y-reverse-transition>

        <v-divider></v-divider>

        <!-- table with number of cars in cities layout -->
        <v-slide-y-transition mode="out-in">
            <v-layout row wrap justify-center mt-4>
                <v-flex sm6>
                    <v-card  elevation="2"
                        outlined
                        shaped>
                        <v-card-title>
                            <v-btn @click.stop="getReportsCars()" outlined type="div" color="primary" :loading="loading">
                                CARS LISTING
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-text-field
                                v-model="search"
                                append-icon="search"
                                label="Search"
                                single-line
                                hide-details
                            >
                            </v-text-field>
                        </v-card-title>
                        <v-data-table
                            :headers="headers"
                            :items="cars"
                            :search="search"
                        >
                            <template slot="items" slot-scope="props">
                                <tr class="tr-click" @click.stop="props.expanded = !props.expanded">
                                    <td class="text-xs-left">{{ props.item.city }}</td>
                                    <td class="text-xs-left">{{ props.item.cars }}</td>
                                </tr>
                            </template>
                            <v-alert slot="no-results" :value="true" color="error" icon="warning">
                                Your search for "{{ search }}" found no results.
                            </v-alert>
                        </v-data-table>
                        <v-progress-linear v-if="loading" :indeterminate="true"></v-progress-linear>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-slide-y-transition>
    </v-container>
</template>

<script>
    import activeUsers from './popups/ActiveUsers'
    import activeCars from './popups/ActiveCars'
    import trips from './popups/trips'
    import dropdown from './popups/generalDropdown'
    import widget from './commonComponents/Cardwidget'
    import LocationStatistic from '../components/commonComponents/Chart';
    import SiteViewStatistic from '../components/commonComponents/ChartWidget';
    import TotalEarningsStatistic from '../components/commonComponents/EarningChart';
    import MultipleChartGraph from '../components/commonComponents/MultipleChartGraph';
    import ToolBar from '../components/commonComponents/ToolBar';

    export default {
        props:{
            profile: {
                type: Object,
                required: false
            }
        },
        components: {
            activeUsers,
            activeCars,
            trips,
            dropdown,
            widget,
            LocationStatistic,
            SiteViewStatistic,
            TotalEarningsStatistic,
            MultipleChartGraph,
            ToolBar
		},
        data () {
            return {
                cars_trend:'',
                users_trend:'',
                tripStatus: '',
                carStatus: '', // set status for active and inactive including verified and unverified cars
                userStatus: '', // set status for active and inactive including verified and unverified users
                type: '', // Set URL for active or inactive alone. Set empty to fulfill righteousness
                search: '',
                loading: false,
                sum: [],
                cars: [],
                users: [],
                vehicle: [],
                headers: [
                    {
                        text: 'CITY',
                        align: 'left',
                        sortable: true,
                        value: 'city'
                    },
                    { text: 'Number of Cars', value: 'cars' }
                ]
            }
        },
        methods: {
			loadingDialog () {
				return this.$store.getters['users/getDialog']
            },
            loadActiveUsers (type) {
                console.log(type)
                this.$store.dispatch('users/DiagSet')
                this.ActiveUsers()
                this.type = type
                // console.log('User Status' + this.userStatus)
                // console.log('User Type active: ' + this.type)
                // console.log('User Type Inactive: ' + type)
            },
            loadAllUsers (type) {
                console.log(type)
                this.$store.dispatch('users/DiagSet')
                this.AllUsers()
                this.type = type
                // console.log('User Status' + this.userStatus)
                // console.log('User Type active: ' + this.type)
                // console.log('User Type Inactive: ' + type)
            },
            loadInActiveUsers (type) {
                this.$store.dispatch('users/DiagSet')
                this.InActiveUsers(type)
                this.type = type
                // console.log('User Status Inactive: ' + this.userStatus)
                // console.log('User Type Inactive: ' + this.type)
            },
            loadingDialogCars () {
				return this.$store.getters['cars/getDialog']
            },
            loadActiveCars (type) {
                this.$store.dispatch('cars/DiagSet')
                this.ActiveCars()
                this.type = type
            },
            loadInActiveCars (type) {
                this.$store.dispatch('cars/DiagSet')
                this.InActiveCars()
                this.type = type
            },
            loadingDialogTrip () {
				return this.$store.getters['trips/getDialog']
            },
            loadTrips (value, type) {
                this.$store.dispatch('trips/DiagSet')
                this.Trips(value)
                this.type = type
            },
            getReportsSum () {
                this.loading = true
                this.axios.get('/report/sum', {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("id_token")
                    }
                })
                .then(response => {
                    this.sum = response.data
                    // console.log(this.sum)
                    // console.log(this.sum.users_list)
                    let list_users = {}
                    this.sum.users_list.forEach(function (value) {
                        // console.log(value.Count)
                        let key = value.months+'-'+value.year
                        list_users[key] = value.Count
                    });
                    this.users_trend = list_users
                    console.log(this.users_trend)


                    let list_cars = {}
                    this.sum.cars_list.forEach(function (value) {
                        // console.log(value.Count)
                        let key = value.months+'-'+value.year
                        list_cars[key] = value.Count
                    });
                    this.cars_trend = list_cars
                    console.log(this.cars_trend)
                    this.loading = false
                })
                .catch(error => {
                    console.log(error)
                    this.loading = false
                })
            },

            getReportsCars () {
                this.loading = true
                this.axios.get('/report/cars', {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("id_token")
                    }
                })
                .then(response => {
                    this.cars = response.data
                    // console.log(this.cars)
                    this.loading = false
                })
                .catch(error => {
                    console.log(error)
                    this.loading = false
                })
            },
            ActiveCars () {
                this.carStatus = 1
            },
            InActiveCars () {
                this.carStatus = 0
            },
            ActiveUsers () {
                this.userStatus = 1
            },
            InActiveUsers () {
                this.userStatus = 0
            },
            Trips (value) {
                this.tripStatus = value
            }
        },
        created () {
            this.getReportsSum()
            this.getReportsCars()
            // this.$bus.$on("UnverifiedCars", (numberOfunverifiedCars) => {
            //     console.log("dsadas")
            // })
        }
//  beforeDestroy () {
//     this.$bus.$off("UnverifiedCars")
//   }
    }
</script>

<style scoped>
    .card__title {
        justify-content: center;
    }

    .sum-numbers {
        color: #fdb633;
        font-size: 20px;
    }
</style>