<template>
    <v-container >
		<v-slide-y-transition mode="out-in">
			<v-layout column md12 sm12 lg12>
				<v-data-table
					:headers="headers"
					:items="desserts"
					sort-by="name"
					class="elevation-1"
				>
					<template v-slot:top>
					<v-toolbar
						flat
					>
						<v-toolbar-title>ROLE MANAGEMENT</v-toolbar-title>
						<v-divider
						class="mx-4"
						inset
						vertical
						></v-divider>
						<v-spacer></v-spacer>
						<v-dialog
						v-model="dialog"
						max-width="500px"
						>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
							color="primary"
							dark
							class="mb-2"
							v-bind="attrs"
							v-on="on"
							>
							ADD ROLE
							</v-btn>
						</template>
						<v-card>
							<v-card-title>
							<span class="headline">{{ formTitle }}</span>
							</v-card-title>

							<v-card-text>
							<v-container>
								<validation-observer
									ref="observer"
									v-slot="{ invalid }"
								>
									<form @submit.prevent="editedItem.id != 0 ? updateRecord(editedItem) : submit()">
									<validation-provider
										v-slot="{ errors }"
										name="Name"
										rules="required|max:100"
									>
										<v-text-field
										v-model="editedItem.name"
										:counter="100"
										:error-messages="errors"
										label="Name"
										required
										></v-text-field>
									</validation-provider>
									
									<validation-provider
										v-slot="{ errors }"
										name="description"
										rules="required"
									>
										<v-text-field
										v-model="editedItem.description"
										:error-messages="errors"
										label="Role Description"
										required
										></v-text-field>
									</validation-provider>
									

                                    <validation-provider
										v-slot="{ errors }"
										name="level"
										rules="required"
									>
										<v-select
										v-model="editedItem.level"
										:items="items"
										:error-messages="errors"
										label="Select role level"
										data-vv-name="level"
										required
										></v-select>
									</validation-provider>


									<v-btn
										class="mr-4"
										type="submit"
										:disabled="invalid"
									>
										submit
									</v-btn>
									<v-btn @click="clear">
										clear
									</v-btn>
									</form>
								</validation-observer>
							</v-container>
							</v-card-text>
						</v-card>

						</v-dialog>
						<!-- <v-dialog v-model="dialogDelete" max-width="500px">
							<v-card>
								<v-card-title class="headline">Are you sure you want to delete this item?</v-card-title>
								<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
								<v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
								<v-spacer></v-spacer>
								</v-card-actions>
							</v-card>
						</v-dialog> -->
					</v-toolbar>
					</template>
					<template v-slot:item.actions="{ item }">
					<v-icon
						small
						class="mr-2"
						@click="editItem(item)"
					>
						mdi-pencil
					</v-icon>
					<!-- <v-icon
						small
						@click="deleteItem(item)"
					>
						mdi-delete
					</v-icon> -->
					</template>
					<template v-slot:no-data>
					<v-btn
						color="primary"
						@click="initialize"
					>
						Reset
					</v-btn>
					</template>
				</v-data-table>

				
			</v-layout>
		</v-slide-y-transition>
	</v-container>
</template>

<script>
  import { required, digits, email, max, regex } from 'vee-validate/dist/rules'
  import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
  import Axios from 'axios'

  setInteractionMode('eager')

  extend('digits', {
    ...digits,
    message: '{_field_} needs to be {length} digits. ({_value_})',
  })

  extend('required', {
    ...required,
    message: '{_field_} can not be empty',
  })

  extend('max', {
    ...max,
    message: '{_field_} may not be greater than {length} characters',
  })

  extend('regex', {
    ...regex,
    message: '{_field_} {_value_} does not match {regex}',
  })
  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    data: () => ({
	  sticky: false,
	  
	  
	  // Table start
      dialog: false,
      dialogDelete: false,
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Description', value: 'description' },
        { text: 'Level', value: 'level' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      items: [
        1, 2, 3, 4, 5, 6, 7, 8, 9
	  ],
      desserts: [],
      editedIndex: -1,
      editedItem: {
		id: 0,
        name: '',
        description: '',
        level: ''
      },
      defaultItem: {
        id: 0,
        name: '',
        description: '',
        level: ''
      },
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Add New Role' : 'Edit Role'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
	  this.initialize()
	  this.getAllRoles()
    },


    methods: {
	  	getAllRoles(){
		  	Axios.get('/getRoles', {
				headers: {
					Authorization: "Bearer " + localStorage.getItem("id_token")
				}
		  	}).then(response => {
				// console.log("rxfghjikbggvhkgk"+ response.data.data.map(user => ({user})))
				this.desserts = response.data.data.map(role => (
                    {
                        "id": role.id, 
                        "name": role.name, 
                        "description": role.description,
                        "level": role.level
                    }
                ))
				console.log(this.desserts)
				// this.desserts.push()

			}).catch(error => {
			})
	  },
      submit () {
		this.$refs.observer.validate()
		let formData = {
			name: this.editedItem.name,
            description: this.editedItem.description,
            level: this.editedItem.level
        }
        
		Axios.post('/role', formData, {
			headers: {
				Authorization: "Bearer " + localStorage.getItem("id_token")
			}
		}).then(response => {
			if(response.data.status){
				this.$swal(response.data.message);
			}else{
				this.$swal(response.data.message);
			}
            this.dialog = false
			// this.clear()
		}).catch(error => {
		})
		this.getAllRoles()
      },
      clear () {
        this.editedItem.name = ''
        this.editedItem.description = ''
        this.editedItem.level = ''
        this.$refs.observer.reset()
	  },
	  initialize () {
        this.desserts = [
          {
            name: 'loading...',
            description: 'loading...',
            level: 'loading...'
          }
         
        ]
      },

      editItem (item) {
		  
        this.editedIndex = this.desserts.indexOf(item)
		this.editedItem = Object.assign({}, item)
		console.log(this.editedItem)
        this.dialog = true
	  },
	  
	  updateRecord(item)
	  {
		  console.log(item)
		  let formData = {
			name: item.name,
			description: item.description,
			level: item.level
		}
		Axios.post('/EditRole/'+item.id, formData, {
			headers: {
				Authorization: "Bearer " + localStorage.getItem("id_token")
			}
		}).then(response => {
            this.$swal('Role successfully Updated');
            this.dialog = false
			this.getAllRoles()
		}).catch(error => {
		})
	  },

    //   deleteItem (item) {
	// 	  Axios.post('/deleteAdmin/'+item.id, {}, {
	// 		headers: {
	// 			Authorization: "Bearer " + localStorage.getItem("id_token")
	// 		}
	// 	}).then(response => {
    //         this.$swal('Successfully Deleted');
    //         this.dialog = false
	// 		// this.clear()
	// 	}).catch(error => {
	// 	})
    //     this.editedIndex = this.desserts.indexOf(item)
    //     this.editedItem = Object.assign({}, item)
    //     this.dialogDelete = true
    //   },

    //   deleteItemConfirm () {
    //     this.desserts.splice(this.editedIndex, 1)
    //     this.closeDelete()
    //   },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.desserts[this.editedIndex], this.editedItem)
        } else {
          this.desserts.push(this.editedItem)
        }
        this.close()
      },
    },
  }
</script>