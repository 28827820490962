<template>
    <v-container fluid grid-list-md style="overflow: auto;">
        <v-slide-y-transition mode="out-in">
            <v-layout fill-height>
                <v-card>
                    <v-card-title>
                        <v-flex xs6 sm6 md3>
                        <v-btn @click="getEmails()" outline type="div" color="primary" :loading="loadingStatus()">
                            CALCULATOR
                        </v-btn>
                        </v-flex>
						
						<v-flex xs4>
							<v-md-date-range-picker
								show-year-select
								max-year="2030"
    							min-year="2010"
								@change="handleChange"
							>

							</v-md-date-range-picker>
						</v-flex>
						

						<v-flex xs12 sm6 md4>
                        <v-text-field
                                v-model="search"
                                append-icon="search"
                                label="Search"
                                single-line
                                hide-details
                        >
                        </v-text-field>
						</v-flex>
                    </v-card-title>
                    <v-data-table
                            :headers="headers"
                            :items="emails"
                            :loading="loadingStatus()"
                            :pagination.sync="pagination"
                            hide-default-footer="false"
                            :hideActions=true
                            rows-per-page-text=""
                            :rows-per-page-items="[10, 20, 30, 50]"
                            :footer-props="{ disablePagination : false, disableItemsPerPage : false }"
                    >
                        <template slot="items" slot-scope="props">
                            <tr class="tr-click">
                                <td>{{ props.item.id }}</td>
                                <td>{{ props.item.email }}</td>
                                <td>{{ props.item.phone }}</td>
                                <td>{{ props.item.carManufacturer }}</td>
                                <td>{{ props.item.carModel }}</td>
                                <td>{{ props.item.productionYear }}</td>
                                <td>{{ props.item.carValue }}</td>
                                <td>{{ props.item.class }}</td>
                                <td>{{ props.item.dailyPrice }}</td>
                                <td>{{ props.item.yearlyPrice }}</td>
                                <td>{{ props.item.created }}</td>
                            </tr>
                        </template>
                        <!-- alert if no results -->
                        <v-alert slot="no-results" :value="true" color="error" icon="warning">
                            Your search for "{{ search }}" found no results.
                        </v-alert>
                    </v-data-table>
                    <v-progress-linear v-if="loadingStatus()" :indeterminate="true"></v-progress-linear>
                    <paginate store="calculator" collection="meta"/>
                </v-card>

            </v-layout>
        </v-slide-y-transition>
    </v-container>
</template>

<script>
    import paginate from './paginate'
    export default {
        components: {
			paginate
		},
        name: "Calculator",
        data () {
            return {
                dates: [],
                search: '',
                loading: false,
                dialogDelete: false,
                dialogRestore: false,
                selectedItem: null,
                pagination: {
                    sortBy: 'id',
					rowsPerPage: 10
				},
                headers: [
                    {
                        text: 'ID',
                        align: 'left',
                        sortable: true,
                        value: 'id'
                    },
                    { text: 'Email', value: 'email' },
                    { text: 'Phone', value: 'phone' },
                    { text: 'Manufacturer', value: 'carManufacturer' },
                    { text: 'Model', value: 'carModel' },
                    { text: 'Year', value: 'productionYear' },
                    { text: 'Value', value: 'carValue' },
                    { text: 'Class', value: 'class' },
                    { text: 'Daily Price', value: 'dailyPrice' },
                    { text: 'Yearly Price', value: 'yearlyPrice' },
                    { text: 'Created', value: 'created' }
                ]
            }
        },
        computed: {
			emails: {
				get () {
					return this.$store.state.calculator.emails
				}
			},
			pages () {
				if (this.pagination.rowsPerPage == null ||
					this.pagination.totalItems == null
				) return 0

				return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
			}
		},
		watch: {
			loading (newVal, oldVal) {
				this.loading = this.loadingStatus()
			},
			search (newVal, oldVal) {
                this.$store.dispatch('calculator/setCurrentUrl', '/calculator')
				this.$store.dispatch('calculator/setSearchText', newVal)
				this.$store.dispatch('calculator/getList', 1).then(response => {
					this.loading = this.$store.getters['calculator/getLoader']
				})
			},
			dates (newVal, oldVal) {
				this.$store.dispatch('calculator/setCurrentUrl', '/calculator')
				this.$store.dispatch('calculator/setDateRangeNow', newVal)
				this.$store.dispatch('calculator/getList', 1).then(response => {
					this.loading = this.$store.getters['calculator/getLoader']
				})
			}
        },
        methods: {
            getEmails () {
                this.$store.dispatch('calculator/Unset_Date_range')
                this.$store.dispatch('calculator/setCurrentUrl', '/calculator')
                this.$store.dispatch('calculator/getList', 1).then(response => {
					this.loading = this.$store.getters['calculator/getLoader']
				})
                if (this.emails.length > 0) {
					this.loading = this.$store.getters['calculator/getLoader']
					this.emails = this.emails
                } else {
                    this.loading = this.$store.getters['calculator/getLoader']
                }
            },
            loadingStatus () {
				return this.$store.getters['calculator/getLoader']
			},
			handleChange (dates) {
				this.dates.splice(0, this.dates.length)
				for (let i = 0; i < dates.length; i++) {
					this.dates.push(dates[i].format("YYYY-MM-DD"))
				}
			}
        },
        mounted () {
            this.getEmails()
        },
        created () {
            this.$store.dispatch('calculator/setCurrentUrl', '/calculator')
            this.$store.dispatch('calculator/getList', 1).then(response => {
				this.loading = this.$store.getters['calculator/getLoader']
			})
        }
    }
</script>

<style scoped>

</style>
