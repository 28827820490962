<template>
<v-container>
    
    <v-layout row justify-center>
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      
      <v-card>
          
        <v-toolbar dark color="grey">
            <v-btn icon dark @click="closeDialog()">
                <v-icon>close</v-icon>
            </v-btn>
            <v-toolbar-title>{{title}}</v-toolbar-title>
        </v-toolbar>
        <v-card-title>
			<v-btn @click="getCars()" outline type="div" id="open" color="primary" :loading="loadingStatus()">
				CARS LISTING
			</v-btn>

            <v-layout row wrap justify-center mb-4>
                <v-flex xs3 sm>
                    <v-card>
                        <v-card-title class="text-xs-center" primary-title>
							
                            <div >
                                <h3 class="headline mb-0">Active</h3>
                                <div class="sum-numbers">{{data.active | toNumberFormat}}</div>
                            </div>
                        </v-card-title>
                    </v-card>
                </v-flex>

                <v-flex xs3 sm>
                    <v-card>
                        <v-card-title primary-title>
                            <div>
                                <h3 class="headline mb-0">Daily</h3>
                                <div class="sum-numbers">{{data.daily | toNumberFormat}}</div>
                            </div>
                        </v-card-title>
                    </v-card>
                </v-flex>
                <v-flex xs3 sm>
                    <v-card>
                        <v-card-title primary-title>
                            <div>
                                <h3 class="headline mb-0">Weekly</h3>
                                <div class="sum-numbers">{{data.weekly | toNumberFormat}}</div>
                            </div>
                        </v-card-title>
                    </v-card>
                </v-flex>
                <v-flex xs6 sm>
                    <v-card>
                        <v-card-title primary-title>
                            <div>
                                <h3 class="headline mb-0">Monthly</h3>
                                <div class="sum-numbers">{{data.monthly | toNumberFormat}}</div>
                            </div>
                        </v-card-title>
                    </v-card>
                </v-flex>
                <v-flex xs3 sm>
                    <v-card>
                        <v-card-title primary-title>
                            <div>
                                <h3 class="headline mb-0">Yearly</h3>
                                <div class="sum-numbers">{{data.yearly | toNumberFormat}}</div>
                            </div>
                        </v-card-title>
                    </v-card>
                </v-flex>
            
            </v-layout>
            <v-flex xs12 md12 lg12 sm>
			<v-text-field
			    v-model="search"
				append-icon="search"
				label="Search"
			    single-line
				hide-details
			>
			</v-text-field>
            </v-flex>
			</v-card-title>
            

			    <v-data-table
						:headers="headers"
						:items="cars"
						:loading="loadingStatus()"
						:pagination.sync="pagination"
						hide-default-footer="false"
						:hideActions=true
						:footer-props="{ disablePagination : false, disableItemsPerPage : false }"
						>
						<template slot="items" slot-scope="props">
							<tr class="tr-click">
								<td @click="props.expanded = !props.expanded">{{ props.item.id }}</td>
								<td @click="props.expanded = !props.expanded">{{ `${props.item.owner.first_name}  ${props.item.owner.last_name}` }}</td>
								<td @click="props.expanded = !props.expanded" class="text-xs-left">{{ props.item.car_manufacturer }}</td>
								<td @click="props.expanded = !props.expanded" class="text-xs-left">{{ props.item.car_model }}</td>
								<!-- <td @click="props.expanded = !props.expanded" class="text-xs-left">{{ props.item.trim }}</td>
								<td @click="props.expanded = !props.expanded" class="text-xs-left">{{ props.item.style }}</td>
								<td @click="props.expanded = !props.expanded" class="text-xs-left">{{ props.item.transmission }}</td> -->
								<td @click="props.expanded = !props.expanded" class="text-xs-left">{{ props.item.production_year }}</td>
								<td @click="props.expanded = !props.expanded" class="text-xs-left">{{ props.item.car_city }}</td>
								<td @click="props.expanded = !props.expanded" class="text-xs-left">{{ props.item.active ? "Yes" : "No" }}</td>
								<td
									v-if="props.item.active === 'Yes'"
									class="text-xs-left">
									<v-btn
										@click="showDeleteDialog(props.item.id)"
										color="error">
										DELETE
									</v-btn>
								</td>
								<td
									v-else
									class="text-xs-left">
									<v-btn
										@click="showRestoreDialog(props.item.id)"
										color="success">
										RESTORE
									</v-btn>
								</td>
								<td @click="props.expanded = !props.expanded" class="text-xs-left">
									<v-icon v-if="props.item.verified_insurance === 1 && props.item.verified_registration === 1 " color="green">check_circle</v-icon>
									<v-icon v-else color="red">highlight_off</v-icon>
								</td>
							</tr>
						</template>
						<!-- expanding component -->
						<template slot="expand" slot-scope="props">
							<selected-car :carId="props.item.id"></selected-car>
						</template>
						<!-- alert if no results -->
						<v-alert slot="no-results" :value="true" color="error" icon="warning">
							Your search for "{{ search }}" found no results.
						</v-alert>
					</v-data-table>
					
					<paginate store="cars" collection="meta"/>
                    <v-progress-linear v-if="loadingStatus()" :indeterminate="true"></v-progress-linear>
				

				<!-- delete dialog -->
				<v-dialog v-model="dialogDelete" v-if="selectedItem" persistent max-width="290">
					<v-card>
						<v-card-title class="headline">Are you sure?</v-card-title>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="info" @click.native="dialogDelete = false; deleteCar(selectedItem)">DELETE</v-btn>
							<v-btn color="error" @click.native="dialogDelete = false">CANCEL</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
				<!-- end of delete dialog-->

				<!-- restore dialog -->
				<v-dialog v-model="dialogRestore" v-if="selectedItem" persistent max-width="290">
					<v-card>
						<v-card-title class="headline">Are you sure?</v-card-title>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="info" @click.native="dialogRestore = false; restoreCar(selectedItem)">RESTORE</v-btn>
							<v-btn color="error" @click.native="dialogRestore = false">CANCEL</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
				<!-- end of restore dialog-->
				</v-card>
    		</v-dialog>
        </v-layout>
    </v-container>
</template>
<script>
    import paginate from '../paginate'
	export default {
		props: {
			status,
			type: {type: String}
		},
		components: {
			paginate
		},
		data () {
			return {
				data: '',
				dialog: false,
				dates: [],
				userFirstLastName: [],
                search: '',
                loading: false,
                dialogDelete: false,
                dialogRestore: false,
				selectedItem: null,
				pagination: {
					sortBy: 'id',
					rowsPerPage: 10
				},
				headers: [
					{
					text: 'CAR ID',
					align: 'left',
					sortable: true,
					value: 'id'
					},
					{ text: 'Owner Name', value: 'ownerName' },
					{ text: 'Manufacturer', value: 'car_manufacturer' },
					{ text: 'Model', value: 'car_model' },
					{ text: 'Year', value: 'production_year' },
					{ text: 'City', value: 'car_city' },
					{ text: 'Active', value: 'active' },
                    { text: 'Delete/Restore', value: 'delete_restore' },
                    { text: 'Verified', value: 'Verified' }
				],
				ownerName: []
			}
		},
		computed: {
			cars: {
				get () {
					return this.$store.state.cars.cars
				}
			},
			pages () {
				if (this.pagination.rowsPerPage == null ||
					this.pagination.totalItems == null
				) return 0

				return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
			},
			title: {
				get () {
					let text = this.type === "active" ? 'Active' : 'Verified'
					let status = this.status === 1 ? '' : this.type === "active" ? 'In-' : 'Un-'
					return status + text + ' Cars'
				}
			}
		},
		watch: {
			loading (newVal, oldVal) {
				this.loading = this.loadingStatus()
			},
			// data (newVal, oldVal) {
			// 	this.data = this.cars
			// },
			search (newVal, oldVal) {
				this.$store.dispatch('cars/setSearchText', newVal)
				if (this.type === "active") {
					this.$store.dispatch('cars/UnsetVerifiedAction')
					this.$store.dispatch('cars/setActiveAction', this.status)
				} else if (this.type === "verified") {
					this.$store.dispatch('cars/UnsetActiveAction')
					this.$store.dispatch('cars/setVerifiedAction', this.status)
				}
				this.$store.dispatch('cars/setCurrentUrl', '/cars')
				this.$store.dispatch('cars/getList', 1)
			},
			dates (newVal, oldVal) {
				if (this.type === "active") {
					this.$store.dispatch('cars/UnsetVerifiedAction')
					this.$store.dispatch('cars/setActiveAction', this.status)
				} else if (this.type === "verified") {
					this.$store.dispatch('cars/UnsetActiveAction')
					this.$store.dispatch('cars/setVerifiedAction', this.status)
				}
				this.$store.dispatch('cars/setCurrentUrl', '/cars')
				this.$store.dispatch('cars/setDateRangeNow', newVal)
				this.$store.dispatch('cars/getList', 1).then(response => {
					this.loading = this.$store.getters['cars/getLoader']
				})
			}
		},
		created () {
			this.getCounts()
			this.$store.dispatch('cars/setCurrentUrl', '/cars')
			this.$store.dispatch('cars/getList', 1).then(response => {
				this.loading = this.$store.getters['cars/getLoader']
			})
			this.dialog = this.$store.getters['cars/getDialog']
		},
		methods: {
			closeDialog () {
				this.$store.dispatch('cars/DiagSet')
			},
			getCars () {
				if (this.type === "active") {
					this.$store.dispatch('cars/UnsetVerifiedAction')
					this.$store.dispatch('cars/setActiveAction', this.status)
				} else if (this.type === "verified") {
					this.$store.dispatch('cars/UnsetActiveAction')
					this.$store.dispatch('cars/setVerifiedAction', this.status)
				}
				this.$store.dispatch('cars/Unset_Date_range')
				this.$store.dispatch('cars/setCurrentUrl', '/cars')
				this.$store.dispatch('cars/getList', 1).then(response => {
					this.loading = this.$store.getters['cars/getLoader']
				})
				if (this.cars.length > 0) {
					this.userFirstLastName = []
					this.cars.forEach(elem => {
						this.userFirstLastName.push(elem)
					})
					this.loading = this.$store.getters['cars/getLoader']
				} else {
					this.loading = this.$store.getters['cars/getLoader']
				}
			},
			loadingStatus () {
				return this.$store.getters['cars/getLoader']
			},
			getCounts () {
                this.loading = true
                this.axios.get('/popUsers?model=Car&status=' + this.status + '&type=' + this.type,
				{
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("id_token")
                    }
                })
				.then(response => {
                    this.loading = false
                    console.log(response.data)
                    this.data = response.data
				})
				.catch(error => {
					console.log(error)
					this.loading = false
				})
            },
            deleteCar (id) {
                this.loading = true
                this.axios.post('/cars/delete/' + id, {},
				{
					headers: {
						Authorization: "Bearer " + localStorage.getItem("id_token")
					}
				})
				.then(response => {
					this.loading = false
					this.getCars()
				})
				.catch(error => {
					console.log(error)
					this.loading = false
				})
            },

            restoreCar (id) {
                this.loading = true
                this.axios.post('/cars/restore/' + id, {},
				{
					headers: {
						Authorization: "Bearer " + localStorage.getItem("id_token")
					}
				})
				.then(response => {
					this.loading = false
					this.getCars()
				})
				.catch(error => {
					console.log(error)
					this.loading = false
				})
            },
            showDeleteDialog (value) {
                this.dialogDelete = true
                this.selectedItem = value
            },
            showRestoreDialog (value) {
                this.dialogRestore = true
                this.selectedItem = value
            },
			handleChange (dates) {
				this.dates.splice(0, this.dates.length)
				for (let i = 0; i < dates.length; i++) {
					this.dates.push(dates[i].format("YYYY-MM-DD"))
				}
			}
		},
        filters: {
            toNumberFormat (value) {
                value = value > 0 ? value : 0
                return `${value.toLocaleString()}`
            }
        },
		mounted () {
			// this.getCars()
		}
	}
</script>
<style>
	.tr-click {
		cursor: pointer;
	}
</style>
