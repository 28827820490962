<template>
	<v-container fluid grid-list-md style="overflow: auto;">
		<v-slide-y-transition mode="out-in">
			<v-layout wrap fill-height>
				 <v-card>
					<v-card-title>
						<v-flex xs6 sm6 md3>
						<v-btn @click="getCars()" outlined type="div" id="open" color="primary" :loading="loadingStatus()">
							CARS LISTING
						</v-btn>
						</v-flex>
						
						<v-flex xs4>
							<v-md-date-range-picker
								show-year-select
								max-year="2030"
    							min-year="2010"
								@change="handleChange"
							>

							</v-md-date-range-picker>
						</v-flex>
						

						<v-flex xs12 sm6 md4>
						<v-text-field
							v-model="search"
							append-icon="search"
							label="Search"
							single-line
							hide-details
							>
						</v-text-field>
						</v-flex>

						<v-spacer></v-spacer>
						<vueBtn 
							:json_data = "cars"
							:param="{
								model: 'Car',
								search: search,
								type: dates,
								status: null,
								duration:'Total',
								url: '/report/getCountsRecordsExport',
								filename: 'Total' + '_Cars',
								date_range: dates
							}"
							:fields="headerfileExport"
							:fieldsPDF="headerfileExportPDF"
						/>
					</v-card-title>
					<v-data-table
						:headers="headers"
						:items="cars"
						:loading="loadingStatus()"
						:hide-default-footer=false
						:hideActions=true
						:footer-props="{ disablePagination : false, disableItemsPerPage : false }"
						:single-expand="singleExpand"
						:expanded.sync="expanded"
						show-expand
					>
					<template v-slot:item.ownerName="{ item }">
						{{ `${item.owner.first_name}  ${item.owner.last_name}` }}
					</template>
					<template v-slot:item.created_at="{ item }">
						{{ `${item.created_at}` }}
					</template>
					<template v-slot:item.ownerId="{ item }">
						{{ `${item.owner.id}` }}
					</template>
					<template v-slot:item.active="{ item }">
						{{ item.active ? "Yes" : "No" }}
					</template>

					<template v-slot:item.delete_restore="{ item }">
						<div
							v-if="item.active === 1"
							class="text-xs-left">
							<v-icon
								@click="showDeleteDialog(item.id)"
								color="error"
							>
								mdi-delete
							</v-icon>
						</div>
						<div
							v-else
							class="text-xs-left"
						>
							<v-icon
								@click="showRestoreDialog(item.id)"
								color="success"
							>
							mdi-check
							</v-icon>
						</div>
					</template>
					<template v-slot:item.Verified="{ item }">
						<v-icon v-if="item.verified_insurance === 1 && item.verified_registration === 1 " color="green">check_circle</v-icon>
						<v-icon v-else color="red">highlight_off</v-icon>
					</template>
					<template v-slot:expanded-item="{ headers, item }">
						<td :colspan="headers.length">
							<selected-car :carId="item.id"></selected-car>
						</td>
					</template>
						<!-- <template slot="items" slot-scope="props">
							<tr class="tr-click">
								<td @click="props.expanded = !props.expanded">{{ props.item.id }}</td>
								<td @click="props.expanded = !props.expanded" class="text-xs-left">{{ props.item.car_manufacturer }}</td>
								<td @click="props.expanded = !props.expanded" class="text-xs-left">{{ props.item.car_model }}</td>
								<td @click="props.expanded = !props.expanded" class="text-xs-left">{{ props.item.production_year }}</td>
								<td @click="props.expanded = !props.expanded" class="text-xs-left">{{ props.item.car_city }}</td>
								<td @click="props.expanded = !props.expanded" class="text-xs-left">{{ props.item.active ? "Yes" : "No" }}</td>
								<td @click="props.expanded = !props.expanded">{{ `${props.item.owner.first_name}  ${props.item.owner.last_name}` }}</td>
								<td @click="props.expanded = !props.expanded">{{ `${props.item.created_at}` }}</td>
								 -->
								<!-- <td @click="props.expanded = !props.expanded" class="text-xs-left">{{ props.item.trim }}</td>
								<td @click="props.expanded = !props.expanded" class="text-xs-left">{{ props.item.style }}</td>
								<td @click="props.expanded = !props.expanded" class="text-xs-left">{{ props.item.transmission }}</td> -->
								<!-- <td
									v-if="props.item.active === 'Yes'"
									class="text-xs-left">
									<v-btn
										@click="showDeleteDialog(props.item.id)"
										color="error">
										DELETE
									</v-btn>
								</td>
								<td
									v-else
									class="text-xs-left">
									<v-btn
										@click="showRestoreDialog(props.item.id)"
										color="success">
										RESTORE
									</v-btn>
								</td>
								<td @click="props.expanded = !props.expanded" class="text-xs-left">
									<v-icon v-if="props.item.verified_insurance === 1 && props.item.verified_registration === 1 " color="green">check_circle</v-icon>
									<v-icon v-else color="red">highlight_off</v-icon>
								</td>
							</tr>
						</template>
						<template slot="expand" slot-scope="props">
							<selected-car :carId="props.item.id"></selected-car>
						</template>
						<v-alert slot="no-results" :value="true" color="error" icon="warning">
							Your search for "{{ search }}" found no results.
						</v-alert> -->
					</v-data-table>
					
					<paginate store="cars" collection="meta"/>
                    <v-progress-linear v-if="loadingStatus()" :indeterminate="true"></v-progress-linear>
				</v-card>

				<!-- delete dialog -->
				<v-dialog v-model="dialogDelete" v-if="selectedItem" persistent max-width="290">
					<v-card>
						<v-card-title class="headline">Are you sure?</v-card-title>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="info" @click.native="dialogDelete = false; deleteCar(selectedItem)">DELETE</v-btn>
							<v-btn color="error" @click.native="dialogDelete = false">CANCEL</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
				<!-- end of delete dialog-->

				<!-- restore dialog -->
				<v-dialog v-model="dialogRestore" v-if="selectedItem" persistent max-width="290">
					<v-card>
						<v-card-title class="headline">Are you sure?</v-card-title>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="info" @click.native="dialogRestore = false; restoreCar(selectedItem)">RESTORE</v-btn>
							<v-btn color="error" @click.native="dialogRestore = false">CANCEL</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
				<!-- end of restore dialog-->

			</v-layout>
		</v-slide-y-transition>
	</v-container>
</template>

<script>
	import paginate from './paginate'
	import vueBtn from './vueBtnDownload'
	export default {
		components: {
			paginate,
			vueBtn
		},
		data () {
			return {
				expanded: [],
        		singleExpand: false,
				dates: [],
				userFirstLastName: [],
                search: '',
                loading: false,
                dialogDelete: false,
                dialogRestore: false,
				selectedItem: null,
				pagination: {
					sortBy: 'id',
					rowsPerPage: 10
				},
				headers: [
					{
					text: 'CAR ID',
					align: 'left',
					sortable: true,
					value: 'id'
					},
					{ text: 'Manufacturer', value: 'car_manufacturer' },
					{ text: 'Model', value: 'car_model' },
					{ text: 'Year', value: 'production_year' },
					{ text: 'City', value: 'car_city' },
					{ text: 'Active', value: 'active' },
					{ text: 'Owner Id', value: 'ownerId' },
					{ text: 'Owner Name', value: 'ownerName' },
					{ text: 'Created On', value: 'created_at' },
					{ text: 'Delete/Restore', value: 'delete_restore' },
					{ text: 'Verified', value: 'Verified' }
				],
				headerfileExport: {
				'id': 'id',
				'car_manufacturer': 'car_manufacturer',
				'car_model': 'car_model',
				'production_year': 'production_year',
				'car_city': 'car_city',
				'active': 'active',
				'owner_first': 'owner.first_name',
				'owner_last': 'owner.last_name',
				'created_at': 'created_at'
			},
			headerfileExportPDF: [
				{ header: 'ID', dataKey: 'id' },
				{ header: 'Car Manufacturer', dataKey: 'car_manufacturer' },
				{ header: 'Car Model', dataKey: 'car_model' },
				{ header: 'Production Year', dataKey: 'production_year' },
				{ header: 'Car City', dataKey: 'car_city' },
				{ header: 'Owner First Name', dataKey: 'owner.first_name' },
				{ header: 'Owner Last Name', dataKey: 'owner.last_name' },
				{ header: 'Active', dataKey: 'active' },
				{ header: 'Created_at', dataKey: 'created_at' }
			],
			ownerName: []
			}
		},
		computed: {
			cars: {
				get () {
					return this.$store.state.cars.cars
				}
			},
			pages () {
				if (this.pagination.rowsPerPage == null ||
					this.pagination.totalItems == null
				) return 0

				return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
			}
		},
		watch: {
			loading (newVal, oldVal) {
				this.loading = this.loadingStatus()
			},
			// data (newVal, oldVal) {
			// 	this.data = this.cars
			// },
			search (newVal, oldVal) {
				this.$store.dispatch('cars/setSearchText', newVal)
				this.$store.dispatch('cars/setCurrentUrl', '/cars')
				this.$store.dispatch('cars/getList', 1)
			},
			dates (newVal, oldVal) {
				this.$store.dispatch('cars/setCurrentUrl', '/cars')
				this.$store.dispatch('cars/setDateRangeNow', newVal)
				this.$store.dispatch('cars/getList', 1).then(response => {
					this.loading = this.$store.getters['cars/getLoader']
				})
			}
		},
		created () {
			this.$store.dispatch('cars/setCurrentUrl', '/cars')
			this.$store.dispatch('cars/getList', 1).then(response => {
				this.loading = this.$store.getters['cars/getLoader']
			})
		},
		methods: {
			getCars () {
				this.$store.dispatch('cars/Unset_Date_range')
				this.$store.dispatch('cars/setCurrentUrl', '/cars')
				this.$store.dispatch('cars/getList', 1).then(response => {
					this.loading = this.$store.getters['cars/getLoader']
				})
				if (this.cars.length > 0) {
					this.userFirstLastName = []
					this.cars.forEach(elem => {
						this.userFirstLastName.push(elem)
					})
					this.loading = this.$store.getters['cars/getLoader']
				} else {
					this.loading = this.$store.getters['cars/getLoader']
				}
			},
			loadingStatus () {
				return this.$store.getters['cars/getLoader']
			},
            deleteCar (id) {
                this.loading = true
                this.axios.post('/cars/delete/' + id, {},
				{
					headers: {
						Authorization: "Bearer " + localStorage.getItem("id_token")
					}
				})
				.then(response => {
					this.loading = false
					this.getCars()
				})
				.catch(error => {
					console.log(error)
					this.loading = false
				})
            },

            restoreCar (id) {
                this.loading = true
                this.axios.post('/cars/restore/' + id, {},
				{
					headers: {
						Authorization: "Bearer " + localStorage.getItem("id_token")
					}
				})
				.then(response => {
					this.loading = false
					this.getCars()
				})
				.catch(error => {
					console.log(error)
					this.loading = false
				})
            },
            showDeleteDialog (value) {
                this.dialogDelete = true
                this.selectedItem = value
            },
            showRestoreDialog (value) {
                this.dialogRestore = true
                this.selectedItem = value
            },
			handleChange (dates) {
				this.dates.splice(0, this.dates.length)
				for (let i = 0; i < dates.length; i++) {
					this.dates.push(dates[i].format("YYYY-MM-DD"))
				}
			}
		},
		mounted () {
			this.getCars()
		}
	}
</script>
<style>
	.tr-click {
		cursor: pointer;
	}
</style>

